<template>
	<v-card class="elevation-0 info-text-container" v-if="infoBlockVisibility[type]">
		<div class="hide-type" @click="hideType">X</div>
		<div v-if="type === 'textile'">
			<SectionLabel :label="$getDictString('configurator_textile_info_block_heading')" />
			<InfoText :text="$getDictString('configurator_textile_info_block_text')" class="descr-text" />
		</div>
		<div v-if="type === 'motive'">
			<SectionLabel :label="$getDictString('configurator_finish_info_block_heading')" />
			<InfoText :text="$getDictString('configurator_finish_info_block_text')" class="descr-text" />
		</div>
		<div v-if="type === 'variants'">
			<SectionLabel :label="$getDictString('configurator_variants_info_block_heading')" />
			<InfoText :text="$getDictString('configurator_variants_info_block_text')" class="descr-text" />
		</div>
		<div v-if="type === 'sizes'">
			<SectionLabel :label="$getDictString('configurator_amount_info_block_heading')" />
			<InfoText :text="$getDictString('configurator_amount_info_block_text')" class="descr-text" />
		</div>
	</v-card>
</template>

<script>
import SectionLabel from '@/views/configurator/components/SectionLabel'
import InfoText from '@/views/configurator/components/InfoText'

import { mapFields } from 'vuex-map-fields'

export default {
	name: 'InfoBlock',
	components: { SectionLabel, InfoText },
	props: {
		type: { type: String, required: true, enum: ['textile', 'motive', 'variants', 'sizes', 'cart-overview'] },
	},
	computed: {
		...mapFields('ConfiguratorDisplay', {
			infoBlockVisibility: 'infoBlockVisibility',
		}),
	},
	methods: {
		hideType() {
			this.infoBlockVisibility[this.type] = false
		},
	},
}
</script>

<style lang="sass" scoped>
.info-text-container
	margin-bottom: 10px
	padding: 20px 40px
	position: relative
	border: 1px solid #ebebeb !important

	.hide-type
		position: absolute
		top: 10px
		right: 10px
		background: black
		color: white
		border-radius: 50% !important
		width: 20px
		height: 20px
		display: flex
		align-items: center
		justify-content: center
		cursor: pointer
</style>