<template>
	<v-app>
		<v-main id="configurator-wrapper" v-if="loadingProducts">
			<BigLoader :text="$getDictString('configurator_bigloader_label')" />
		</v-main>
		<v-main id="configurator-wrapper" v-if="!loadingProducts">
			<ErrorBanner />
			<ContinueConfigurationModal />
			<v-sheet color="transparent" height="60px">
				<TopBar :showOverviewBtn="requestProducts.length > 0" />
			</v-sheet>
			<v-sheet color="transparent" height="calc(100vh - 60px)">
				<v-row class="backdrop" no-gutters>
					<v-col cols="11" sm="12" md="6" lg="6" class="left-box">
						<div class="top-container d-flex align-end justify-center flex-column text-center" ref="top-container">
							<ShirtAnimation v-if="currentStep == 'textile' && !selectedProduct && selectedCategory == 'shirt'" />
							<CapAnimation v-if="currentStep == 'textile' && !selectedProduct && selectedCategory == 'cap'" />
							<HoodieAnimation v-if="currentStep == 'textile' && !selectedProduct && selectedCategory == 'hoodie'" />
							<CrewneckAnimation v-if="currentStep == 'textile' && !selectedProduct && selectedCategory == 'crewneck'" />
							<OtherAnimation v-if="currentStep == 'textile' && !selectedProduct && selectedCategory == 'other'" />

							<div class="stock-image pa-1" v-if="currentStep === 'textile' && selectedProduct">
								<StockImage :product="selectedProduct" v-if="selectedProduct" />
							</div>
							<div class="mockups" v-if="currentStep !== 'textile'">
								<SVGFront
									mockupSide="front"
									:mainColor="Object.keys(variants).length === 0 ? '#fff' : mockupMainColor"
									:secColor="Object.keys(variants).length === 0 ? '#fff' : mockupSecColor"
									v-if="mockupSide === 'front'"
									:allFinishes="productFinishes"
									:currentStep="currentStep"
									:mockupType="selectedProduct && selectedProduct.productType"
									:key="mockupMountKey.toString() + 1"
								/>
								<SVGBack
									mockupSide="back"
									:mainColor="Object.keys(variants).length === 0 ? '#fff' : mockupMainColor"
									:secColor="Object.keys(variants).length === 0 ? '#fff' : mockupSecColor"
									v-if="mockupSide === 'back'"
									:allFinishes="productFinishes"
									:currentStep="currentStep"
									:mockupType="selectedProduct && selectedProduct.productType"
									:key="mockupMountKey.toString() + 1"
								/>
								<SVGLeft
									mockupSide="left"
									:mainColor="Object.keys(variants).length === 0 ? '#fff' : mockupMainColor"
									:secColor="Object.keys(variants).length === 0 ? '#fff' : mockupSecColor"
									v-if="mockupSide === 'left'"
									:allFinishes="productFinishes"
									:currentStep="currentStep"
									:mockupType="selectedProduct && selectedProduct.productType"
									:key="mockupMountKey.toString() + 1"
								/>
								<SVGRight
									mockupSide="right"
									:mainColor="Object.keys(variants).length === 0 ? '#fff' : mockupMainColor"
									:secColor="Object.keys(variants).length === 0 ? '#fff' : mockupSecColor"
									v-if="mockupSide === 'right'"
									:allFinishes="productFinishes"
									:currentStep="currentStep"
									:mockupType="selectedProduct && selectedProduct.productType"
									:key="mockupMountKey.toString() + 1"
								/>
							</div>
							<div
								class="actions-box-wrapper d-flex align-center justify-center"
								:style="{ opacity: currentStep !== 'textile' ? 1 : 0, zIndex: currentStep !== 'textile' ? 0 : -1 }"
							>
								<div class="actions-box py-2 px-4" v-if="Object.keys(variants).length > 0">
									<div v-for="(variant, idx) in variants" :key="idx">
										<div class="py-2" v-if="variant.color && selectedProduct.shopName === 'stanley-stella'">
											<img
												:src="getColorBlob(variant.color)"
												class="colorBlob"
												@click="changeMockupColor(variant.extractedHexColor)"
												:ref="'color-blob-' + variant.color"
											/>
										</div>
										<div
											class="py-2"
											v-if="
												variant.color &&
												(selectedProduct.isManualProduct ||
													selectedProduct.shopName === 'l-shop' ||
													selectedProduct.shopName === 'custom')
											"
										>
											<div
												class="colorBlob circle"
												:style="{ backgroundColor: variant.extractedHexColor }"
												@click="changeMockupColor(variant.extractedHexColor)"
											></div>
										</div>
										<!-- <div class="pt-2">{{ variant.color }}</div> -->
									</div>
								</div>
								<div class="rotate py-2 px-4 mt-2">
									<div class="py-2">
										<v-btn class="rotate-btn" text large @click="switchMockupSide">
											<img :src="require('@/assets/icons/turn.svg')" />
										</v-btn>
									</div>
								</div>
							</div>
						</div>

						<div class="next-prev-buttons">
							<button class="prev-button" @click="prevStep">Zurück</button>

							<!-- TEXTILE BUTTON -->
							<div v-if="currentStep == 'textile'">
								<button
									class="bottom-container-button next-button"
									@click="nextStep"
									v-if="
										selectedProduct &&
										selectedCategory &&
										variants['0'] &&
										variants['0'].color &&
										variants['0'].color.length > 0
									"
								>
									Weiter
								</button>
							</div>

							<button
								v-if="this.selectedFinishId > -1 && currentStep == 'motive' && productFinishes"
								class="bottom-container-button secondary-button"
								@click="closeSelectedFinish()"
							>
								<svg
									width="24px"
									height="24px"
									stroke-width="1.5"
									viewBox="0 0 24 24"
									fill="white"
									xmlns="http://www.w3.org/2000/svg"
									color="#000000"
								>
									<path
										d="M9.172 14.828L12.001 12m2.828-2.828L12.001 12m0 0L9.172 9.172M12.001 12l2.828 2.828M12 22c5.523 0 10-4.477 10-10S17.523 2 12 2 2 6.477 2 12s4.477 10 10 10z"
										stroke="#000000"
										stroke-width="1.5"
										stroke-linecap="round"
										stroke-linejoin="round"
									></path>
								</svg>
							</button>

							<button
								v-if="this.selectedFinishId > -1 && currentStep == 'motive' && productFinishes"
								class="bottom-container-button next-button"
								@click="closeSelectedFinish()"
							>
								{{ $getDictString('configurator_finish_button_save_finish') }}
							</button>

							<!-- FINISH BUTTONS -->
							<button
								class="bottom-container-button next-button"
								@click="nextStep"
								v-if="
									currentStep == 'motive' &&
									productFinishes &&
									Object.keys(productFinishes).length > 0 &&
									selectedFinishId === -1
								"
							>
								Weiter
							</button>
							<!-- VARIANT BUTTON -->
							<button
								class="bottom-container-button next-button"
								@click="nextStep"
								v-if="currentStep == 'variants' && variants && Object.keys(variants).length > 0 && selectedVariantId === -1"
							>
								Weiter
							</button>
						</div>
					</v-col>

					<v-col cols="12" sm="12" md="6" lg="6" class="fill-height right-box">
						<v-container
							id="configurator-wrapper-scroll-container"
							ref="scrollContainer"
							fill-height
							class="align-start scroll-box"
						>
							<v-row>
								<v-col cols="12" xs="12" md="11" lg="11" xl="11" class="pr-0">
									<div class="mb-20">
										<div class="active-step-title" v-if="currentStep">
											{{ steps.find((step) => step.key === currentStep).value }}
										</div>
										<Textile v-if="currentStep === 'textile'" />
										<Finish v-else-if="currentStep === 'motive'" />
										<Variants v-else-if="currentStep === 'variants'" />
										<Amount v-else-if="currentStep === 'sizes'" />
									</div>
								</v-col>
							</v-row>
						</v-container>
					</v-col>
				</v-row>
				<div class="footer d-flex justify-center items-center">
					<a href="/privacy">Datenschutz</a>
					<a href="/imprint">Impressum</a>
					<a href="/agb">AGB</a>
				</div>
			</v-sheet>
		</v-main>
	</v-app>
</template>

<script>
import StockImage from './components/StockImage'
import TopBar from './partials/TopBar'
import ErrorBanner from './components/ErrorBanner'
import Textile from './steps/Textile'
import Variants from './steps/Variants'
import Finish from './steps/Finish'
import Amount from './steps/Amount'
import ShirtAnimation from './components/categoryAnimations/ShirtAnimation'
import CapAnimation from './components/categoryAnimations/CapAnimation'
import HoodieAnimation from './components/categoryAnimations/HoodieAnimation'
import CrewneckAnimation from './components/categoryAnimations/CrewneckAnimation'
import OtherAnimation from './components/categoryAnimations/OtherAnimation'
import BigLoader from './components/BigLoader'
import ContinueConfigurationModal from './partials/ContinueConfigurationModal.vue'
import { finishValidate } from '@/assets/data/validationSchemes'

// mockups
import SVGFront from './mockups/SVGFront'
import SVGBack from './mockups/SVGBack'
import SVGLeft from './mockups/SVGLeft'
import SVGRight from './mockups/SVGRight'

import { gsap } from 'gsap'
import { Power4 } from 'gsap'
import { DrawSVGPlugin } from 'gsap/DrawSVGPlugin'

gsap.registerPlugin(DrawSVGPlugin)

import { mapFields } from 'vuex-map-fields'

export default {
	name: 'ConfiguratorWrapper',
	components: {
		TopBar,
		SVGFront,
		SVGBack,
		SVGLeft,
		SVGRight,
		Textile,
		Variants,
		Finish,
		Amount,
		ShirtAnimation,
		CapAnimation,
		HoodieAnimation,
		CrewneckAnimation,
		OtherAnimation,
		StockImage,
		ErrorBanner,
		BigLoader,
		ContinueConfigurationModal,
	},
	data() {
		return {
			COLOR_DOT_BASE_URL: process.env.VUE_APP_AWS_IMAGES_BASE_URL + '/stanley-stella-colors',
			mockupSide: 'front', // front, left, right, back
			mockupMainColor: 'white',
			mockupSecColor: 'white',
			mockupMountKey: 0,
		}
	},
	computed: {
		...mapFields('InternalProducts', {
			loadingProducts: 'loading',
		}),
		...mapFields('ConfiguratorDisplay', {
			currentStep: 'currentStep',
			displayScenario: 'displayScenario',
		}),
		...mapFields('Configurator', {
			variants: 'variants',
			productFinishes: 'finishes',
			selectedFinishId: 'selectedFinishId',
			selectedVariantId: 'selectedVariantId',
			requestProducts: 'customerRequest.configurations',
			selectedProduct: 'product',
			selectedCategory: 'category',
		}),
		steps() {
			return [
				{ key: 'textile', value: this.$getDictString('configurator_textile_big_heading') },
				{ key: 'motive', value: this.$getDictString('configurator_finish_big_heading') },
				{ key: 'variants', value: this.$getDictString('configurator_variants_big_heading') },
				{ key: 'sizes', value: this.$getDictString('configurator_amount_big_heading') },
			]
		},
	},
	created() {
		this.$store.dispatch('InternalProducts/fetchProducts')
		window.addEventListener('resize', this.remountMockup)

		// check if there is a started configuration
		if ((this.requestProducts && this.requestProducts.length > 1) || this.selectedProduct) {
			this.$store.dispatch('ConfiguratorDisplay/toggleContinueConfigurationModal')
		}

		// check if currentStep is set to one of the configurator steps, if not set to 'texile'
		if (!this.steps.find((s) => s.key === this.currentStep)) this.currentStep = 'textile'
	},
	destroyed() {
		window.removeEventListener('resize', this.remountMockup)
	},
	mounted() {
		this.triggerMockupColorChange()
	},
	watch: {
		// currentStep(newType) {
		// 	if (newType) this.setNavigationPosition(newType)
		// },
		currentStep() {
			this.$nextTick(function () {
				this.triggerMockupColorChange()
			})
		},
		variants() {
			this.$nextTick(function () {
				this.triggerMockupColorChange(true)
			})
		},
		productFinishes(newFinishes) {
			if (this.selectedFinishId > -1 && newFinishes[this.selectedFinishId]) {
				const side = this.getFinishSide(newFinishes[this.selectedFinishId])
				this.mockupSide = side
			}
		},
		displayScenario(newVal) {
			this.changeDisplayScenario(newVal.scenario)
		},
		selectedFinishId(newFinishId) {
			console.log('selectedFinishId changed: ', newFinishId)
			if (newFinishId > -1 && this.productFinishes[newFinishId]) {
				const side = this.getFinishSide(this.productFinishes[newFinishId])
				this.mockupSide = side
			}
		},
	},
	methods: {
		remountMockup() {
			this.mockupMountKey = Math.round(Math.random() * 1000)
		},
		getFinishSide(finish) {
			// let finishSide = 'front'
			// const sideViewValues = ['shoulder-sleeve', 'label-sleeve', 'label-hip'] // shoulder-sleeve
			// const frontViewValues = ['front', 'label-hem', 'label-front', 'neck', 'label-standard-neck', 'label-loop-neck'] // front
			// const backViewValues = ['back'] // back

			// const sideLeftPositions = ['shoulder-sleeve-left-custom', 'label-sleeve-left', 'label-hip-left']
			// const sideRightPositions = ['shoulder-sleeve-right-custom', 'label-sleeve-right', 'label-hip-right']

			let finishSide = 'front'
			const sideViewValues = ['shoulder-sleeve', 'label-sleeve', 'label-hip', 'left', 'right'] // shoulder-sleeve
			const frontViewValues = ['front', 'label-hem', 'label-front', 'neck', 'label-standard-neck', 'label-loop-neck'] // front
			const backViewValues = ['back'] // back

			const sideLeftPositions = ['shoulder-sleeve-left-custom', 'left-center', 'label-sleeve-left', 'label-hip-left', 'custom-left']
			const sideRightPositions = [
				'shoulder-sleeve-right-custom',
				'right-center',
				'label-sleeve-right',
				'label-hip-right',
				'custom-right',
			]

			if (frontViewValues.indexOf(finish.side) > -1) finishSide = 'front'
			else if (backViewValues.indexOf(finish.side) > -1) finishSide = 'back'
			else if (sideViewValues.indexOf(finish.side) > -1) {
				if (sideLeftPositions.indexOf(finish.position) > -1) finishSide = 'left'
				if (sideRightPositions.indexOf(finish.position) > -1) finishSide = 'right'
			}

			return finishSide
		},
		// getFinishes(side) {
		// 	const sideFinishes = []

		// 	for (let key in this.productFinishes) {
		// 		const finish = this.productFinishes[key]
		// 		const finishSide = this.getFinishSide(finish)
		// 		if (side === finishSide) {
		// 			finish.idx = key
		// 			sideFinishes.push(finish)
		// 		}
		// 	}

		// 	return sideFinishes
		// },
		switchMockupSide() {
			let sides = ['front', 'right', 'back', 'left']
			if (this.selectedProduct.productType === 'custom') sides = ['front', 'back']
			const currentIdx = sides.indexOf(this.mockupSide)
			let newIdx = currentIdx + 1
			if (newIdx > sides.length - 1) newIdx = 0
			else if (newIdx < 0) newIdx = sides.length - 1
			this.mockupSide = sides[newIdx]
		},
		triggerMockupColorChange(force = false) {
			if (force || this.mockupMainColor === 'white') {
				if (this.variants && this.variants[0]) {
					this.changeMockupColor(this.variants[0].extractedHexColor)
				}
			}
		},
		changeMockupColor(colorName) {
			if (colorName && colorName.match(/^#/gi)) {
				this.mockupMainColor = colorName
				this.mockupSecColor = colorName
			}
		},
		// setNavigationPosition(newType) {
		// 	// center of top container
		// 	const containerWidth = this.$refs['top-container'].clientWidth
		// 	const containerCenter = containerWidth / 2

		// 	// element offset inside stepNavigation div
		// 	const btnElm = this.$refs[newType + '-btn'][0]
		// 	const elementOffset = btnElm.offsetLeft
		// 	const elementWidth = btnElm.clientWidth
		// 	const elementOffsetCenter = elementOffset + elementWidth / 2

		// 	// new left offset of step navigation container
		// 	const newOffsetLeft = containerCenter - elementOffsetCenter

		// 	this.stepNavigationOffset = newOffsetLeft + 'px'
		// },
		// isActive(type) {
		// 	return type === this.currentStep
		// },
		// changeStep(type) {
		// 	this.$store.dispatch('ConfiguratorDisplay/setStep', type)
		// 	this.$refs.scrollContainer.scrollTo({ top: 0, behavior: 'smooth' })
		// },
		nextStep() {
			this.$store.dispatch('ConfiguratorDisplay/nextStep')
			this.$refs.scrollContainer.scrollTo({ top: 0, behavior: 'smooth' })
		},
		prevStep() {
			this.$store.dispatch('ConfiguratorDisplay/prevStep')
		},
		getColorBlob(colorName) {
			if (this.selectedProduct.shopName == 'stanley-stella' && colorName && colorName !== '') {
				const color = this.selectedProduct.colors.find((c) => c.value === colorName)
				const blob = color ? color.hexValue : ''
				return `${this.COLOR_DOT_BASE_URL}/${blob}.png`
			}
			return ''
		},
		changeDisplayScenario(newVal) {
			if (newVal == 'reset') {
				gsap.to('svg', { opacity: 1, duration: 1.4, scale: 1, xPercent: 0, yPercent: 0, ease: Power4.easeOut })
			} else if (newVal == 'zoom') {
				gsap.to('svg', { opacity: 1, duration: 2, scale: 1.9, yPercent: 10, transformOrigin: '50% 50%', ease: Power4.easeOut })
			}
		},
		addFinish() {
			this.$store.dispatch('Configurator/addFinish')
		},
		addVariant() {
			this.$store.dispatch('Configurator/addVariant')
		},
		closeSelectedFinish() {
			console.log(this.productFinishes)

			let finish = { ...this.productFinishes[this.selectedFinishId] }

			console.log(finish)
			const validate = finishValidate()
			const valid = validate(finish)
			if (!valid) {
				// console.log(validate.errors)
				const printErrors = validate.errors.filter((e) => e.instancePath !== '')
				// const printErrors = validate.errors
				let errorString = ''

				console.log('--- FEHLER ---')
				printErrors.forEach((e) => {
					console.log(e.instancePath + ' ' + e.message)
					errorString += e.message + '\n'
				})
				console.log('--- ------ ---')
				this.$store.dispatch('ConfiguratorDisplay/setError', { message: errorString })
			} else {
				this.selectedFinishId = -1
			}
		},
	},
}
</script>

<style lang="sass" scoped>
.backdrop
	border-right: 1px solid rgba(0,0,0,0.01)
	background-image: radial-gradient(rgba(0,0,0,0.12) 10%, #fff 7%)
	background-position: 0 0
	background-size: 20px 20px
	background-repeat: repeat
	overflow: hidden
	height: calc(100vh - 60px)

.left-box
	position: relative
	height: calc(100vh - 60px)
	@media screen and (max-width: 900px)
		height: auto
	.top-container
		height: calc(100vh - 60px)
		background: transparent
		position: relative
		position: fixed
		top: 60px
		width: 50%
		// display: flex
		// justify-content: center
		// align-items: center
		// top: -20px
		@media screen and (max-width: 900px)
			height: 60vh
			width: 100%
			z-index: 2
			top: 100px

		.mockups
			width: 100%
			padding: 50px
			overflow-x: visible
		.textil-name
			position: absolute
			top: 7vh
			font-size: 2em
			font-weight: 700
			letter-spacing: -.02em
			margin-bottom: 50px
		.stock-image
			// max-width: 100%
			// max-height: 100%
			height: 100%
			width: 100%
			// padding-bottom: 80px !important
		.actions-box-wrapper
			position: absolute
			z-index: 10
			top: 0
			left: 20px
			bottom: 120px
			flex-direction: column
			.rotate
				position: absolute
				top: 5vh
				// right: 14%
				margin-left: 76vw
				.rotate-btn
					&:focus
						outline: none !important
				img
					width: 50px
			.actions-box
				background: white
				border: 1px solid #B5B5B5
				width: 100%
				.colorBlob
					width: 30px
					height: 30px
					cursor: pointer
					&.circle
						border-radius: 50%

	.next-prev-buttons
		font-size: 27px
		height: 60px
		position: fixed
		bottom: 40px
		right: -2px
		z-index: 11111111111
		display: flex
		justify-content: center
		align-items: center
		z-index: 201
	.prev-button
		background: rgb(250,250,250)
		color: black
		height: 50px
		font-weight: 700
		padding: 0 20px
		display: none
		// margin-right: 10px
	.next-button
		background: black
		color: white
		font-size: 28px
		font-weight: 700
		padding: 0 50px
		letter-spacing: -0.025em
		border: solid 2px black
		box-sizing: border-box
		opacity: 1
		height: 60px
		z-index: 11111111
		&:hover
			opacity: 1
			color: black
			background: white
	.secondary-button
		background: black
		color: white
		font-size: 28px
		font-weight: 700
		padding: 0 20px
		letter-spacing: -0.025em
		border: solid 2px black
		box-sizing: border-box
		margin-right: 2px
		opacity: 1
		height: 60px
		z-index: 11111111
		&:hover
			opacity: 1
			color: black
			background: white
	.alt-button
		background: white
		color: black
		font-size: 18px
		height: 60px
		font-weight: 700
		padding: 0 30px
		letter-spacing: -0.025em
		border: solid 2px black
		box-sizing: border-box
		opacity: 1
		z-index: 11111111
		&:hover
			opacity: 1
			color: white
			background: black

.right-box
	position: relative
	height: calc(100vh - 60px)
	// overflow-y: scroll
	overflow-y: hidden

	.scroll-box
		overflow-y: scroll
		overflow-x: hidden
		@media screen and (max-width: 900px)
			padding-top: 30vh
			overflow-y: scroll
	.active-step-title
		font-size: 100px
		font-family: "hawk-display"
		opacity: 1
		color: rgb(35,35,35)
		position: absolute
		top: -20px
		right: 30px
		text-transform: capitalize
		@media screen and (max-width: 900px)
			position: fixed
			top: 60px
			z-index: 1
.footer
	position: absolute
	bottom: 10px
	right: 0
	width: 50%
	a
		margin: 0 5px
		color: black
		opacity: 0.7
</style>
