<template>
	<div class="content pa-5">
		<div class="bigIndex">
			<span>{{ parseInt(index) + 1 }}</span>
		</div>
		<div class="mockup-wrapper">
			<div class="mockup">
				<img :src="textileIconSrc" />
			</div>
			<div class="color-blob" v-if="shopName === 'stanley-stella'">
				<img :src="getColorBlob(variant.color, shopName)" />
			</div>
			<div
				class="color-circle"
				:style="{ backgroundColor: variant.extractedHexColor }"
				v-if="selectedProduct.isManualProduct || shopName === 'l-shop' || shopName === 'custom'"
			></div>
		</div>
		<div class="color-name">{{ variant.color }}</div>
	</div>
</template>

<script>
export default {
	name: 'DisplayTextile',
	props: {
		shopName: { type: String, required: true },
		variant: { type: Object, required: true },
		selectedProduct: { type: Object, required: true },
		index: { type: Number, required: false },
	},
	computed: {
		textileIconSrc() {
			let type = this.selectedProduct.productType
			if (type.match('cap')) type = 'cap'
			return require(`@/assets/icons/textile-icons/transparent-${type}.png`)
		},
	},
	data() {
		return {
			COLOR_DOT_BASE_URL: process.env.VUE_APP_AWS_IMAGES_BASE_URL + '/stanley-stella-colors',
		}
	},
	methods: {
		getColorBlob(colorName, shopName) {
			if (shopName == 'stanley-stella' && colorName.length > 0) {
				const blob = this.selectedProduct.colors.find((c) => c.value === colorName).hexValue
				return `${this.COLOR_DOT_BASE_URL}/${blob}.png`
			}
			return ''
		},
	},
}
</script>

<style scoped lang="sass">
.content
    height: 100%
    display: flex
    align-items: center
    justify-content: center
    flex-direction: column
    position: relative
    cursor: pointer
    border-radius: 4px
    .bigIndex
        position: absolute
        top: -10px
        height: 100%
        display: flex
        justify-content: flex-end
        align-items: flex-start
        right: -2px
        font-size: 100px
        font-weight: 700
        opacity: 0.05
        line-height: 1
    &:hover
        background: rgba(0,0,0,0.03)

    .mockup-wrapper
        height: 174px
        width: 100%
        position: relative
        display: flex
        img
            display: inline-block
        .mockup
            width: 100%
            text-align: center
            margin: 20px 0
            position: absolute

        .color-blob
            width: 100%
            text-align: center
            position: absolute
            img
                height: 60px
                margin-top: 50px
        .color-circle
            position: relative
            top: 30%
            margin: 0px auto
            border-radius: 50%
            width: 40px
            height: 40px

    .index
        font-size: 10px
        font-weight: 700
        letter-spacing: 0.2em
        text-transform: uppercase

    .color-name
        font-size: 14px
        font-weight: 700
        letter-spacing: -0.02em
</style>
