<template>
	<div :class="{ button: true, 'active-state': active, mini: mini, medium: medium }">{{ label }}</div>
</template>

<style scoped lang="sass">
.button
    font-size: 32px
    font-size: 2.4em
    font-weight: 700
    padding: 4px 16px 4px 0px
    color: rgba(0,0,0,0.17)
    cursor: pointer
    letter-spacing: -0.02em
    text-transform: capitalize
    transition: color 300ms ease
    &:hover
        color: rgba(0,0,0,0.35)
    &.active-state
        color: rgba(0,0,0,1)
    &.mini
        font-size: 1.6em
    &.medium
        font-size: 2em
</style>

<script>
export default {
	name: 'TextButton',
	props: {
		label: { type: String },
		active: { type: Boolean },
		mini: { type: Boolean },
		medium: { type: Boolean },
	},
}
</script>
