var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-dialog',{attrs:{"content-class":"textile-color-dialog","scrollable":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"color-change-btn",attrs:{"small":"","outlined":"","elevation":"0","tile":""}},'v-btn',attrs,false),on),[_vm._v("Ändern")])]}}]),model:{value:(_vm.colorDialog),callback:function ($$v) {_vm.colorDialog=$$v},expression:"colorDialog"}},[_c('v-card',{staticClass:"content",attrs:{"tile":""}},[_c('v-card-title',{staticClass:"text-h5 black text-white",attrs:{"color":"white"}},[_vm._v(" "+_vm._s(_vm.$getDictString('configurator_variants_heading_color_select'))+" ")]),_c('v-card-text',[_c('v-item-group',{staticClass:"color-selection-container d-flex flex-wrap",model:{value:(_vm.selectedVariantColor),callback:function ($$v) {_vm.selectedVariantColor=$$v},expression:"selectedVariantColor"}},_vm._l((_vm.selectedProduct.colors),function(color,idx){return _c('v-item',{key:idx,staticClass:"color-selection-wrapper pa-5",attrs:{"value":color.value}},[_c('span',[(
								_vm.selectedProduct.isManualProduct ||
								_vm.selectedProduct.shopName === 'l-shop' ||
								_vm.selectedProduct.shopName === 'custom'
							)?_c('v-btn',{staticClass:"color-selection-item ma-2",class:{ selected: _vm.selectedVariantColor == color.value, 'ma-5': true },style:({ backgroundColor: '#' + color.hexValue, border: '1px solid lightgrey' }),attrs:{"fab":"","small":"","plain":"","depressed":"","value":color.value,"color":"black"},nativeOn:{"click":function($event){return _vm.selectTextileColor(color)}}},[_c('span',{staticClass:"pt-2"},[_vm._v(_vm._s(color.value))])]):_vm._e(),(_vm.selectedProduct.shopName === 'stanley-stella')?_c('v-btn',{staticClass:"color-selection-item ma-2",class:{ selected: _vm.selectedVariantColor == color.value, 'ma-5': true },style:({
								backgroundImage: ("url(" + _vm.COLOR_DOT_BASE_URL + "/" + (color.hexValue) + ".png)"),
								backgroundSize: '100%',
							}),attrs:{"fab":"","small":"","plain":"","depressed":"","value":color.value},nativeOn:{"click":function($event){return _vm.selectTextileColor(color)}}},[_c('span',[_vm._v(_vm._s(color.value))])]):_vm._e()],1)])}),1)],1),_c('v-divider'),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{attrs:{"color":"primary","text":""},on:{"click":function($event){_vm.colorDialog = false}}},[_vm._v(" Abbrechen ")])],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }