<template>
	<div class="d-flex flex-column mt-10">
		<SectionLabel :label="dictionary['configurator_finish_heading_comment']" />
		<div class="button-wrapper d-flex justify-start flex-column">
			<v-textarea
				class="textfield"
				v-model="selectedComment"
				counter
				maxlength="300"
				full-width
				no-resize
				outlined
				color="black"
			></v-textarea>
		</div>
	</div>
</template>

<script>
import { mapFields } from 'vuex-map-fields'

import SectionLabel from '../../components/SectionLabel'

export default {
	name: 'Comment',
	components: { SectionLabel },
	computed: {
		...mapFields('Dictionary', {
			dictionary: 'readableDictionary',
		}),
		...mapFields('Configurator', {
			selectedFinishId: 'selectedFinishId',
			productFinishes: 'finishes',
		}),
		selectedComment: {
			get() {
				return this.productFinishes[this.selectedFinishId] && this.productFinishes[this.selectedFinishId].comment
			},
			set(value) {
				this.$store.dispatch('Configurator/updateFinish', { key: 'comment', value })
			},
		},
	},
}
</script>

<style lang="sass" scoped>
.textfield
    font-size: 13px !important
    caret-color: black !important
    textarea
        padding-left: 0 !important
        padding-right: 0 !important
</style>