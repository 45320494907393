 <template>
	<div>
		<div :class="{ button: true, fade: fade && !active, 'active-state': active }">
			<DeleteFileDialog v-if="deleteFn && !active" :deleteFn="deleteFn" />
			<div class="inner">
				<div class="image">
					<v-img :src="image" height="7.2vw" max-width="6vw" contain> </v-img>
				</div>
				<div class="label px-2">{{ label }}</div>
			</div>
		</div>
	</div>
</template>

<script>
// import InfoText from './InfoText'
import DeleteFileDialog from './DeleteFileDialog'

export default {
	name: 'BoxButton',
	components: { DeleteFileDialog },
	props: {
		label: { type: String },
		image: { type: String },
		fade: { type: Boolean },
		active: { type: Boolean },
		deleteFn: { type: Function },
	},
	data() {
		return {
			COLOR_DOT_BASE_URL: process.env.VUE_APP_AWS_IMAGES_BASE_URL + '/stanley-stella-colors',
			deleteFileDialog: false,
		}
	},
	watch: {
		fade(fade) {
			console.log(fade)
		},
	},
	methods: {
		shorten(val, n) {
			return val.length > n ? val.substr(0, n - 1) + '...' : val
		},
	},
}
</script>

<style scoped lang="sass">
.button
    background: transparent
    border: 1px solid rgba(214, 214, 214, 0.5)
    border-radius: 4px
    font-size: 1em
    font-weight: 70
    width: 8.5vw
    height: 11vw
    max-width: 160px
    max-height: 210px

    display: flex
    align-items: center
    justify-content: flex-start
    flex-direction: column
    margin: 10px 10px 10px 0px
    cursor: pointer
    position: relative
    transition: all 400ms ease
    &:before
        content: " "
        position: absolute
        z-index: 10
        top: -11px
        right: -11px
        background-image: url('~@/assets/icons/select-check.png')
        width: 25px
        height: 25px
        opacity: 0
        transform: scale(0)
        transition: all 300ms ease
        transition-delay: 100ms
        .label
            color: rgba(0,0,0,1)

    .inner
        background: white
        height: 100%
        width: 100%
        display: flex
        align-items: center
        justify-content: center
        flex-direction: column
        overflow: hidden
        border-radius: 4px
        position: relative
        .index
            position: absolute
            top: 5px
            left: 5px
            width: 20px
            height: 20px
            color: lightgrey
            font-weight: 700
            font-size: 12px
            display: flex
            align-items: center
            justify-content: center
        .label
            font-size: 0.9em
            text-align: center
            color: rgba(0,0,0,0.9)
            font-weight: 700
            word-break: break-all
            height: 3em
            overflow: hidden
            text-overflow: ellipsis
            width: 90%
            white-space: normal
            display: -webkit-box
            -webkit-line-clamp: 2
            -webkit-box-orient: vertical

    &.fade
        filter: opacity(0.5)
    &.active-state
        border-color: rgba(140,140,140,1)
        &:before
            opacity: 1
            transform: scale(1)
    &:hover
        filter: opacity(0.8)
        border-color: rgb(210,210,210)
</style>