<template>
	<div class="wrapper d-flex justify-center items-center fill-height">
		<div class="close-btn" @click="closeFn"><v-icon color="white">mdi-check</v-icon>Speichern</div>
		<div>
			<div v-if="!selectedVariantColor">
				<BoxButton :label="$getDictString('configurator_variants_warning_select_color')" class="white-background" />
			</div>
			<div v-if="selectedVariantColor" class="pt-5 d-flex justify-center items-center">
				<BoxButton
					hasColor
					displayOnly
					v-if="selectedProduct.shopName === 'stanley-stella'"
					:label="selectedProduct.colors.find((f) => f.value === selectedVariantColor).value"
					:image="`${COLOR_DOT_BASE_URL}/${selectedProduct.colors.find((f) => f.value === selectedVariantColor).hexValue}.png`"
					class="white-background"
				/>
				<BoxButton
					hasColor
					displayOnly
					v-if="selectedProduct.isManualProduct || selectedProduct.shopName === 'l-shop' || selectedProduct.shopName === 'custom'"
					:label="selectedProduct.colors.find((f) => f.value === selectedVariantColor).value"
					:hexColor="selectedProduct.colors.find((f) => f.value === selectedVariantColor).hexValue"
					class="white-background"
				/>
			</div>
			<TextileColorModal :selectedProduct="selectedProduct" :selectedVariantId="selectedVariantId" />
		</div>
	</div>
</template>

<script>
import { mapFields } from 'vuex-map-fields'

import TextileColorModal from './TextileColorModal'
import BoxButton from '@/views/configurator/components/BoxButton'

export default {
	name: 'EditTextile',
	components: { BoxButton, TextileColorModal },
	props: {
		selectedProduct: { type: Object, required: true },
		selectedVariantId: { type: Number, required: true },
		closeFn: { type: Function, required: true },
	},
	data() {
		return {
			COLOR_DOT_BASE_URL: process.env.VUE_APP_AWS_IMAGES_BASE_URL + '/stanley-stella-colors',
		}
	},
	computed: {
		...mapFields('Configurator', {
			variants: 'variants',
		}),
		selectedVariantColor: {
			get() {
				return this.variants[this.selectedVariantId] && this.variants[this.selectedVariantId].color
			},
			set(colorObj) {
				this.$store.dispatch('Configurator/updateVariant', { key: 'color', value: colorObj.value })
				this.$store.dispatch('Configurator/updateVariant', { key: 'hexColor', value: colorObj.hexValue })
			},
		},
	},
}
</script>

<style scoped lang="sass">
.wrapper
	position: relative
	text-align: center

	.close-btn
		position: absolute
		bottom: -16px
		left: 15%
		background: black
		font-weight: 700
		font-size: 1.1em
		color: white
		padding: 6px 6px
		width: 70%
		text-align: center
		cursor: pointer
		z-index: 100
		border: solid 2px black
		transition: all 150ms
		i
			padding-right: 5px
		&:hover
			background: green
			border-color: green
</style>
