<template>
	<div class="ani-container">
		<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 126.213 141.559">
			<g id="HOODIE_FRONT" transform="translate(-588.499 -398.328)">
				<g id="LINES" transform="translate(0.023 -1.47)">
					<path
						id="Path_1858"
						data-name="Path 1858"
						d="M1448.846,786.712l-.005,0c.009-.052.024-.1.036-.15Z"
						transform="translate(-752 -366)"
						fill="#9cb092"
					/>
					<path
						id="Path_1859"
						data-name="Path 1859"
						d="M1449.61,857.049s4.767,1.782,9.023.974C1458.633,858.023,1450.971,857.388,1449.61,857.049Z"
						transform="translate(-752 -366)"
						fill="rgba(0,0,0,0)"
					/>
					<path
						id="Path_1860"
						data-name="Path 1860"
						d="M1450.8,855.534s3.915,2.2,7.831,1.515Z"
						transform="translate(-752 -366)"
						fill="rgba(0,0,0,0)"
					/>
					<path
						id="Path_1861"
						data-name="Path 1861"
						d="M1449.61,859.347a14.863,14.863,0,0,0,4.511,0l-4.511-.372Z"
						transform="translate(-752 -366)"
						fill="rgba(0,0,0,0)"
					/>
					<path
						id="Path_1862"
						data-name="Path 1862"
						d="M1439.773,888.206c-.2-.124-1.072.211-1.072.211l-.435-1.571-.429-1.07-.479-1.619s1.176-.256,1.216-.136S1440.019,888.355,1439.773,888.206Z"
						transform="translate(-752 -366)"
						fill="#1a1818"
					/>
					<path
						id="Path_1863"
						data-name="Path 1863"
						d="M1448.689,785.759a22.206,22.206,0,0,0-7.04-2.824c-4.407-1.1-5.887-2.143-5.887-2.143s-2.933-1.192-5.838-2.308"
						transform="translate(-752 -366)"
						fill="rgba(0,0,0,0)"
						stroke="#5a5b5e"
						stroke-width="0.088"
						stroke-dasharray="0.706 0.353"
					/>
					<path
						id="Path_1864"
						data-name="Path 1864"
						d="M1430.9,778.217c2.3.922,4.419,1.836,4.419,1.836s1.468,1.046,5.85,2.15a23.3,23.3,0,0,1,7.271,2.988c.784.47.224,1.467-.323,3.355s-2.712,12.267-2.712,12.267c-.88,4.509-3.994,21.863-3.994,21.863s-.357,1.9-.709,4.117l-.064.921c-.262,1.694-.488,3.433-.535,4.55a88.018,88.018,0,0,1-1.181,10.2,6.47,6.47,0,0,0-.292,1.829c.579,4.495.787,21.284.835,25.2a31.8,31.8,0,0,1-1.1,7.949c-.048.262-.757,2.146-1.152,4.026s.786,4.393,1.169,5.6a20.873,20.873,0,0,1,.677,4.078c.121,1.307.511,3.188.7,5.909a36.676,36.676,0,0,1,.077,6.169c-.155.159-.252.89-.814,1.833s-3.676,1.668-6.389,2.087-10.023-.437-14.789-1.249c-4.592-.782-9.543-.679-13.415-.994a63.49,63.49,0,0,0-11.135.655c-1.885.215-5.933-.185-13.25.285a21.741,21.741,0,0,1-13.374-3.348c-1-.731-.5-2.143-.341-2.562a4.874,4.874,0,0,0,.03-2.25,13.906,13.906,0,0,1,.3-4.495,23.688,23.688,0,0,0,.639-3.243,13.413,13.413,0,0,1,1.529-3.348,1.186,1.186,0,0,0-.072-1.463,26.89,26.89,0,0,1-2.294-3.87c-.862-1.882.155-8.42.752-10.823a64.707,64.707,0,0,1,2.334-7.01c.47-1.15.681-2.874.316-7.163s-.941-12.34-.941-12.6-.116-4.967-.111-8.849c0-1.389-.148-2.513-.155-3.43a8.95,8.95,0,0,0-.216-2.3l-.034-.269-.07-.13-.263-1.673c-.327-2.013-.638-3.657-.638-3.657s-3.114-17.354-4-21.863c0,0-2.161-10.38-2.709-12.268s-1.11-2.882-.323-3.355a23.306,23.306,0,0,1,7.267-2.988c4.383-1.1,5.852-2.149,5.852-2.149s2.05-.87,4.268-1.747"
						transform="translate(-752 -366)"
						fill="rgba(0,0,0,0)"
						stroke="#47484a"
						stroke-width="0.133"
					/>
					<path
						id="Path_1865"
						data-name="Path 1865"
						d="M1366.84,892.4a21.974,21.974,0,0,0,13.2,3.225c7.317-.473,11.365-.075,13.25-.284a62.891,62.891,0,0,1,11.135-.656c3.872.312,8.823.208,13.415.989,4.766.815,12.074,1.672,14.789,1.253s5.826-1.148,6.389-2.089a6.36,6.36,0,0,0,.428-.865"
						transform="translate(-752 -366)"
						fill="rgba(0,0,0,0)"
						stroke="#464749"
						stroke-width="0.179"
					/>
					<path
						id="Path_1866"
						data-name="Path 1866"
						d="M1366.9,892.1a22.027,22.027,0,0,0,13.141,3.182c7.317-.472,11.365-.068,13.25-.285a63.625,63.625,0,0,1,11.135-.653c3.872.313,8.823.209,13.415.992,4.766.811,12.074,1.67,14.789,1.252s5.826-1.15,6.389-2.091a6.455,6.455,0,0,0,.428-.862"
						transform="translate(-752 -366)"
						fill="rgba(0,0,0,0)"
						stroke="#5a5b5e"
						stroke-width="0.088"
						stroke-dasharray="0.706 0.353"
					/>
					<path
						id="Path_1867"
						data-name="Path 1867"
						d="M1366.774,892.7a21.892,21.892,0,0,0,13.265,3.269c7.317-.472,11.365-.072,13.25-.284a63,63,0,0,1,11.135-.655c3.872.316,8.823.209,13.415.993,4.766.81,12.074,1.668,14.789,1.249s5.826-1.146,6.389-2.089a6.24,6.24,0,0,0,.428-.864"
						transform="translate(-752 -366)"
						fill="rgba(0,0,0,0)"
						stroke="#5a5b5e"
						stroke-width="0.088"
						stroke-dasharray="0.706 0.353"
					/>
					<path
						id="Path_1868"
						data-name="Path 1868"
						d="M1360.01,786.6a23.72,23.72,0,0,1,7.306-2.979c4.408-1.1,5.884-2.144,5.884-2.144s2.724-1.108,5.521-2.187"
						transform="translate(-752 -366)"
						fill="rgba(0,0,0,0)"
						stroke="#5a5b5e"
						stroke-width="0.088"
						stroke-dasharray="0.706 0.353"
					/>
					<path
						id="Path_1869"
						data-name="Path 1869"
						d="M1404.959,856.093c.164.589.165,1.4.39,2.036a38.718,38.718,0,0,0,1.646,3.525,12.879,12.879,0,0,1,1.378,3.952,45.268,45.268,0,0,0,.876,5.989,14.913,14.913,0,0,0,1.456,3.688c.526.877,1.129,1.7,1.62,2.615a36.052,36.052,0,0,0,3.286,5.155c.58.738,1.344,1.326,1.962,2.042.344.4,1.207,1.176,1.242,1.743.067,1.071-.935-.252-1.178-.575a17.6,17.6,0,0,0-1.592-1.77c.012.246-.136.309-.2.472a3.054,3.054,0,0,1-1.155-1.612,11.168,11.168,0,0,0-1.194-2.182c-.981-1.549-1.631-3.23-2.777-4.705-2.163-2.781-2.109-6.206-3.024-9.374a14.361,14.361,0,0,1-.549-3.192,4.874,4.874,0,0,0-.1-1.4,8.72,8.72,0,0,0-.925-1.328,25.465,25.465,0,0,1-.9-2.5,3.6,3.6,0,0,1-.379-1.119c-.054-.409.222-.966-.157-1.255"
						transform="translate(-752 -366)"
						fill="rgba(0,0,0,0)"
					/>
					<path
						id="Path_1872"
						data-name="Path 1872"
						d="M1368.363,829.12"
						transform="translate(-752 -366)"
						fill="rgba(0,0,0,0)"
						stroke="#464749"
						stroke-width="0.167"
					/>
					<path
						id="Path_1873"
						data-name="Path 1873"
						d="M1440.592,828.263s2.182,18.971,2.221,20.616.141,3.813.141,3.813a181.255,181.255,0,0,1,3.626,24.324c1.286,11.536,1.416,15.27,1.416,15.27s-.391,7.344.532,8.49,14,1.365,14.693.952,1.39-5.927,1.829-8.2c.445-2.329.873-8.073,1.089-10.407s.364-13.99.364-13.99c.567-8.686-1.2-15.109-1.446-22.171s-3.046-20.576-3.046-20.576c-.223-.533-3.258-10.325-3.632-12.374s-2.774-14.392-3.6-16.767a54.677,54.677,0,0,0-3.259-6.557c-.824-1.637-1.62-3.642-2.782-4.668"
						transform="translate(-752 -366)"
						fill="rgba(0,0,0,0)"
						stroke="#464749"
						stroke-width="0.196"
					/>
					<path
						id="Path_1875"
						data-name="Path 1875"
						d="M1421.056,861.446"
						transform="translate(-752 -366)"
						fill="rgba(0,0,0,0)"
						stroke="#1a1818"
						stroke-width="0.166"
					/>
					<path
						id="Path_1876"
						data-name="Path 1876"
						d="M1385.7,861.446"
						transform="translate(-752 -366)"
						fill="rgba(0,0,0,0)"
						stroke="#1a1818"
						stroke-width="0.166"
					/>
					<path
						id="Path_1882"
						data-name="Path 1882"
						d="M1341.676,893.163s15.861.532,17.324-.606"
						transform="translate(-752 -366)"
						fill="rgba(0,0,0,0)"
						stroke="#5a5b5e"
						stroke-width="0.088"
						stroke-dasharray="0.706 0.353"
					/>
					<path
						id="Path_1883"
						data-name="Path 1883"
						d="M1341.676,893.843s15.861.534,17.324-.6"
						transform="translate(-752 -366)"
						fill="rgba(0,0,0,0)"
						stroke="#5a5b5e"
						stroke-width="0.088"
						stroke-dasharray="0.706 0.353"
					/>
					<path
						id="Path_1995"
						data-name="Path 1995"
						d="M1343.243,901.325s.052.425,1.076.493a73.544,73.544,0,0,0,13.158-.493,1.6,1.6,0,0,0,1.141-1.046s.549-1,.465-4.359-.014-3.057-.033-3.046c-.043.026-.1.062-.123.072-1.846,1.072-17.251.557-17.251.557S1342.508,900.041,1343.243,901.325Z"
						transform="translate(-752 -366)"
						fill="rgba(0,0,0,0)"
						stroke="#464749"
						stroke-width="0.233"
					/>
					<path
						id="Path_1996"
						data-name="Path 1996"
						d="M1464.96,893.163s-15.535.532-16.968-.606"
						transform="translate(-752 -366)"
						fill="rgba(0,0,0,0)"
						stroke="#5a5b5e"
						stroke-width="0.088"
						stroke-dasharray="0.706 0.353"
					/>
					<path
						id="Path_1997"
						data-name="Path 1997"
						d="M1464.96,893.843s-15.535.534-16.968-.6"
						transform="translate(-752 -366)"
						fill="rgba(0,0,0,0)"
						stroke="#5a5b5e"
						stroke-width="0.088"
						stroke-dasharray="0.706 0.353"
					/>
					<path
						id="Path_1998"
						data-name="Path 1998"
						d="M1463.423,901.325s-.048.425-1.052.493a70.558,70.558,0,0,1-12.887-.493,1.574,1.574,0,0,1-1.117-1.046s-.539-1-.457-4.359.082-3.021.082-3.021c1.433,1.136,16.968.6,16.968.6S1464.145,900.041,1463.423,901.325Z"
						transform="translate(-752 -366)"
						fill="rgba(0,0,0,0)"
						stroke="#464749"
						stroke-width="0.233"
					/>
					<path
						id="Path_1999"
						data-name="Path 1999"
						d="M1386.859,782.835s12.5,5.555,14.175,6.887a7.732,7.732,0,0,0,3.63,1.528,5.918,5.918,0,0,0,2.539-.928,45.672,45.672,0,0,1,4.343-2.677c1.085-.541,9.271-4.544,11.262-5.441"
						transform="translate(-752 -366)"
						fill="rgba(0,0,0,0)"
						stroke="#5a5b5e"
						stroke-width="0.084"
						stroke-dasharray="0.671 0.335"
					/>
					<path
						id="Path_2000"
						data-name="Path 2000"
						d="M1404.619,790.475c2.294.058,3.52-2.885,12.553-5.955a66.791,66.791,0,0,0,11.891-5.661c2.026-1.02,2.223-.2,2.061-1.522-.242-1.96-3.086-4.835-4.078-6.581s-1.187-4.552-4.085-4.62-11.842.744-14.5.673-7.6.182-10.134.107-11.246-1.5-13.527-.9-3.926,3.9-4.5,5.956a12.476,12.476,0,0,1-2.379,4.9c-1.906,1.792-.317,2.061.381,2.264,1.461.428,4.236,2.33,8.554,3.694C1401,787.3,1401.845,790.407,1404.619,790.475Z"
						transform="translate(-752 -366)"
						fill="rgba(0,0,0,0)"
						stroke="#464749"
						stroke-width="0.196"
					/>
					<path
						id="Path_2002"
						data-name="Path 2002"
						d="M1397.319,784a.245.245,0,0,1,.311-.133l2.658,1.527a.246.246,0,0,1,.042.335h0a.242.242,0,0,1-.308.128l-2.658-1.526a.24.24,0,0,1-.045-.331Z"
						transform="translate(-752 -366)"
						fill="rgba(0,0,0,0)"
						stroke="#464749"
						stroke-width="0.196"
					/>
					<path
						id="Path_2005"
						data-name="Path 2005"
						d="M1412.4,783.911a.239.239,0,0,1-.052.33l-2.679,1.482a.237.237,0,0,1-.3-.136h0a.24.24,0,0,1,.047-.332l2.683-1.483a.242.242,0,0,1,.306.139Z"
						transform="translate(-752 -366)"
						fill="rgba(0,0,0,0)"
						stroke="#464749"
						stroke-width="0.196"
					/>
					<path
						id="Path_2009"
						data-name="Path 2009"
						d="M1399.687,807.831c.075-3.631.267-11.829.306-12.878.04-.946-.037-1.881-.022-2.826.022-1.257.079-2.651.07-3.905a19.734,19.734,0,0,0-.028-2.494c-.028-.2-.295-.394-.385-.574-.322-.174-.708-.3-1.018-.5-.136-.086-.751-.609-.856-.546a.29.29,0,0,0-.1.241c.165.164.312.353.483.5a.933.933,0,0,1,.388.758c.07.424.178,1.49.186,1.916.018.885-.076,4.807-.155,7.127-.068,1.958-.2,10.574-.24,13.235l1.068.022Z"
						transform="translate(-752 -366)"
						fill="rgba(0,0,0,0)"
						stroke="#464749"
						stroke-width="0.196"
					/>
					<path
						id="Path_2010"
						data-name="Path 2010"
						d="M1399.122,811.477l-.495-.007.069-3.576.5.013Z"
						transform="translate(-752 -366)"
						fill="rgba(0,0,0,0)"
						stroke="#464749"
						stroke-width="0.196"
					/>
					<path
						id="Path_2011"
						data-name="Path 2011"
						d="M1410.49,808.3c.062-2.479.25-11.712.262-13.71.017-2.32.092-6.68.144-7.561a12.9,12.9,0,0,1,.241-1.493c.086-.427-.072-.374.22-.625.171-.146.314-.329.478-.482a.3.3,0,0,0-.079-.246c-.1-.071-.7.425-.837.507-.3.181-.67.293-.985.456a2.028,2.028,0,0,0-.2.562,19.289,19.289,0,0,0-.136,2.4c-.056,1.253-.091,2.51-.119,3.769-.022.943-.043,1.888-.043,2.837,0,1.1-.166,10.214-.236,13.543l1.08.02Z"
						transform="translate(-752 -366)"
						fill="rgba(0,0,0,0)"
						stroke="#464749"
						stroke-width="0.196"
					/>
					<path
						id="Path_2012"
						data-name="Path 2012"
						d="M1410.067,811.951l-.5-.012.072-3.676.495.008Z"
						transform="translate(-752 -366)"
						fill="rgba(0,0,0,0)"
						stroke="#464749"
						stroke-width="0.196"
					/>
					<path
						id="Path_2013"
						data-name="Path 2013"
						d="M1413.262,780.468h-.043"
						transform="translate(-752 -366)"
						fill="rgba(0,0,0,0)"
						stroke="#464749"
						stroke-width="0.179"
					/>
					<path
						id="Path_2014"
						data-name="Path 2014"
						d="M1416.158,779.337"
						transform="translate(-752 -366)"
						fill="rgba(0,0,0,0)"
						stroke="#464749"
						stroke-width="0.179"
					/>
					<path
						id="Path_2015"
						data-name="Path 2015"
						d="M1391.8,778.848c3.166-.145,7.406-.242,12.071-.094,5.121.167,9.361.381,12.448.609"
						transform="translate(-752 -366)"
						fill="rgba(0,0,0,0)"
						stroke="#464749"
						stroke-width="0.179"
					/>
					<path
						id="Path_2016"
						data-name="Path 2016"
						d="M1413.219,780.468a.388.388,0,0,0-.06-.005c-2.44-.147-5.524-.292-9.378-.416-3.35-.107-6.541-.059-9.219.049"
						transform="translate(-752 -366)"
						fill="rgba(0,0,0,0)"
						stroke="#464749"
						stroke-width="0.179"
					/>
					<path
						id="Path_2018"
						data-name="Path 2018"
						d="M1413.9,780.216c-2.554-.161-5.876-.325-10.138-.463-3.619-.116-7.049-.048-9.86.076"
						transform="translate(-752 -366)"
						fill="rgba(0,0,0,0)"
						stroke="#5a5b5e"
						stroke-width="0.088"
						stroke-dasharray="0.706 0.353"
					/>
					<path
						id="Path_2019"
						data-name="Path 2019"
						d="M1392.34,779.119c3.1-.131,7.115-.209,11.5-.068,4.9.159,8.844.34,11.881.552"
						transform="translate(-752 -366)"
						fill="rgba(0,0,0,0)"
						stroke="#5a5b5e"
						stroke-width="0.088"
						stroke-dasharray="0.706 0.353"
					/>
					<path
						id="Path_2026"
						data-name="Path 2026"
						d="M1404.225,778.67s-1.192-8.246-.34-10.972a.949.949,0,0,0-.059-.846"
						transform="translate(-752 -366)"
						fill="rgba(0,0,0,0)"
						stroke="#464749"
						stroke-width="0.196"
					/>
					<path
						id="Path_2027"
						data-name="Path 2027"
						d="M1404.566,778.67s-1.191-8.246-.341-10.972c0,0,.165-.587,0-.857"
						transform="translate(-752 -366)"
						fill="rgba(0,0,0,0)"
						stroke="#5a5b5e"
						stroke-width="0.084"
						stroke-dasharray="0.671 0.335"
					/>
					<path
						id="Path_2028"
						data-name="Path 2028"
						d="M1403.885,778.67s-1.192-8.246-.341-10.972c0,0,.1-.711-.123-.835"
						transform="translate(-752 -366)"
						fill="rgba(0,0,0,0)"
						stroke="#5a5b5e"
						stroke-width="0.084"
						stroke-dasharray="0.671 0.335"
					/>
					<path
						id="Path_2029"
						data-name="Path 2029"
						d="M1394.443,780.1a26.692,26.692,0,0,1,6.954,3.81c1.145.965,2.2,3.068,3.454,3.181,1.777.154,2.248-3.732,6.132-5.639.694-.338,1.387-.638,2.076-.912l3.707-1.343a14.2,14.2,0,0,0,4.287-2.1c2.171-1.819,4.834-4.1,4.543-6.641s-2.4-3.91-8.418-3.483-8.4.769-12.164.717-12.4-.438-14.788-.947-5.934.031-6.908,2.036-2.33,3.736.585,5.646a78.138,78.138,0,0,0,7.635,4.36l2.905,1.317"
						transform="translate(-752 -366)"
						fill="rgba(0,0,0,0)"
						stroke="#96999c"
						stroke-width="0.5"
					/>
					<path
						id="Path_2030"
						data-name="Path 2030"
						d="M1359.96,786.733a12.882,12.882,0,0,0-2.7,4.091,54.252,54.252,0,0,0-3.345,6.557c-.846,2.375-3.308,14.721-3.694,16.767s-3.5,11.84-3.73,12.374c0,0-2.709,13.26-3.136,20.3s-3.084,13.431-2.724,22.131c0,0-.143,11.659.019,13.994s.455,8.093.856,10.43c.39,2.281,1.232,8.006,1.931,8.437s13.862.34,14.836-.785.762-8.475.762-8.475.226-3.729,1.838-15.231c0,0,2.028-10.991,5.36-24.225,0,0,.161-2.163.241-3.81s1.871-20.336,1.871-20.336"
						transform="translate(-752 -366)"
						fill="rgba(0,0,0,0)"
						stroke="#464749"
						stroke-width="0.198"
					/>
				</g>
			</g>
		</svg>
	</div>
</template>

<script>
import { gsap } from 'gsap'
import { DrawSVGPlugin } from 'gsap/DrawSVGPlugin'

gsap.registerPlugin(DrawSVGPlugin)
export default {
	name: 'HoodieAnimation',
	props: { type: String },
	methods: {},
	mounted() {
		gsap.from('svg path', { duration: 1, stagger: 0.01, drawSVG: 0 })
	},
}
</script>

<style lang="sass" scoped>
.ani-container
	max-height: 100%
	width: 100%
	height: 100%
	padding: 20px 10px
	display: flex
	justify-content: center
	align-items: center
	svg
		transform-origin: 50% 50%
		width: 65%
		path
			stroke-width: 0.4px
			stroke-dasharray: 5px
// .ani-container
// 	width: 65%
// 	padding: 40px 20px
// 	display: flex
// 	justify-content: center
// 	align-items: center
// 	svg 
// 		transform-origin: 50% 50%
// 		height: 100%
// 		path
// 			stroke-width: 0.4px
// 			stroke-dasharray: 5px
</style>