<template>
	<div class="ani-container">
		<svg xmlns="http://www.w3.org/2000/svg" width="252.898" height="390.335" viewBox="0 0 252.898 390.335">
        <g id="Group_1" data-name="Group 1" transform="translate(-826.429 -334.583)">
            <path id="Path_7" data-name="Path 7" d="M0,0,19.766,22.984" transform="translate(881.616 485.283)" fill="none" stroke="#000" stroke-width="2"/>
            <path id="Path_6" data-name="Path 6" d="M0,0,18.847,23.263" transform="translate(1003.429 485.464)" fill="none" stroke="#000" stroke-width="2"/>
            <path id="Path_5" data-name="Path 5" d="M0,23.164,17.008,0" transform="translate(883.455 485.743)" fill="none" stroke="#000" stroke-width="2"/>
            <path id="Path_4" data-name="Path 4" d="M-4.752,22.369,14.44,0" transform="translate(1007.835 485.923)" fill="none" stroke="#000" stroke-width="2"/>
            <path id="Path_1" data-name="Path 1" d="M682.705,441.893V663.12c0,10.085,8.994,16.728,22.382,16.728H911.864c23.119,0,20.689-10.028,20.689-16.728s-2.979-221.227-2.979-221.227Z" transform="translate(145.224 43.571)" fill="none" stroke="#000" stroke-width="3"/>
            <path id="Path_2" data-name="Path 2" d="M682.705,492.12H929.8" transform="translate(145.224 16.431)" fill="none" stroke="#000" stroke-width="3"/>
            <path id="Path_3" data-name="Path 3" d="M802.941,227.6S789.368,54.584,873.574,55.134s67.7,172.467,67.7,172.467H922.14s20-164.323-44.338-164.323S820.463,227.6,820.463,227.6Z" transform="translate(80.693 280.951)" fill="none" stroke="#000" stroke-width="3"/>
        </g>
        </svg>


	</div>
</template>



<script>
import { gsap } from 'gsap'
import { DrawSVGPlugin } from 'gsap/DrawSVGPlugin'

gsap.registerPlugin(DrawSVGPlugin)
export default {
	name: 'OtherAnimation',
	props: { type: String },
	methods: {},
	mounted() {
		gsap.from('svg path', { duration: 1, stagger: 0.01, drawSVG: 0 })
	},
}
</script>

<style lang="sass" scoped>
.ani-container
	max-height: 100%
	width: 100%
	height: 100%
	padding: 20px 10px
	display: flex
	justify-content: center
	align-items: center
	svg
		transform-origin: 50% 50%
		width: 65%
		path
			stroke-width: 1.5px
			stroke-dasharray: 5px

</style>