<template>
	<!-- <v-img
		:src="getColorImage()"
		:lazy-src="getColorImage()"
		:class="{ image: true }"
		max-width="100%"
		max-height="100%"
		height="100%"
		contain
		right
	>
		<template v-slot:placeholder>
			<v-row class="fill-height ma-0" align="center" justify="center">
				<v-progress-circular indeterminate color="black"></v-progress-circular>
			</v-row>
		</template>
	</v-img> -->
	<div style="height: 100%">
		<v-carousel
			hide-delimiter-background
			height="100%"
			max-width="100%"
			max-height="100%"
			:continuous="false"
			color="black"
			:show-arrows="false"
			delimiter-icon="mdi-circle-medium"
		>
			<!-- ONLY FOR VUETIFY >2.4 <template v-slot:prev="{ on, attrs }">
				<v-btn color="success" v-bind="attrs" v-on="on">Previous slide</v-btn>
			</template>
			<template v-slot:next="{ on, attrs }">
				<v-btn v-bind="attrs" v-on="on">Next slide</v-btn>
			</template> -->
			<v-carousel-item
				v-for="(url, idx) in getColorImages()"
				:key="idx"
				reverse-transition="fade-transition"
				transition="fade-transition"
				height="100%"
			>
				<v-img :src="url" :class="{ image: true }" max-width="100%" max-height="100%" height="100%" contain right>
					<template v-slot:placeholder>
						<v-row class="fill-height ma-0" align="center" justify="center">
							<v-progress-circular indeterminate color="black"></v-progress-circular>
						</v-row>
					</template>
				</v-img>
			</v-carousel-item>
		</v-carousel>
	</div>
</template>

<script>
import { mapFields } from 'vuex-map-fields'

export default {
	name: 'StockImage',
	props: {
		product: { type: Object, required: true },
		imageType: { type: String, required: false, default: 'studio' },
	},
	computed: {
		...mapFields('Configurator', {
			variants: 'variants',
		}),
	},
	data() {
		return {
			baseUrl: process.env.VUE_APP_BASE_URL,
		}
	},
	methods: {
		getColorImages() {
			const objectToArray = (object) => {
				const allowedKeys = ['studio', 'detail', 'front', 'back', 'side']
				const array = []
				allowedKeys.forEach((key) => {
					if (object && object[key] && object[key] !== '' && !object[key].match('image-not-found')) {
						// const imageWidth = 400
						// const url = `${this.baseUrl}/images/${imageWidth}/${encodeURIComponent(object[key])}`
						const url = object[key]
						array.push(url)
					}
				})
				return array
			}

			if (this.product) {
				// if (this.product.isSingleImageSet) {
				// 	return objectToArray(this.product.singleImageSet)
				// } else {
				const variant = this.variants[0]
				if (variant && this.product.colors) {
					const colorVariant = this.product.colors.find((ci) => ci.hexValue === variant.hexColor)
					if (colorVariant) {
						return objectToArray(colorVariant.images)
					}
				}
			} else {
				return ['/image-not-found.png']
			}
		},
		// getColorImage() {
		// 	let image = '/image-not-found.png'

		// 	if (this.product && this.product.colorImages && this.selectedColors && this.selectedColors.length > 0) {
		// 		const colorObj = this.product.colorImages.find((ci) => ci.colorCode === this.selectedColors[0].hexValue)
		// 		image = colorObj ? colorObj[this.imageType] : '/image-not-found.png'
		// 	} else if (this.product && this.product.colorImages && this.product.colorImages.length > 0) {
		// 		const colorObj = this.product.colorImages[0]
		// 		image = colorObj ? colorObj[this.imageType] : '/image-not-found.png'
		// 	} else if (this.product) image = this.product.mainImage

		// 	if (!image) image = '/image-not-found.png'

		// 	console.log(image)

		// 	return image
		// },
	},
}
</script>

<style lang="sass">
.v-carousel__controls .v-item-group
	// border: solid 1px rgba(0,0,0,0.3)
	border-radius: 20px
	position: relative
	top: 0px
	button
		margin: 0
		color: black !important
		&.v-btn--active
			color: black !important
		&:before
			opacity: 0 !important
</style>
