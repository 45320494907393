<template>
	<div class="wrapper">
		<InfoBlock type="textile" />
		<v-card class="main-selection-container elevation-0" ref="product">
			<InfoBlockTrigger type="textile" />
			<div class="row config-row pl-10">
				<SectionLabel :label="$getDictString('configurator_textile_heading_type')" />

				<v-slide-group v-model="selectedCategory" center-active mandatory prev-icon="" next-icon="" ref="categorySlider">
					<v-slide-item v-for="(category, idx) in allCategories" :key="idx" :value="category.code" v-slot="{ active, toggle }">
						<v-card
							class="flex justify-center items-center flex-column"
							flat
							@click="toggle"
							@click.native="selectedProduct = ''"
						>
							<TextButton :label="category.code" :active="active" medium />
						</v-card>
					</v-slide-item>
				</v-slide-group>
			</div>

			<div class="config-row pl-10 pr-5">
				<!-- <SectionLabel label="Artikel" class="mt-8" /> -->

				<!-- <InfoText
					text="Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat"
				/> -->

				<SectionLabel :label="$getDictString('configurator_textile_heading_select')" class="mt-5" />

				<div class="box-button-group mt-2">
					<BoxButton
						v-for="(product, idx) in productByCat(selectedCategory, 12)"
						:key="idx"
						:label="product.name"
						:tooltip="true"
						:image="mainImageUrl(product.mainImage)"
						:active="selectedProductId === product._id"
						:fade="selectedProduct ? true : false"
						:shopName="product.shopName"
						@click.native="selectedProductId = product._id"
					/>
					<!-- <BoxButton label="ALLE ANZEIGEN" image="product.mainImage" @click.native="selectedProductModalState = true" /> -->
				</div>
				<div class="color-slider mt-1 mb-10">
					<v-row v-if="selectedProduct && selectedProduct.name == 'Custom'">
						<v-col cols="12">
							<SectionLabel :label="$getDictString('configurator_textile_custom_description_heading')" class="mt-5 ml-0" />
							<div class="mt-1">
								<v-textarea
									class="custom-description"
									counter
									maxlength="300"
									color="black"
									full-width
									no-resize
									outlined
									placeholder="Describe"
									v-model="customProductDescription"
								>
								</v-textarea>
							</div>
						</v-col>
					</v-row>
					<v-row v-if="selectedProduct">
						<v-col cols="4">
							<SectionLabel :label="$getDictString('configurator_textile_heading_color_select')" class="mt-5 ml-0" />
							<div v-if="!selectedVariantColor">
								<BoxButton label="farbe auswählen" class="white-background" />
							</div>
							<!-- <div v-if="selectedProduct.shopName === 'stanley-stella'">
											{{ selectedVariantColor }}
										</div> -->
							<div v-if="selectedVariantColor">
								<BoxButton
									v-if="selectedProduct.shopName === 'stanley-stella'"
									hasColor
									:label="getColor(selectedVariantColor).value"
									:image="`${COLOR_DOT_BASE_URL}/${getColor(selectedVariantColor).hexValue}.png`"
									class="white-background"
								/>
								<BoxButton
									v-if="
										selectedProduct.isManualProduct ||
										selectedProduct.shopName === 'l-shop' ||
										selectedProduct.shopName === 'custom'
									"
									hasColor
									:label="getColor(selectedVariantColor).value"
									:hexColor="getColor(selectedVariantColor).hexValue"
									class="white-background"
								/>
							</div>
						</v-col>
						<v-col cols="8">
							<div v-if="selectedProduct" class="color-column mt-1">
								<v-item-group class="color-selection-container d-flex pt-10 flex-wrap" v-model="selectedVariantColor">
									<v-item
										class="color-selection-wrapper"
										v-for="(color, idx) in selectedProduct.colors"
										:key="idx"
										:value="color.value"
									>
										<span v-if="color.activeState">
											<v-btn
												class="color-selection-item ma-2"
												fab
												small
												plain
												depressed
												:value="color.value"
												@click.native="selectedVariantColor = color"
												:class="{ selected: selectedVariantColor == color.value }"
												:color="'#' + color.hexValue"
												:style="{
													backgroundColor: '#' + color.hexValue,
												}"
												v-if="
													selectedProduct.isManualProduct ||
													selectedProduct.shopName === 'l-shop' ||
													selectedProduct.shopName === 'custom'
												"
											>
												<span>{{ color.value }}</span>
											</v-btn>
											<v-btn
												class="color-selection-item ma-2"
												fab
												small
												plain
												depressed
												:value="color.value"
												@click.native="selectedVariantColor = color"
												:class="{ selected: selectedVariantColor == color.value }"
												v-if="selectedProduct.shopName === 'stanley-stella'"
												:style="{
													backgroundImage: `url(${COLOR_DOT_BASE_URL}/${color.hexValue}.png)`,
													backgroundSize: '100%',
												}"
											>
												<span>{{ color.value }}</span>
											</v-btn>
										</span>
									</v-item>
								</v-item-group>
							</div>
						</v-col>

						<v-col cols="6">
							<SectionLabel :label="$getDictString('configurator_textile_heading_desription')" class="mt-1 ml-0" />
							<!-- <InfoText
								:text="`${$getDictString('configurator_textile_manufacturer_label')}: `"
								class="descr-text"
								
							/> -->
							<h2 v-if="selectedProduct.manufacturer">{{ $getDictString('configurator_textile_manufacturer_label') }}</h2>
							<h3 v-if="selectedProduct.manufacturer">{{ selectedProduct.manufacturer }}</h3>
							<InfoText :text="selectedProduct.description" class="descr-text" />
						</v-col>
						<v-col cols="6">
							<SectionLabel :label="$getDictString('configurator_textile_heading_material')" class="mt-1 ml-0" />
							<InfoText :text="selectedProduct.materials" class="descr-text" />
							<SectionLabel :label="$getDictString('configurator_textile_heading_certificates')" class="mt-2 ml-0" />
							<InfoText :text="selectedProduct.certificates.replace(/(\s\|\s)+/gim, ' | ')" class="descr-text" />
						</v-col>
					</v-row>
				</div>
			</div>
		</v-card>
	</div>
</template>

<script>
import TextButton from '../components/TextButton'
import BoxButton from '../components/BoxButton'
import SectionLabel from '../components/SectionLabel'
import InfoText from '../components/InfoText'
import InfoBlock from '@/views/configurator/components/InfoBlock'
import InfoBlockTrigger from '@/views/configurator/components/InfoBlockTrigger'

import { mapGetters } from 'vuex'
import { mapFields } from 'vuex-map-fields'

export default {
	name: 'Product',
	components: { TextButton, SectionLabel, InfoText, BoxButton, InfoBlock, InfoBlockTrigger },
	data() {
		return {
			COLOR_DOT_BASE_URL: process.env.VUE_APP_AWS_IMAGES_BASE_URL + '/stanley-stella-colors',
			baseUrl: process.env.VUE_APP_BASE_URL,
			promise: null,
		}
	},
	computed: {
		...mapGetters({
			allProducts: 'InternalProducts/getProducts',
			productByCat: 'InternalProducts/getProductsByCategory',
			allCategories: 'InternalProducts/getCategories',
		}),
		...mapFields('InternalProducts', {
			loadingProducts: 'loading',
		}),
		...mapFields('ConfiguratorDisplay', {
			currentStep: 'currentStep',
		}),
		...mapFields('Configurator', {
			selectedCategory: 'category',
			selectedProduct: 'product',
			selectedColors: 'colors',
			variants: 'variants',
			selectedVariantId: 'selectedVariantId',
			customProductDescription: 'customProductDescription',
		}),
		selectedVariantColor: {
			get() {
				return this.variants[0] && this.variants[0].color
			},
			set(colorObj) {
				this.selectedVariantId = 0
				this.$store.dispatch('Configurator/updateVariant', { key: 'hexColor', value: colorObj.hexValue })
				this.$store.dispatch('Configurator/updateVariant', { key: 'color', value: colorObj.value }).then(() => {
					// reset selected variant id after update
					this.selectedVariantId = -1
				})
			},
		},
		selectedProductId: {
			get() {
				return this.$store.getters['Configurator/getSelectedProductId']
			},
			set(productId) {
				if (!this.selectedProduct || (this.selectedProduct && this.selectedProduct._id !== productId)) {
					this.$store
						.dispatch('Configurator/setProduct', productId)
						.then((newProductId) => {
							// we cannot cancel the promises here, so we have to check if its the right product
							if (Object.keys(this.variants).length === 0 && newProductId === productId) {
								// add first variant
								return this.$store.dispatch('Configurator/addVariant')
							}
						})
						.then(() => {
							// we cannot cancel the promises here, so we have to check if its the right product
							if (this.selectedProduct._id === productId) {
								// select first color and set hex value
								return this.$store.dispatch('Configurator/updateVariant', {
									key: 'hexColor',
									value: this.selectedProduct.colors[0].hexValue,
								})
							}
						})
						.then(() => {
							// we cannot cancel the promises here, so we have to check if its the right product
							if (this.selectedProduct._id === productId) {
								// set color name
								return this.$store.dispatch('Configurator/updateVariant', {
									key: 'color',
									value: this.selectedProduct.colors[0].value,
								})
							}
						})
						.then(() => {
							// reset selected variant id after update
							this.selectedVariantId = -1
						})
				}
			},
		},
	},
	methods: {
		countColors(colorArray) {
			return colorArray.length > 8 ? 11 : 4
		},
		submitProduct() {
			this.currentStep = 'motive'
		},
		mainImageUrl(url) {
			return url
			// const imageWidth = 100
			// return `${this.baseUrl}/images/${imageWidth}/${encodeURIComponent(url)}`
		},
		getColor(hexColor) {
			const color = this.selectedProduct.colors.find((f) => f.value === hexColor)

			if (color && color.value && color.hexValue) {
				return color
			} else {
				return {
					value: '',
					hexValue: '',
				}
			}
		},
	},
}
</script>

<style lang="sass" scoped>
h4
	font-size: 1.3em
h3
	font-size: 2.2em
	padding-bottom: 10px
.wrapper
	// padding-top: 40px
	margin-top: 100px

.main-selection-container
	border: 1px solid #ebebeb !important
	padding-top: 40px
	// margin-top: 150px
.transparent-background
	background: transparent !important
.config-row
	position: relative
	margin-top: 0
	margin-bottom: 0
.box-button-group
	display: flex
	flex-direction: row
	justify-content: flex-start
	align-items: flex-start
	flex-wrap: wrap
.nav-button
	position: fixed
	bottom: 0
	right: 0
	height: 80px
	display: flex
	align-items: center
	justify-content: space-between
	padding: 50px
	// background: linear-gradient(rgba(255,255,255,0), rgba(255,255,255,1))
	z-index: 11000
.custom-description
	font-size: 13px !important
	caret-color: black !important
.color-column
	.hidden-hint
		font-size: 12px
		opacity: 0
	&:hover
		.color-blob
			filter: opacity(0.7)
			transform: scale(0.85)
		.hidden-hint
			opacity: 0.4
.color-selection-container
	.color-selection-wrapper
		// border: solid 2px black
		border-radius: 50%

	.color-selection-item
		box-shadow: 0 1px 2px rgba(0,0,0,0.3) !important
		span
			opacity: 0
			position: absolute
			background: rgb(245,245,245)
			color: black
			top: 20px
			font-size: 10px
			padding: 3px 10px
			font-family: "grotta-semibold"
			text-transform: initial
		&:after
			height: 130%
			width: 130%
			display: inline-flex
			content: ""
			position: absolute
			background-color: rgba(255,255,255,0.1) !important
			background-image: url('~@/assets/icons/select-check.png')
			background-position: top right
			background-size: 40% auto
			opacity: 0
			transition: all 200ms ease-in
		&:hover
			// background: white !important
			span
				opacity: 1
		&:focus, &:active

			outline: none

		&.selected
			&:after
				opacity: 1
				background-size: 40% auto
			&:hover
				span
					opacity: 0 !important
.color-blob
	transition: all 140ms
	// transition: filter 200ms
	transform-origin: 14px 27px
	box-shadow: 0 1px 2px rgba(0,0,0,0.3) !important
	span
		opacity: 0
		position: absolute
		background: rgb(245,245,245)
		color: black
		top: 5px
		right: 20px
		font-size: 10px
		padding: 3px 10px
		ffont-family: "grotta-semibold"
		text-transform: initial
		transition: all 200ms ease
	&:hover
		filter: opacity(1) !important
		transform: scale(1.3) !important
		cursor: zoom-in
		z-index: 11111
		span
			transform: scale(0.8)
			opacity: 1
	&:focus, &:active
		// background: white !important
		// outline: none

	&.selected
		&:after
			// opacity: 1
			// background-size: 40% auto
		&:hover
			span
				// opacity: 0 !important
	.color-circle
		width: 30px
		height: 30px
		box-shadow: 0 1px 1px rgba(0,0,0,0.1)
	>div
		>div
			box-shadow: 0 0 1px rgba(0,0,0,0.3)
			border-radius: 50%
</style>
