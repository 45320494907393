<template>
	<div id="config-position" class="background-transparent">
		<InfoBlock type="variants" />
		<v-card class="main-selection-container elevation-0 background-transparent">
			<InfoBlockTrigger type="variants" />

			<v-col cols="12" class="pa-0">
				<div v-for="(variant, idx) in variants" :key="idx">
					<div class="variants-wrapper">
						<VariantItem
							:index="parseInt(idx)"
							:shopName="selectedProduct.shopName"
							:variant="variant"
							:finishes="productFinishes"
							:selectedProduct="selectedProduct"
							:canDelete="Object.keys(variants).length > 1"
						/>
					</div>
				</div>
			</v-col>

			<!-- <v-col class="pa-2" cols="1"> </v-col> -->
			<v-col class="pa-2 text-center" cols="11">
				<v-btn class="addButton" @click="addVariant('textile-print')">
					<v-icon class="pr-2"> mdi-plus </v-icon> {{ dictionary['configurator_variants_add_variant'] }}
				</v-btn>
			</v-col>

			<v-col class="pa-0" cols="1"> </v-col>
		</v-card>
	</div>
</template>

<script>
import InfoBlock from '@/views/configurator/components/InfoBlock'
import InfoBlockTrigger from '@/views/configurator/components/InfoBlockTrigger'

import { mapFields } from 'vuex-map-fields'

import VariantItem from '../partials/variants/VariantItem'

export default {
	name: 'Variants',
	components: { VariantItem, InfoBlock, InfoBlockTrigger },
	computed: {
		...mapFields('Dictionary', {
			dictionary: 'readableDictionary',
		}),
		...mapFields('Configurator', {
			selectedProduct: 'product',
			productFinishes: 'finishes',
			variants: 'variants',
			selectedVariantId: 'selectedVariantId',
		}),
	},
	created() {
		// close all variants, otherwise if one is open and browser is
		// reloaded "selectedVariantId" is still not -1 and the next-button is not shown
		this.selectedVariantId = -1
	},
	methods: {
		addVariant(type) {
			this.$store.dispatch('Configurator/addVariant', type)
		},
	},
}
</script>

<style scoped lang="sass">
#config-position
	margin-top: 100px
	.main-selection-container
		margin-top: 0
		padding-top: 0 !important

.background-transparent
	background: transparent
.white-background
	background: white
	color: black
.add-finish-button
	border-radius: 0
	box-shadow: none
	height: 40px !important
.info-text
	font-size: 12px
	color: grey

.bigIndex
	position: absolute
	top: 0
	right: 0
	font-size: 120px
	font-family: "grotta-semibold"
	line-height: 0.8
	opacity: 0.2

.delete-btn
	position: absolute
	z-index: 10
	top: 0
	right: 10px

.variant-button
	border: solid 2px black
	padding: 5px 20px
	font-family: "grotta-semibold"
	margin: 0
	font-size: 1.2em
	opacity: 0.3
	&:hover
		opacity: 1
	&.danger
		background: rgb(245,245,245)
	&.main
		background: black
		color: white
		opacity: 1
		&:hover
			opacity: 0.8

.new-variant
	margin: 0 0 20px 0
	padding: 0
	.new-variant-btn-wrapper
		position: relative
		z-index: 10
		text-align: center
	.next-btn-wrapper
		position: relative
		top: -50px
		z-index: 10
		text-align: center
.color-selection-container
	.color-selection-wrapper
		// border: solid 2px black
		border-radius: 50%

	.color-selection-item
		box-shadow: 0 1px 2px rgba(0,0,0,0.3) !important
		span
			opacity: 0
			position: absolute
			background: rgb(245,245,245)
			color: black
			top: 20px
			font-size: 10px
			padding: 3px 10px
			font-family: "grotta-semibold"
			text-transform: initial
		&:after
			height: 130%
			width: 130%
			display: inline-flex
			content: ""
			position: absolute
			background-color: rgba(255,255,255,0.1) !important
			background-image: url('~@/assets/icons/select-check.png')
			background-position: top right
			background-size: 10% auto
			opacity: 0
			transition: all 200ms ease-in
		&:hover
			// background: white !important
			span
				opacity: 1
		&:focus, &:active
			// background: white !important
			outline: none

		&.selected
			&:after
				opacity: 1
				background-size: 40% auto
			&:hover
				span
					opacity: 0 !important
.addButton
	background: white !important
	color: black !important
	border: solid 2px black
	font-family: "grotta-semibold"
	box-shadow: none
	&:hover
		background: black !important
		color: white !important
.new-variant-settings
	position: relative
	z-index: 9
	top: -5px
	background: white
	padding-top: 40px
	padding-bottom: 40px
	margin-bottom: 20px
	border: 1px solid #ebebeb
	border-width: 1px 1px 1px 1px
	.close-btn
		position: absolute
		top: 20px
		right: 30px
		letter-spacing: 1px
		cursor: pointer
	.new-variant-row
		position: relative
		padding-bottom: 20px
		.button-wrapper
			display: flex
			flex-direction: row
</style>