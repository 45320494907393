<template>
	<div id="error-banner" v-if="hasError">
		<v-alert class="d-flex inner" color="#F8D6D7" dark icon="mdi-exclamation-thick" border="right">
			<span class="text">{{ errorMessage }}</span>
			<v-btn class="ml-5 btn" @click="close" elevation="0" dark color="#b54e4e">OK</v-btn>
		</v-alert>
	</div>
</template>

<script>
import { mapFields } from 'vuex-map-fields'

export default {
	name: 'ErrorBanner',
	computed: {
		...mapFields('ConfiguratorDisplay', {
			hasError: 'error',
			errorMessage: 'errorMessage',
			errorOptions: 'errorOptions',
		}),
	},
	methods: {
		close() {
			this.$store.dispatch('ConfiguratorDisplay/unsetError')
		},
	},
}
</script>

<style lang="sass">
$text: #B54E4E
#error-banner
	.v-alert__wrapper
		display: flex
		align-items: center
		justify-content: center

	.v-icon
		color: $text !important
		margin-top: 4px
</style>

<style scoped lang="sass">
$background: #F8D6D7
$text: #B54E4E
#error-banner
	position: fixed
	top: 80px
	// right: 20px
	left: calc(50vw - 250px)
	z-index: 1100

	.inner
		border: 2px solid $text !important

	.v-icon
		color: $text !important

	.btn
		color: white

	.text
		color: $text
</style>