<template>
	<v-dialog v-model="dialog" fullscreen hide-overlay transition="dialog-bottom-transition" persistent>
		<!-- <template v-slot:activator="{ on, attrs }">
			<div id="color-selection-modal-button" v-bind="attrs" v-on="on">Farbe Auswählen</div>
		</template> -->
		<v-card id="color-selection-modal">
			<v-toolbar dark color="black" elevation="0" tile>
				<v-btn icon dark @click="closeDialog">
					<v-icon>mdi-close</v-icon>
				</v-btn>
				<v-toolbar-title>Farbe ändern</v-toolbar-title>
				<v-spacer></v-spacer>
				<!-- <v-toolbar-items>
					<v-btn dark text @click="dialog = false"> Save </v-btn>
				</v-toolbar-items> -->
			</v-toolbar>
			<div
				class="pa-4"
				v-if="finishes[finishIdx].technique !== 'stick' || (finishes[finishIdx].technique === 'stick' && stickCategory)"
			>
				<v-text-field label="Suche" hide-details="auto" v-model="searchInput"></v-text-field>
			</div>
			<div v-if="finishes[finishIdx].technique !== 'stick'" class="color-scroll-box">
				<div class="d-flex flex-wrap color-scroll-box align-content-start">
					<div
						v-for="(color, idx) of filteredColors"
						:key="idx"
						class="color-item d-flex justify-center align-center text-center flex-column"
						:style="{ background: color.color }"
						@click="selectColor(color)"
					>
						{{ color.name }}
						<i style="font-weight: 300">{{ color.pantone }}</i>
					</div>
				</div>
			</div>
			<div v-else>
				<div class="select-category d-flex justify-center align-center text-center pt-2" v-if="!stickCategory">
					<v-card elevation="0">
						<v-card-text>
							<h4>Standard</h4>
							<p>Glänzende Garne..</p>
							<v-btn variant="primary" size="sm" @click="selectCategory('standard')">Auswählen</v-btn>
						</v-card-text>
					</v-card>
					<v-card elevation="0">
						<v-card-text>
							<h4>Matt</h4>
							<p>Lorem ipsum..</p>
							<v-btn variant="primary" size="sm" @click="selectCategory('frosted')">Auswählen</v-btn>
						</v-card-text>
					</v-card>
					<!-- <v-card elevation="0">
						<v-card-text>
							<h4>Polyneon</h4>
							<p>Coming soon..</p>
							<v-btn variant="primary" size="sm" disabled @click="selectCategory('polyneon')">Auswählen</v-btn>
						</v-card-text>
					</v-card>
					<v-card elevation="0">
						<v-card-text>
							<h4>Green</h4>
							<p>Coming soon..</p>
							<v-btn variant="primary" size="sm" disabled @click="selectCategory('green')">Auswählen</v-btn>
						</v-card-text>
					</v-card>
					<v-card elevation="0">
						<v-card-text>
							<h4>Sensa</h4>
							<p>Coming soon..</p>
							<v-btn variant="primary" size="sm" disabled @click="selectCategory('sensa')">Auswählen</v-btn>
						</v-card-text>
					</v-card> -->
				</div>
				<div v-if="stickCategory">
					<div class="d-flex align-center mt-2 mb-2 stick-category-header">
						<h4 class="m-0">{{ stickCategoryLabels[stickCategory] }}</h4>
						<v-btn class="back-btn" icon @click="stickCategory = null">
							<!-- <v-icon small>mdi-pencil-outline</v-icon> -->
							<v-icon small>mdi-close</v-icon>
						</v-btn>
					</div>
					<div class="d-flex flex-wrap align-content-start color-scroll-box">
						<div
							v-for="(color, idx) of filteredColors"
							:key="idx"
							class="color-item d-flex justify-center align-center text-center flex-column"
							:style="{ background: color.color }"
							@click="selectColor(color)"
						>
							{{ color.name }}
							<i style="font-weight: 300">{{ color.pantone }}</i>
						</div>
					</div>
				</div>
			</div>
		</v-card>
	</v-dialog>
</template>

<script>
import PantoneColorsArray from '@/assets/data/pantone.js'
import { standard as PantoneStickStandardColorsArray, frosted as PantoneStickFrostedColorsArray } from '@/assets/data/pantone-stick.js'
import { mapFields } from 'vuex-map-fields'

export default {
	name: 'ColorSelectionModal',
	props: {
		color: { type: Object },
		finishIdx: { type: String },
		dialog: { type: Boolean },
		showColorSelectionModalFn: { type: Function },
	},
	data() {
		return {
			// dialog: false,
			pantoneColors: PantoneColorsArray,
			pantoneStickColors: {
				standard: PantoneStickStandardColorsArray,
				frosted: PantoneStickFrostedColorsArray,
			},
			searchInput: '',
			filteredColors: [],
			stickCategory: null,
			stickCategoryLabels: {
				standard: 'Standard-Garne (glänzend)',
				frosted: 'Matt-Garne',
			},
		}
	},
	computed: {
		// ...mapFields('ConfiguratorDisplay', {
		// 	dialog: 'colorSelectionModal',
		// }),
		...mapFields('Configurator', {
			variants: 'variants',
			finishes: 'finishes',
			selectedVariantId: 'selectedVariantId',
		}),
		selectedColor: {
			get() {
				if (this.variants[this.selectedVariantId] && this.variants[this.selectedVariantId].finishes[this.finishIdx]) {
					const colors = this.variants[this.selectedVariantId].finishes[this.finishIdx].colors
					return colors.find((c) => c.hexColor === this.color.hexColor).substituteColor
				} else {
					return '#000000'
				}
			},
			set(value) {
				const pantoneColorArray =
					this.finishes[this.finishIdx].technique === 'stick' ? this.pantoneStickColors[this.stickCategory] : this.pantoneColors
				const pantoneColorObject = pantoneColorArray.find((pc) => pc.pantone === value)
				this.$store.dispatch('Configurator/updateVariant', {
					key: 'finishColor',
					value: {
						substituteColor: value,
						substituteRgbColor: pantoneColorObject.color,
						yarnNumber: pantoneColorObject.productNumber,
						hexColor: this.color.hexColor,
						finishIdx: this.finishIdx,
					},
				})
			},
		},
	},
	mounted() {
		const pantoneColorArray =
			this.finishes[this.finishIdx].technique === 'stick' ? this.pantoneStickColors[this.stickCategory] : this.pantoneColors
		this.filteredColors = pantoneColorArray
	},
	watch: {
		searchInput(val) {
			val = val.replace(/\s*/g, '')
			const pantoneColorArray =
				this.finishes[this.finishIdx].technique === 'stick' ? this.pantoneStickColors[this.stickCategory] : this.pantoneColors
			this.filteredColors = pantoneColorArray.filter(
				(c) => c.name.replace(/\s*/g, '').match(new RegExp(val, 'gi')) || c.pantone.replace(/\s*/g, '').match(new RegExp(val, 'gi'))
			)
		},
	},
	methods: {
		selectColor(color) {
			console.log(color)
			this.selectedColor = color.pantone
			// this.dialog = false
			this.showColorSelectionModalFn(false)
		},
		selectCategory(category) {
			this.stickCategory = category

			const pantoneColorArray =
				this.finishes[this.finishIdx].technique === 'stick' ? this.pantoneStickColors[this.stickCategory] : this.pantoneColors
			this.filteredColors = pantoneColorArray
		},
		closeDialog() {
			this.stickCategory = null
			this.showColorSelectionModalFn(false)
		},
	},
}
</script>

<style lang="sass">
#color-selection-modal-button
	padding: 10px
	font-size: 12px
	cursor: pointer
	font-weight: 700
	display: inline-flex
	justify-content: center
	align-items: center
	border: 1px solid rgb(214, 214, 214)
	border-radius: 4px
	width: 100%
	height: 100%
	.color
		width: 60px
		height: 100%
		border-radius: 4px 0 0 4px
		border-right: 1px solid rgb(214, 214, 214)
#color-selection-modal
	.color-scroll-box
		height: calc(100vh - 66px - 90px)
		overflow-y: auto
	.color-item
		width: calc(100% / 10)
		height: 100px
		cursor: pointer
		font-size: 10px
		font-weight: 600
		letter-spacing: 1px
		position: relative
		z-index: 9
		&:hover
			outline: 4px solid white
			z-index: 10
	.select-category
		flex-wrap: wrap
		.v-card
			flex-basis: 25%
			margin-left: 1.5rem
			margin-top: 1.5rem
			h4
				font-size: 1.4em
				font-weight: 700
				letter-spacing: -0.02em
			p
				margin-top: 10px
				margin-bottom: 20px
				font-size: 11px
	.stick-category-header
		padding-left: 16px
		h4
			font-size: 1.4em
			font-weight: 700
			letter-spacing: -0.02em
		.back-btn
			margin-left: 10px
			.v-icon
				// color: black
</style>