<template>
	<v-dialog v-model="showModal" width="500" transition="dialog-bottom-transition" persistent>
		<v-card id="color-selection-warning-modal">
			<v-toolbar dark color="black" elevation="0" tile class="toolbar">
				<v-toolbar-title>{{ $getDictString('configurator_color_selection_warning_modal_heading') }}</v-toolbar-title>
				<v-spacer></v-spacer>
			</v-toolbar>
			<div class="d-flex flex-wrap color-scroll-box align-content-start pa-5">
				{{ $getDictString('configurator_color_selection_warning_modal_message') }}
			</div>

			<v-divider></v-divider>

			<v-card-actions>
				<!-- <v-btn color="primary" class="weakBtn" text @click="showModal = false">
					{{ $getDictString('configurator_color_selection_warning_modal_cancel_button') }}
				</v-btn> -->
				<v-spacer></v-spacer>
				<v-btn color="primary" class="strongBtn" text @click="openColorSelection">
					{{ $getDictString('configurator_color_selection_warning_modal_accept_button') }}
				</v-btn>
			</v-card-actions>
		</v-card>
	</v-dialog>
</template>

<script>
// import { mapFields } from 'vuex-map-fields'

export default {
	name: 'ColorSelectionWarningModal',
	props: {
		showColorSelectionModalFn: { type: Function },
		showModal: { type: Boolean },
	},
	computed: {
		// ...mapFields('ConfiguratorDisplay', {
		// 	showModal: 'colorSelectionWarningModal',
		// }),
	},
	methods: {
		openColorSelection() {
			// this.showModal = false
			this.$store.dispatch('ConfiguratorDisplay/acceptColorSelectionWarning')
			// this.$store.dispatch('ConfiguratorDisplay/toggleColorSelectionModal')
			this.showColorSelectionModalFn(true)
		},
	},
}
</script>

<style lang="sass">
#color-selection-warning-modal
	font-weight: 700
	.toolbar, .v-toolbar
		border-radius: 4px 4px 0 0 !important
		border: solid 1px transparent
		.v-toolbar__title
			font-size: 2em !important
	.strongBtn
		background: black
		color: white !important
		text-transform: none
		font-weight: 700
		font-size: 1em
		letter-spacing: -0.01em
		padding: 20px 20px
	.weakBtn
		background: white
		color: black !important
		text-transform: none
		font-weight: 700
		font-size: 1em
		letter-spacing: -0.01em
		padding: 20px 20px
		border: solid 2px black
</style>