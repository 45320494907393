<template>
	<div class="wrapper pa-5">
		<v-row class="px-2">
			<div class="close-btn" @click="closeFn"><v-icon medium color="white">mdi-check</v-icon>Speichern</div>
			<v-col cols="6" class="text-left">
				<SectionLabel :label="$getDictString('configurator_variants_heading_finish')" class="pb-1" />
			</v-col>
			<v-col class="text-left" cols="6">
				<SectionLabel :label="$getDictString('configurator_variants_heading_color_select')" class="pb-1" />
			</v-col>

			<v-col class="pt-0 text-left motive-preview" cols="4">
				<!-- <BoxButton label="" :image="getFile(finish)" class="white-background" /> -->
				<img :src="$getFileUrlById(finish.fileName)" />
			</v-col>
			<v-col cols="2"> </v-col>
			<v-col class="pt-0 text-right" cols="6">
				<div class="d-flex items-start flex-column">
					<TextButton
						mini
						v-for="(option, idx) in filteredColorOptions"
						class="option-selector"
						:key="idx"
						:label="option.label"
						:active="selectedVariantFinishes[finishIdx] && selectedVariantFinishes[finishIdx].type === option.value"
						@click.native="updateVariantFinish(finishIdx, option.value)"
					/>
				</div>
			</v-col>
		</v-row>
		<div class="py-1 text-center">
			<InfoText
				:text="$getDictString('configurator_variants_text_finish_color_single')"
				v-if="selectedVariantFinishes[finishIdx].type === 'single'"
			/>
			<InfoText
				:text="$getDictString('configurator_variants_text_finish_color_multiple')"
				v-if="selectedVariantFinishes[finishIdx].type === 'multiple'"
			/>
			<InfoText
				:text="$getDictString('configurator_variants_text_finish_color_recommendet')"
				v-if="selectedVariantFinishes[finishIdx].type === 'recommendation'"
			/>
		</div>
		<v-row class="px-2 py-2">
			<v-col class="mb-2 p-0" cols="12">
				<div v-if="selectedVariantFinishes[finishIdx].type !== 'recommendation'">
					<ColorSelection
						v-for="(color, idx) in selectedVariantFinishes[finishIdx].colors"
						:key="idx"
						:color="color"
						:finishIdx="finishIdx.toString()"
					/>
				</div>
				<!-- <div v-if="selectedVariantFinishes[finishIdx].type !== 'recommendation'"> -->
				<div>
					<div class="mt-4" style="font-style: italic; font-weight: bold" v-if="getComment">"{{ getComment }}"</div>
					<v-dialog content-class="comment-dialog" v-model="commentDialog" width="500">
						<template v-slot:activator="{ on, attrs }">
							<v-btn
								class="comment-btn mt-3"
								small
								elevation="0"
								tile
								v-bind="attrs"
								v-on="on"
								v-html="
									getComment
										? $getDictString('configurator_variants_comment_button_alt')
										: $getDictString('configurator_variants_comment_button')
								"
							></v-btn>
						</template>

						<v-card tile class="content">
							<v-card-title class="text-h5 black text-white" color="white"> Kommentar </v-card-title>

							<v-card-text>
								<v-textarea
									v-model="selectedVariantFinishes[finishIdx].comment"
									outlined
									class="mt-5"
									hide-details
									height="2"
									label="Kommentar"
									v-on:keyup="updateVariantFinishComment(finishIdx)"
								>
								</v-textarea>
							</v-card-text>

							<v-divider></v-divider>

							<v-card-actions>
								<v-spacer></v-spacer>
								<v-btn color="primary" text @click="commentDialog = false"> Ok </v-btn>
							</v-card-actions>
						</v-card>
					</v-dialog>
				</div>
			</v-col>
		</v-row>
	</div>
</template>

<script>
import { mapFields } from 'vuex-map-fields'

import ColorSelection from '../ColorSelection'
import InfoText from '../../components/InfoText'
import TextButton from '../../components/TextButton'
// import BoxButton from '../../components/BoxButton'
import SectionLabel from '../../components/SectionLabel'

export default {
	name: 'EditFinish',
	components: { ColorSelection, InfoText, TextButton, SectionLabel },
	props: {
		finish: { type: Object, required: true },
		finishIdx: { type: Number, required: true },
		selectedVariantId: { type: Number, required: true },
		productFinishes: { type: Object, required: true },
		closeFn: { type: Function, required: true },
	},
	data() {
		return {
			commentDialog: false,
			showColorSelectionModal: false,
			colorSelectWarningDialog: false,
			colorOptions: [
				{ value: 'single', label: 'Einfarbig' },
				{ value: 'multiple', label: 'Mehrfarbig' },
				{ value: 'recommendation', label: 'Empfehlung' },
			],
		}
	},
	computed: {
		...mapFields('Configurator', {
			variants: 'variants',
		}),
		filteredColorOptions() {
			// stick only recommendation @INFO: not used anymore
			// if (this.finish.technique === 'stick') {
			// 	return this.colorOptions.filter((opt) => opt.value === 'recommendation')
			// } else {
			return this.colorOptions
			// }
		},
		selectedVariantFinishes: {
			get() {
				return this.variants[this.selectedVariantId] && this.variants[this.selectedVariantId].finishes
			},
			set(value) {
				console.log(value)
				this.$store.dispatch('Configurator/updateVariant', { key: 'finishes', value })
			},
		},
		getComment() {
			if (
				this.selectedVariantFinishes &&
				this.selectedVariantFinishes[this.finishIdx] &&
				this.selectedVariantFinishes[this.finishIdx].comment &&
				this.selectedVariantFinishes[this.finishIdx].comment.length > 0
			)
				return this.selectedVariantFinishes[this.finishIdx].comment
			else return null
		},
	},
	methods: {
		updateVariantFinish(finishIdx, value) {
			if (value === 'multiple') {
				const imgUrl = this.$getFileUrlById(this.productFinishes[finishIdx].fileName)
				const that = this

				if (imgUrl.match(/dummy/gi)) {
					this.$store.dispatch('Configurator/addVariantFinishColors', {
						finishIdx,
						variantIdx: this.selectedVariantId,
						colors: [{ hex: '#000000' }, { hex: '#ffffff' }],
					})
				} else {
					this.$store.dispatch('ConfiguratorFiles/extractColors', imgUrl).then(({ colors }) => {
						that.$store.dispatch('Configurator/addVariantFinishColors', {
							finishIdx,
							variantIdx: that.selectedVariantId,
							colors: colors,
						})
					})
				}
			} else if (value === 'single') {
				this.$store.dispatch('Configurator/addVariantFinishColors', {
					finishIdx,
					variantIdx: this.selectedVariantId,
					colors: [{ hex: '#000000' }],
				})
			} else if (value === 'recommendation') {
				this.$store.dispatch('Configurator/addVariantFinishColors', {
					finishIdx,
					variantIdx: this.selectedVariantId,
					colors: [],
				})
			}

			this.$store.dispatch('Configurator/updateVariantFinishes', {
				variantIdx: this.selectedVariantId,
				finishIdx: finishIdx,
				type: value,
			})
		},
		updateVariantFinishComment(finishIdx) {
			const comment = this.selectedVariantFinishes[finishIdx].comment
			if (comment && comment.replace(/\s/g, '').length > 0) {
				this.$store.dispatch('Configurator/updateVariantFinishes', {
					variantIdx: this.selectedVariantId,
					finishIdx: finishIdx,
					comment: comment,
				})
			}
		},
	},
}
</script>



<style scoped lang="sass">
.wrapper
	position: relative
	text-align: center
	.comment-btn
		width: 100%
		background: white !important
		font-weight: 700
		margin: 10px 0
	.close-btn
		position: absolute
		bottom: -20px
		right: 32.5%
		background: black
		font-weight: 700
		font-size: 1.1em
		color: white
		padding: 6px 6px
		width: 35%
		text-align: center
		cursor: pointer
		z-index: 100
		border: solid 2px black
		transition: all 150ms
		i
			padding-right: 5px
		&:hover
			background: green
			border-color: green
	.motive-preview
		img
			object-fit: contain
			height: 100%
			width: 100%
	.option-selector
		padding: 0 0 7px 0
</style>

<style lang="sass">
.comment-dialog
	border-radius: 0 !important
	.content
		border: none !important
</style>