<template>
	<div class="ani-container">
		<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 124.187 143.566">
			<g id="LINES" transform="translate(-232.214 -469.016)">
				<path
					id="Path_7"
					data-name="Path 7"
					d="M1037.61,262.037a145.822,145.822,0,0,1,17.529-1.056c9.717,0,15.652.222,18.118.722"
					transform="translate(-761 211)"
					fill="none"
					stroke="#5a5b5e"
					stroke-width="0.115"
					stroke-dasharray="0.92 0.46"
				/>
				<path
					id="Path_8"
					data-name="Path 8"
					d="M994.465,306.524a68.181,68.181,0,0,1,8.584,3.842c2.652,1.633,12.61,5.866,13.673,4.381"
					transform="translate(-761 211)"
					fill="none"
					stroke="#5a5b5e"
					stroke-width="0.115"
					stroke-dasharray="0.92 0.46"
				/>
				<path
					id="Path_9"
					data-name="Path 9"
					d="M994.807,305.933a65.055,65.055,0,0,1,8.322,3.843c2.573,1.633,12.234,5.867,13.266,4.381"
					transform="translate(-761 211)"
					fill="none"
					stroke="#5a5b5e"
					stroke-width="0.115"
					stroke-dasharray="0.92 0.46"
				/>
				<path
					id="Path_11"
					data-name="Path 11"
					d="M1102.656,270.839a25.068,25.068,0,0,0-7.777-3.174c-4.692-1.169-6.265-2.283-6.265-2.283s-9.022-3.667-11.586-4.367"
					transform="translate(-761 211)"
					fill="none"
					stroke="#5a5b5e"
					stroke-width="0.115"
					stroke-dasharray="0.92 0.46"
				/>
				<path
					id="Path_12"
					data-name="Path 12"
					d="M1102.633,271.183a9.349,9.349,0,0,1,2.7,2.807c.986,1.708,1.543,4.677,2.544,7.572s3.239,9.726,4.086,12.1,2.475,8.092,3.471,10.615,1.683,4.009,1.683,4.009a1.184,1.184,0,0,1-.352,1.485c-.81.594-3.914,1.707-5.465,2.45s-6.037,4.008-8.625,4.825-8.385,3.3-9.215.72"
					transform="translate(-761 211)"
					fill="none"
					stroke="#464749"
					stroke-width="0.233"
				/>
				<path
					id="Path_13"
					data-name="Path 13"
					d="M1116.32,306.458a70.433,70.433,0,0,0-8.754,3.908c-2.653,1.633-12.609,5.866-13.673,4.381"
					transform="translate(-761 211)"
					fill="none"
					stroke="#5a5b5e"
					stroke-width="0.115"
					stroke-dasharray="0.92 0.46"
				/>
				<path
					id="Path_14"
					data-name="Path 14"
					d="M1115.978,305.867a67.281,67.281,0,0,0-8.493,3.909c-2.572,1.633-12.234,5.867-13.266,4.381"
					transform="translate(-761 211)"
					fill="none"
					stroke="#5a5b5e"
					stroke-width="0.115"
					stroke-dasharray="0.92 0.46"
				/>
				<path
					id="Path_15"
					data-name="Path 15"
					d="M1034,261.032c-2.564.7-11.587,4.366-11.587,4.366s-1.572,1.114-6.263,2.284a25.055,25.055,0,0,0-7.779,3.172c-.841.5-.243,1.559.345,3.564s2.9,13.029,2.9,13.029c.943,4.787,4.278,23.218,4.278,23.218s.332,1.744.683,3.883c.208,1.27.156.7.309,1.968l.035.286a9.454,9.454,0,0,1,.231,2.446c.007.975.166,2.172.164,3.651,0,4.133.114,9.143.117,9.423s-.114,8.852.278,13.418.166,6.4-.335,7.627-1.123,4.9-1.76,7.461-.994,9.521-.078,11.525a11.854,11.854,0,0,1,.709,5.679,33.846,33.846,0,0,0-.54,3.564,25.625,25.625,0,0,1-.68,3.452,14.755,14.755,0,0,0-.319,4.788,5.2,5.2,0,0,1-.031,2.394c-.169.446-.707,1.949.361,2.728a23.145,23.145,0,0,0,14.242,3.564,52.253,52.253,0,0,0,14.107-2.84c1.942-.556,7.731-.668,11.855-.334s9.473.668,14.284,2.505,12.855,3.954,15.745,3.508,6.2-2.673,6.8-3.674.7-1.782.866-1.949a38.938,38.938,0,0,0-.081-6.57c-.2-2.895-.618-4.9-.747-6.292a22.342,22.342,0,0,0-.721-4.342c-.407-1.281-1.664-3.953-1.243-5.959s1.174-4.008,1.227-4.286a33.92,33.92,0,0,0,1.176-8.464c-.053-4.175-.275-22.048-.891-26.836a6.853,6.853,0,0,1,.31-1.948,92.888,92.888,0,0,0,1.257-10.857c.13-3.007,1.488-10.245,1.488-10.245s3.334-18.43,4.277-23.217c0,0,2.312-11.024,2.9-13.029s1.186-3.062.345-3.564a25.068,25.068,0,0,0-7.779-3.172c-4.692-1.17-6.264-2.284-6.264-2.284s-9.023-3.667-11.587-4.366"
					fill="none"
					transform="translate(-761 211)"
					stroke="#464749"
					stroke-width="0.233"
				/>
				<path
					id="Path_16"
					data-name="Path 16"
					d="M1007.958,271.563a25.07,25.07,0,0,1,7.778-3.172c4.691-1.17,6.263-2.284,6.263-2.284s9.023-3.668,11.587-4.367"
					transform="translate(-761 211)"
					fill="none"
					stroke="#5a5b5e"
					stroke-width="0.115"
					stroke-dasharray="0.92 0.46"
				/>
				<path
					id="Path_17"
					data-name="Path 17"
					d="M1007.981,271.907a9.386,9.386,0,0,0-2.7,2.808c-.985,1.708-1.542,4.678-2.543,7.572s-3.239,9.726-4.086,12.1-2.476,8.091-3.472,10.615-1.683,4.009-1.683,4.009a1.184,1.184,0,0,0,.353,1.485c.809.594,3.913,1.707,5.463,2.45s6.037,4.008,8.626,4.825,8.387,3.295,9.215.72"
					transform="translate(-761 211)"
					fill="none"
					stroke="#464749"
					stroke-width="0.233"
				/>
				<path
					id="Path_18"
					data-name="Path 18"
					d="M1014.811,390.846s4.478,2.443,8.044,2.665a38.468,38.468,0,0,0,8.976-.446c1.7-.37,12.147-2.361,15.489-2.219s12.118,1.4,12.785,1.367,10.257.932,11.227,1.374,11.7,3.784,14.153,3.858,7.076-2.529,7.54-4.754"
					transform="translate(-761 211)"
					fill="none"
					stroke="#5a5b5e"
					stroke-width="0.115"
					stroke-dasharray="0.92 0.46"
				/>
				<path
					id="Path_19"
					data-name="Path 19"
					d="M1014.8,390.232s4.478,2.443,8.044,2.666a38.49,38.49,0,0,0,8.976-.445c1.7-.372,12.145-2.362,15.489-2.221s12.117,1.406,12.786,1.369,10.255.931,11.226,1.374,11.7,3.783,14.153,3.858,7.076-2.529,7.541-4.754"
					transform="translate(-761 211)"
					fill="none"
					stroke="#5a5b5e"
					stroke-width="0.115"
					stroke-dasharray="0.92 0.46"
				/>
				<path id="Path_20" data-name="Path 20" d="M1091.9,380.919" transform="translate(-761 211)" fill="#1a1818" />
				<path
					id="Path_21"
					data-name="Path 21"
					d="M1072.842,262.042a7.584,7.584,0,0,0,.651-1.322s.642-1.785-.931-2.359c-2.828-.217-10.167-.241-13.866-.2-4.448.042-14.891,0-17.6.3-1.072.117-1.837.163-2.413.195-.15.072-2.05,1.016-1.552,2.271a7.653,7.653,0,0,0,.894,1.457"
					transform="translate(-761 211)"
					fill="none"
					stroke="#464749"
					stroke-width="0.233"
				/>
				<path
					id="Path_22"
					data-name="Path 22"
					d="M1033.569,262.714c4.345,8.361,15.094,9.806,15.094,9.806s9.183,1.788,17.142-.864c5.492-1.828,9.629-4.96,11.275-9.213a1.969,1.969,0,0,0-.038-1.976c-1.23-.7-2.79-1.683-3.381-1.95a4.783,4.783,0,0,0-1.1-.156c1.573.574.931,2.359.931,2.359-1.484,4.037-6.622,6.4-9.285,7.344-5.251,1.863-11.372,1.9-17.746.1a15.392,15.392,0,0,1-9.326-7.243c-.5-1.255,1.4-2.2,1.552-2.271a4.014,4.014,0,0,0-1.69.274c-.547.3-2.2,1.483-3.182,1.97A1.542,1.542,0,0,0,1033.569,262.714Z"
					transform="translate(-761 211)"
					fill="none"
					stroke="#464749"
					stroke-width="0.233"
				/>
				<path
					id="Path_23"
					data-name="Path 23"
					d="M1037.685,261.733a145.315,145.315,0,0,1,17.472-1.053c9.685,0,15.6.221,18.059.72l-.8,1.164s-4.032-.58-17.3-.58a130.277,130.277,0,0,0-16.456,1.1Z"
					transform="translate(-761 211)"
					fill="none"
					stroke="#464749"
					stroke-width="0.233"
				/>
				<path
					id="Path_24"
					data-name="Path 24"
					d="M1034.255,260.668a1.515,1.515,0,0,0-.243,1.785c4.255,8.188,14.784,9.6,14.784,9.6s8.992,1.75,16.788-.848c5.377-1.791,9.43-4.856,11.044-9.022a1.931,1.931,0,0,0-.039-1.935"
					transform="translate(-761 211)"
					fill="none"
					stroke="#5a5b5e"
					stroke-width="0.115"
					stroke-dasharray="0.92 0.46"
				/>
				<path
					id="Path_25"
					data-name="Path 25"
					d="M1033.4,261.128a1.578,1.578,0,0,0-.254,1.859c4.432,8.526,15.4,10,15.4,10s9.365,1.823,17.483-.882c5.6-1.865,9.818-5.058,11.5-9.4a2,2,0,0,0-.04-2.015"
					transform="translate(-761 211)"
					fill="none"
					stroke="#5a5b5e"
					stroke-width="0.115"
					stroke-dasharray="0.92 0.46"
				/>
				<path
					id="Path_26"
					data-name="Path 26"
					d="M1072.444,262.272s-4.044-.582-17.361-.582a130.8,130.8,0,0,0-16.512,1.1"
					transform="translate(-761 211)"
					fill="none"
					stroke="#5a5b5e"
					stroke-width="0.115"
					stroke-dasharray="0.92 0.46"
				/>
				<path
					id="Path_28"
					data-name="Path 28"
					d="M1054.891,265.351a.261.261,0,0,0,.108.024c.051,0,.08-.021.08-.052s-.023-.048-.08-.065c-.074-.024-.12-.058-.121-.114s.059-.114.155-.114a.267.267,0,0,1,.107.021l-.019.057a.222.222,0,0,0-.089-.018c-.05,0-.072.024-.072.047s.026.044.086.064c.077.026.114.059.114.116s-.052.117-.165.117a.3.3,0,0,1-.122-.024Z"
					transform="translate(-761 211)"
				/>
				<path
					id="Path_29"
					data-name="Path 29"
					d="M1055.309,265.038l.005.39h-.08l-.005-.39Z"
					transform="translate(-761 211)"
					fill="#c7c9cb"
				/>
				<path
					id="Path_30"
					data-name="Path 30"
					d="M1055.376,265.388l.222-.29h-.206v-.059h.312v.042l-.22.287v0h.227v.059h-.335Z"
					transform="translate(-761 211)"
					fill="#c7c9cb"
				/>
				<path id="Path_33" data-name="Path 33" d="M1073.227,261.34" transform="translate(-761 211)" fill="#1a1818" />
			</g>
		</svg>
	</div>
</template>


<script>
import { gsap } from 'gsap'
import { DrawSVGPlugin } from 'gsap/DrawSVGPlugin'

gsap.registerPlugin(DrawSVGPlugin)
export default {
	name: 'ShirtAnimation',
	props: { type: String },
	methods: {},
	mounted() {
		gsap.from('svg path', { duration: 1, stagger: 0.01, drawSVG: 0 })
	},
}
</script>

<style lang="sass" scoped>
.ani-container
	max-height: 100%
	// width: 65%
	width: 100%
	height: 100%
	padding: 20px 10px
	display: flex
	justify-content: center
	align-items: center
	svg
		transform-origin: 50% 50%
		// height: 100%
		width: 65%
		path
			stroke-width: 0.4px
			stroke-dasharray: 5px
</style>