<template>
	<v-container class="variant py-0">
		<div class="big-index" v-if="canDelete">{{ index + 1 }}</div>
		<v-row class="">
			<!-- <v-col class="pa-2 d-flex justify-center items-center" cols="1">
				<span style="font-size: 40px; font-weight: 700">{{ index + 1 }}</span>
			</v-col> -->
			<v-col class="pl-0" cols="4">
				<div class="textile-color-box">
					<div class="overlay-hover" v-if="editFinishId !== index && !editTextileState && editFinishId == -1">
						<span>{{ dictionary['configurator_variants_edit_textile'] }}</span>
					</div>
					<div class="overlay-inactive" v-if="editFinishId !== -1"></div>
					<DisplayTextile
						v-if="!editTextileState"
						:shopName="shopName"
						:variant="variants[index]"
						:index="index"
						:selectedProduct="selectedProduct"
						@click.native="editTextile"
					/>
					<EditTextile
						v-if="editTextileState"
						:selectedVariantId="index"
						:selectedProduct="selectedProduct"
						:closeFn="closeEditTextile"
					/>
				</div>
			</v-col>
			<v-col class="pr-0 d-flex flex-column" cols="8">
				<div class="pa-0 fill-height d-flex items-start flex-column justify-around">
					<v-row class="ma-0 mb-2 pa-0 finish-row" v-for="(finish, finishIdx) in finishes" :key="finishIdx" no-gutters>
						<div class="overlay-hover" v-if="editFinishId !== finishIdx && editFinishId == -1 && !editTextileState">
							<span>{{ dictionary['configurator_variants_edit_finish_color'] }}</span>
						</div>
						<div class="overlay-inactive" v-if="(editFinishId !== finishIdx && editFinishId !== -1) || editTextileState"></div>
						<v-col class="finish-row-inner d-flex justify-space-evenly items-center" cols="12">
							<DisplayFinish
								v-if="editFinishId !== finishIdx"
								:finish="finish"
								:finishIdx="parseInt(finishIdx)"
								:variantIdx="index"
								@click.native="editFinish(finishIdx)"
							/>
							<EditFinish
								v-if="editFinishId === finishIdx"
								:finish="finish"
								:finishIdx="parseInt(finishIdx)"
								:selectedVariantId="index"
								:productFinishes="finishes"
								:closeFn="closeEditFinish"
							/>
						</v-col>
						<div class="button-spacer" v-if="editFinishId == finishIdx"></div>
					</v-row>
				</div>
			</v-col>

			<v-btn class="delete" icon color="pink" @click="deleteVariant" v-if="canDelete">
				<v-icon> mdi-delete </v-icon>
			</v-btn>
		</v-row>
	</v-container>
</template>

<script>
import EditFinish from './EditFinish'
import DisplayFinish from './DisplayFinish'
import EditTextile from './EditTextile'
import DisplayTextile from './DisplayTextile'

import { mapFields } from 'vuex-map-fields'

export default {
	name: 'VariantItem',
	components: { EditFinish, DisplayFinish, DisplayTextile, EditTextile },
	props: {
		index: { type: Number, required: true },
		shopName: { type: String, required: true },
		variant: { type: Object, required: true },
		finishes: { type: Object, required: true },
		selectedProduct: { type: Object, required: true },
		canDelete: { type: Boolean, required: true },
	},
	data() {
		return {
			editFinishId: -1,
			editTextileState: false,
		}
	},
	computed: {
		...mapFields('Dictionary', {
			dictionary: 'readableDictionary',
		}),
		...mapFields('Configurator', {
			selectedVariantId: 'selectedVariantId',
			variants: 'variants',
		}),
	},
	watch: {
		selectedVariantId(newId) {
			if (newId !== this.index) {
				this.editFinishId = -1
				this.editTextileState = false
			}
		},
	},
	methods: {
		editFinish(finishIdx) {
			this.editFinishId = finishIdx
			this.editTextileState = false
			this.$store.dispatch('Configurator/selectVariant', this.index)
		},
		closeEditFinish() {
			this.editFinishId = -1
			this.$store.dispatch('Configurator/selectVariant', -1)
		},
		editTextile() {
			this.editTextileState = true
			this.editFinishId = -1
			this.$store.dispatch('Configurator/selectVariant', this.index)
		},
		closeEditTextile() {
			this.editTextileState = false
			this.$store.dispatch('Configurator/selectVariant', -1)
		},
		deleteVariant() {
			this.$store.dispatch('Configurator/deleteVariant', this.index)
		},
	},
}
</script>

<style scoped lang="sass">
.variant
	border-radius: 4px
	position: relative
	margin: 10px 0
	position: relative
	.big-index
		position: absolute
		top: 0px
		left: 12px
		font-weight: 700
		color: #f0f0f0
		font-size: 40px
	.delete
		position: absolute
		right: -8px
		top: -5px
		background: white
		border: 1px solid rgb(235, 235, 235)
		opacity: 1
		z-index: 20
		i
			opacity: 0.4
		&:hover
			opacity: 1
			i
				opacity: 1
	.finish-row
		background: transparent
		width: 100%
		position: relative
		.finish-row-inner
			border-radius: 4px
			background: white
			border: 1px solid #ebebeb
		.overlay-hover
			position: absolute
			background: rgba(255,255,255,0.95)
			text-transform: uppercase
			top: 0
			left: 0
			width: 100%
			height: 100%
			pointer-events: none
			opacity: 0
			transition: all 200ms
			z-index: 11
			font-weight: 700
			font-size: 10px
			display: flex
			justify-content: center
			align-items: center
			letter-spacing: 0.02em
			border: 1px solid #ebebeb
			span
				background: black
				color: white
				padding: 8px 12px
				border-radius: 8px
		.overlay-inactive
			position: absolute
			background: rgba(255,255,255,0.95)
			text-transform: uppercase
			top: 0
			left: 0
			width: 100%
			height: 100%
			transition: all 200ms
			z-index: 12
			pointer-events: none
			font-weight: 700
			display: flex
			justify-content: center
			align-items: center
			letter-spacing: 0.1em
			border: 1px solid #ebebeb
		&:hover
			.overlay-hover
				opacity: 1
		&:last-of-type
			margin-bottom: 0 !important

	.textile-color-box
		background: white
		border: 1px solid #ebebeb
		height: 100%
		border-right: 1px solid #ebebeb
		position: relative
		border-radius: 4px
		.overlay-hover
			position: absolute
			background: rgba(255,255,255,0.95)
			text-transform: uppercase
			top: 0
			left: 0
			width: 100%
			height: 100%
			pointer-events: none
			opacity: 0
			transition: all 200ms
			z-index: 11
			font-weight: 700
			font-size: 10px
			display: flex
			justify-content: center
			align-items: center
			letter-spacing: 0.02em
			span
				background: black
				color: white
				padding: 8px 12px
				border-radius: 8px
		.overlay-inactive
			position: absolute
			background: rgba(255,255,255,0.95)
			text-transform: uppercase
			top: 0
			left: 0
			width: 100%
			height: 100%
			transition: all 200ms
			z-index: 12
			font-weight: 700
			display: flex
			justify-content: center
			align-items: center
			letter-spacing: 0.1em
		&:hover
			.overlay-hover
				opacity: 1
				pointer-events: inital
	.button-spacer
		height: 20px
		width: 100%
</style>