<template>
	<div class="standard-amounts">
		<v-dialog v-model="dialog" persistent max-width="300px" style="zindex: 99999">
			<template v-slot:activator="{ on, attrs }">
				<v-btn text v-bind="attrs" v-on="on" class="tracking-normal capitalize"><strong>Standardverteilung</strong></v-btn>
			</template>
			<v-card>
				<v-card-title>
					<span class="headline">Standardverteilung</span>
				</v-card-title>
				<v-card-text>
					<v-container>
						<v-row>
							<v-col cols="12">
								<v-text-field autofocus label="Gesamtmenge" hide-details="auto" v-model="totalAmount"></v-text-field>
							</v-col>
						</v-row>
					</v-container>
				</v-card-text>
				<v-card-actions>
					<v-spacer></v-spacer>
					<v-btn color="blue darken-1" text @click="dialog = false"> Abbrechen </v-btn>
					<v-btn color="blue darken-1" text @click="setStandardAmounts()"> Anwenden </v-btn>
				</v-card-actions>
			</v-card>
		</v-dialog>
	</div>
</template>

<script>
import { mapFields } from 'vuex-map-fields'

export default {
	name: 'StandardAmount',
	props: {
		variantIdx: String,
		defaultAmount: Number,
		variant: Object,
	},
	data() {
		return {
			totalAmount: 100,
			dialog: false,
		}
	},
	computed: {
		...mapFields('Configurator', {
			productSizes: 'product.sizes',
			selectedProduct: 'product',
		}),
	},
	created() {
		if (this.defaultAmount) this.totalAmount = this.defaultAmount
	},
	methods: {
		isOneSize(sizes) {
			return (sizes && sizes[0].value === 'One Size') || sizes[0].value === 'One'
		},
		setStandardAmounts() {
			const color = this.selectedProduct.colors.find((c) => c.hexValue === this.variant.hexColor)

			if (this.isOneSize(color.activeSizes)) {
				this.$store.dispatch('Configurator/updateSelectedSizes', { sizes: ['One Size'], variant: this.variant })
				this.$store.dispatch('Configurator/updateSizeAmount', {
					newAmount: this.totalAmount,
					size: 'One Size',
					variantIdx: this.variantIdx,
				})
			} else {
				const standardAmounts = [
					{ size: 'S', percentage: 14 },
					{ size: 'M', percentage: 30 },
					{ size: 'L', percentage: 34 },
					{ size: 'XL', percentage: 18 },
					{ size: 'XXL', percentage: 4 },
				]

				const filteredSizes = color.activeSizes.filter((vs) => {
					const amount = standardAmounts.find((sa) => sa.size === vs.value)
					if (amount) {
						vs.amount = Math.floor(this.totalAmount * (amount.percentage / 100))
						return vs
					}
				})

				const sizes = filteredSizes.map((fs) => fs.value)
				this.$store.dispatch('Configurator/updateSelectedSizes', { sizes, variant: this.variant })

				// check if calculated amounts are the same as total amount, if not add diff to size "M"
				const totalCalculatedAmounts = filteredSizes.reduce((acc, value) => acc + value.amount, 0)
				if (totalCalculatedAmounts < this.totalAmount) {
					const diff = this.totalAmount - totalCalculatedAmounts
					filteredSizes.forEach((fs) => {
						if (fs.value === 'M') fs.amount += diff
					})
				}

				filteredSizes.forEach((fs) => {
					this.$store.dispatch('Configurator/updateSizeAmount', {
						newAmount: fs.amount,
						size: fs.value,
						variantIdx: this.variantIdx,
					})
				})
			}

			this.dialog = false
		},
	},
}
</script>