import { render, staticRenderFns } from "./InfoBlockTrigger.vue?vue&type=template&id=0850f4c5&scoped=true&"
import script from "./InfoBlockTrigger.vue?vue&type=script&lang=js&"
export * from "./InfoBlockTrigger.vue?vue&type=script&lang=js&"
import style0 from "./InfoBlockTrigger.vue?vue&type=style&index=0&id=0850f4c5&lang=sass&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "0850f4c5",
  null
  
)

export default component.exports