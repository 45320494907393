<template>
	<div class="ani-container">
		<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 82.412 66.69">
			<g id="Group_126" data-name="Group 126" transform="translate(-927.091 -293.349)">
				<path
					id="Path_5164"
					data-name="Path 5164"
					d="M974.009,293.617s-18.788-.825-23.59,2.6-6.582,6.985-6.582,11.172.273,16.806-.96,22.977c0,0,3.017,7.132,17.965,9.6s33.875.821,37.03,0,3.291-.411,5.211-1.783,6.311-5.35,6.311-5.35-.551-9.872-1.1-17.415c0,0,.137-9.053-4.662-13.578S987.586,294.577,974.009,293.617Z"
					fill="none"
					stroke="#48484a"
					stroke-width="0.214"
				/>
				<path
					id="Path_5165"
					data-name="Path 5165"
					d="M975.1,293.617S962.8,299.24,962.8,307.262v32.71"
					fill="none"
					stroke="#48484a"
					stroke-width="0.104"
					stroke-dasharray="0.832 0.416"
				/>
				<path
					id="Path_5166"
					data-name="Path 5166"
					d="M978.159,294.029s14.878,3.461,18.067,12.205c0,0,.924,1.133,1.338,15.122s0,18.616,0,18.616"
					fill="none"
					stroke="#48484a"
					stroke-width="0.214"
				/>
				<path
					id="Path_5167"
					data-name="Path 5167"
					d="M978.159,294.029s16.56,3.461,19.749,12.205c0,0,.925,1.133,1.336,15.122s0,18.616,0,18.616"
					fill="none"
					stroke="#48484a"
					stroke-width="0.104"
					stroke-dasharray="0.832 0.416"
				/>
				<path
					id="Path_5168"
					data-name="Path 5168"
					d="M978.159,294.029s13.5,3.461,16.691,12.205c0,0,.928,1.133,1.34,15.122s-.034,18.928-.034,18.928"
					fill="none"
					stroke="#48484a"
					stroke-width="0.104"
					stroke-dasharray="0.832 0.416"
				/>
				<path
					id="Path_5169"
					data-name="Path 5169"
					d="M943.1,329.076s3.547,6.985,18.257,9.351a120.236,120.236,0,0,0,36.291,0s4.133.515,11.746-6.891"
					fill="none"
					stroke="#48484a"
					stroke-width="0.104"
					stroke-dasharray="0.832 0.416"
				/>
				<path
					id="Path_5170"
					data-name="Path 5170"
					d="M943.658,331.626c-4.618,2.923-11.916,8.728-12.768,11.706a5.384,5.384,0,0,0-.068,2.819,3.362,3.362,0,0,0,1.361,1.607c.117.073,10.736,5.833,30.884,8.252a117.241,117.241,0,0,0,13.793.943c10.66,0,17.235-2.249,20.719-6.923,4.646-6.234,3.313-10.8,3.3-10.845"
					fill="none"
					stroke="#48484a"
					stroke-width="0.106"
					stroke-dasharray="0.846 0.423"
				/>
				<path
					id="Path_5171"
					data-name="Path 5171"
					d="M945.387,333.509c-4.2,2.829-10.894,7.158-11.669,10.043-.317,1.18,0,1.28.038,1.5a5.106,5.106,0,0,0,1.289,1.477c.106.07,10.052,5.117,28.373,7.462a99.709,99.709,0,0,0,12.541.916c9.694,0,15.672-2.18,18.841-6.707a12.9,12.9,0,0,0,2.6-8.136"
					fill="none"
					stroke="#48484a"
					stroke-width="0.099"
					stroke-dasharray="0.794 0.397"
				/>
				<path
					id="Path_5172"
					data-name="Path 5172"
					d="M946.905,334.742c-3.691,2.528-7.87,5.392-8.486,7.8a4.3,4.3,0,0,0-.138,1.742,2.188,2.188,0,0,0,.825.932c.1.073,5.486,4.292,22.946,6.638a91.212,91.212,0,0,0,11.957.913c9.239,0,14.937-2.178,17.957-6.705a7.385,7.385,0,0,0,1.42-5.448"
					fill="none"
					stroke="#48484a"
					stroke-width="0.097"
					stroke-dasharray="0.775 0.387"
				/>
				<path
					id="Path_2142"
					data-name="Path 2142"
					d="M980.73,360.039a186.431,186.431,0,0,1-20.974-1.722,135.756,135.756,0,0,1-13.368-2.8c-1.276-.306-2.591-.593-3.865-.87-7.168-1.558-13.749-3.1-15.291-8.2-.531-1.763.484-3.6,2.285-5.669,4.313-4.964,12.854-10.111,13.36-10.406l.1.179c-.172.1-17.256,10.164-15.54,15.844,1.471,4.869,8.125,6.316,15.171,7.847,1.273.276,2.593.564,3.873.87a133.142,133.142,0,0,0,13.337,2.8c4.084.637,19.323,2.3,24.857,1.509l.276-.042c6.034-.855,18.591-2.638,17.922-21.179l.413-.013c.681,18.909-12.6,20.8-18.277,21.6l-.278.039A29.777,29.777,0,0,1,980.73,360.039Z"
					fill="none"
					stroke="#48484a"
					stroke-width="0.017"
					stroke-dasharray="0.775 0.387"
				/>
			</g>
		</svg>
	</div>
</template>

<script>
import { gsap } from 'gsap'
import { DrawSVGPlugin } from 'gsap/DrawSVGPlugin'

gsap.registerPlugin(DrawSVGPlugin)
export default {
	name: 'CapAnimation',
	props: { type: String },
	methods: {},
	mounted() {
		gsap.from('svg path', { duration: 1, stagger: 0.005, drawSVG: 0 })
	}
}
</script>

<style lang="sass" scoped>
.ani-container
	max-height: 100%
	width: 100%
	height: 100%
	padding: 20px 10px
	display: flex
	justify-content: center
	align-items: center
	svg
		transform-origin: 50% 50%
		width: 65%
		path
			stroke-width: 0.4px
			stroke-dasharray: 5px
</style>