<template>
	<div :class="{ inactive: !selectedSide || !selectedPosition }">
		<v-row>
			<v-col class="new-finish-row py-0 pt-5" cols="8">
				<SectionLabel :label="$getDictString('configurator_finish_heading_size')" />
				<div class="d-flex pt-4">
					<TextButton label="Breite" :active="selectedSizeMeasure === 'width'" @click.native="selectedSizeMeasure = 'width'" />
					<TextButton label="Höhe" :active="selectedSizeMeasure === 'height'" @click.native="selectedSizeMeasure = 'height'" />
				</div>
			</v-col>

			<v-col class="py-0 pt-6 d-flex flex-column items-end" cols="4" v-if="selectedSizeMeasure === 'width'">
				<SectionLabel class="text-right" :label="$getDictString('configurator_finish_heading_size_width')" />
				<div class="text-left px-0 py-0 values d-flex justify-center items-center flex-column">
					<span class="large">
						<v-text-field
							type="number"
							outlined
							:value="selectedWidth"
							@keydown="
								(ev) => {
									if (ev.keyCode === 13) {
										this.setSize('width', ev.target.value)
										// selectedWidth = ev.target.value
									}
								}
							"
							@blur="
								(ev) => {
									this.setSize('width', ev.target.value)
									// selectedWidth = ev.target.value
								}
							"
							:min="0"
							step="0.1"
							hide-details
							suffix="cm"
						></v-text-field>
					</span>
				</div>
			</v-col>
			<v-col class="py-0 pt-6" cols="4" v-if="selectedSizeMeasure === 'height'">
				<SectionLabel class="text-right" :label="$getDictString('configurator_finish_heading_size_height')" />
				<div class="text-left px-0 py-0 values d-flex justify-center items-center flex-column">
					<span class="large">
						<v-text-field
							type="number"
							outlined
							:value="selectedHeight"
							@keydown="
								(ev) => {
									if (ev.keyCode === 13) {
										this.setSize('height', ev.target.value)
										// selectedHeight = ev.target.value;
									}
								}
							"
							@blur="
								(ev) => {
									this.setSize('height', ev.target.value)
									// selectedHeight = ev.target.value;
								}
							"
							:min="0"
							step="0.1"
							hide-details
							hint="maximal 10cm"
							suffix="cm"
						></v-text-field>
					</span>
				</div>
			</v-col>
			<v-col cols="12" class="pt-0">
				<div class="py-0 my-0 flex flex-row justify-start items-center flex-row">
					<v-slider
						v-if="selectedSizeMeasure === 'height' && maxHeight"
						class="selection-range ml-0 py-2"
						v-model="selectedHeight"
						:max="maxHeight"
						:min="0.1"
						step="0.1"
						hide-details
						color="black"
						track-color="lightgrey"
					></v-slider>
					<v-slider
						v-if="selectedSizeMeasure === 'width' && maxWidth"
						class="selection-range ml-0 py-2"
						v-model="selectedWidth"
						:max="maxWidth"
						:min="0.1"
						step="0.1"
						hide-details
						color="black"
						track-color="lightgrey"
					></v-slider>
				</div>
			</v-col>
		</v-row>
		<v-row>
			<v-col class="pt-0">
				<InfoText :text="$getDictString('configurator_finish_text_size_1')" />
				<InfoText v-if="sizeWarning" class="mt-5" :warning="true" :text="$getDictString('configurator_finish_warning_size')" />
			</v-col>
			<v-col class="py-0">
				<InfoText
					:text="
						$getDictString('configurator_finish_text_size_2', {
							MAX_HEIGHT: maxHeight,
							MAX_WIDTH: maxWidth,
						})
					"
			/></v-col>
		</v-row>
	</div>
</template>

<script>
import { mapFields } from 'vuex-map-fields'

import { EventBus } from '@/utils/eventbus'

import PositionSettings from '@/assets/data/finishSettings.js'

import TextButton from '../../components/TextButton'
import SectionLabel from '../../components/SectionLabel'
import InfoText from '../../components/InfoText'

export default {
	name: 'Technique',
	components: { SectionLabel, InfoText, TextButton },
	data() {
		return {
			positionOptions: PositionSettings.positionOptions,
			maxSizes: PositionSettings.maxSizes,
			maxHeight: null,
			maxWidth: null,
		}
	},
	mounted() {
		this.getMaxSizes()
	},
	watch: {
		productFinishes() {
			this.getMaxSizes()
		},
	},
	methods: {
		setSize(measureType, size) {
			if (measureType === 'width') {
				if (size < this.maxWidth) {
					this.selectedWidth = parseFloat(size)
				} else {
					this.selectedWidth = this.maxWidth
				}
			} else if (measureType === 'height') {
				if (size < this.maxHeight) {
					this.selectedHeight = parseFloat(size)
				} else {
					this.selectedHeight = this.maxHeight
				}
			}
		},
		getMaxSizes() {
			const cb = (maxSizes) => {
				this.maxHeight = maxSizes.maxHeight
				this.maxWidth = maxSizes.maxWidth

				// check if size is inside max-bounds
				const sizeMeasure = this.productFinishes[this.selectedFinishId].sizeMeasure
				const sizeObj = this.productFinishes[this.selectedFinishId].size
				const maxSize = sizeMeasure === 'width' ? this.maxWidth : this.maxHeight
				if (sizeObj[sizeMeasure] > maxSize) {
					// console.log('TOO BIG')
					this.finishTooBig = true
				} else {
					// console.log('TOO BIG set to false')
					// this.finishTooBig = false
				}
			}
			// event in svgComponentData
			EventBus.$emit('svgComponentData/getMaxSizes', { finish: this.productFinishes[this.selectedFinishId], cb })
		},
		keepAspectRatio(finish) {
			const imageHeight = finish.imageHeight
			const imageWidth = finish.imageWidth

			if (this.selectedSizeMeasure === 'height') {
				const ratio = imageWidth / imageHeight
				return { width: ratio * finish.size.height, height: 0 }
			} else if (this.selectedSizeMeasure === 'width') {
				const ratio = imageHeight / imageWidth
				return { height: ratio * finish.size.width, width: 0 }
			} else {
				return 0
			}
		},
	},
	computed: {
		...mapFields('ConfiguratorDisplay', {
			sizeWarning: 'sizeWarning',
			// 	sliderMaxHeight: 'sliderMaxHeight',
			// 	sliderMaxWidth: 'sliderMaxWidth',
		}),
		...mapFields('Configurator', {
			selectedCategory: 'category',
			selectedFinishId: 'selectedFinishId',
			productFinishes: 'finishes',
			selectedProduct: 'product',
			finishTooBig: 'finishTooBig',
		}),
		selectedSizeMeasure: {
			get() {
				return this.productFinishes[this.selectedFinishId] && this.productFinishes[this.selectedFinishId].sizeMeasure
			},
			set(value) {
				// calculate other measure size based on aspect rartio
				const newSizes = this.keepAspectRatio(this.productFinishes[this.selectedFinishId])

				// set new size measure
				this.$store.dispatch('Configurator/updateFinish', { key: 'sizeMeasure', value })

				// set new sizes
				this.$store.dispatch('Configurator/updateFinish', {
					key: 'size',
					value: newSizes,
				})
			},
		},
		selectedWidth: {
			get() {
				return this.productFinishes[this.selectedFinishId] && this.productFinishes[this.selectedFinishId].size.width
			},
			set(width) {
				const value = { width, height: 0 }
				this.$store.dispatch('Configurator/updateFinish', { key: 'size', value })
			},
		},
		selectedHeight: {
			get() {
				return this.productFinishes[this.selectedFinishId] && this.productFinishes[this.selectedFinishId].size.height
			},
			set(height) {
				const value = { width: 0, height }
				this.$store.dispatch('Configurator/updateFinish', { key: 'size', value })
			},
		},
		selectedFileName: {
			get() {
				return this.productFinishes[this.selectedFinishId] && this.productFinishes[this.selectedFinishId].fileName
			},
		},
		selectedSide: {
			get() {
				return this.productFinishes[this.selectedFinishId] && this.productFinishes[this.selectedFinishId].side
			},
		},
		selectedTechnique: {
			get() {
				return this.productFinishes[this.selectedFinishId] && this.productFinishes[this.selectedFinishId].technique
			},
		},
		selectedPosition: {
			get() {
				return this.productFinishes[this.selectedFinishId] && this.productFinishes[this.selectedFinishId].position
			},
		},
		currentImgHeight() {
			return Math.floor(this.selectedSize / this.aspectRatio)
		},
	},
}
</script>