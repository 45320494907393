<template>
	<v-row no-gutters class="wrapper px-5">
		<v-col cols="3" class="d-flex items-start flex-column pt-2 pl-2 pr-2 pb-2">
			<small>Motiv</small>
			<img class="finish-image" :src="$getFileUrlById(finish.fileName)" />
		</v-col>
		<v-col cols="9" class="pl-5 d-flex justify-start items-center selected-colors pr-5">
			<div class="type-text pl-3 pr-5">
				<small>Farbeinstellung</small>
				<div class="even-size d-flex items-center">
					<h3>{{ settingsLabel }}</h3>
					<!-- <h3 v-if="variants[variantIdx].finishes[finishIdx].type === 'multiple'">
						Mehrfarbiger {{ variants[variantIdx].finishes[finishIdx].technique === 'print' ? 'Druck' : 'Stick' }}
					</h3>
					<h3 v-if="variants[variantIdx].finishes[finishIdx].type === 'single'">
						Einfarbiger {{ variants[variantIdx].finishes[finishIdx].technique === 'print' ? 'Druck' : 'Stick' }}
					</h3>
					<h3 v-if="variants[variantIdx].finishes[finishIdx].type === 'recommendation'">Empfehlung</h3> -->
				</div>
			</div>

			<div
				v-if="variants[variantIdx].finishes[finishIdx].type !== 'recommendation'"
				class="d-flex flex-column justify-start items-start pl-5"
			>
				<small>Druckfarbe</small>
				<div class="d-flex colors-wrapper items-center even-size">
					<div
						v-for="(color, idx) in variants[variantIdx].finishes[finishIdx].colors"
						:key="idx"
						:style="{ 'background-color': color.substituteRgbColor ? color.substituteRgbColor : color.hexColor }"
						class="color-blob"
					></div>
				</div>
			</div>

			<div class="d-flex flex-column pl-5 pr-5 pb-5">
				<InfoText
					:text="$getDictString('configurator_variants_text_color_select')"
					v-if="variants[variantIdx].finishes[finishIdx].type === 'recommendation'"
				/>
				<div
					v-if="
						variants[variantIdx].finishes[finishIdx].type === 'recommendation' &&
						variants[variantIdx].finishes[finishIdx].comment &&
						variants[variantIdx].finishes[finishIdx].comment.length > 0
					"
					class="mt-4"
					style="font-style: italic; font-weight: bold"
				>
					"{{ variants[variantIdx].finishes[finishIdx].comment.slice(0, MAX_COMMENT_LENGTH)
					}}{{ variants[variantIdx].finishes[finishIdx].comment.length > MAX_COMMENT_LENGTH ? '...' : '' }}"
				</div>
			</div>
		</v-col>
	</v-row>
</template>

<script>
import InfoText from '../../components/InfoText'

import { mapFields } from 'vuex-map-fields'

export default {
	name: 'DisplayFinish',
	components: { InfoText },
	props: {
		finish: { type: Object, required: true },
		finishIdx: { type: Number, required: true },
		variantIdx: { type: Number, required: true },
	},
	data() {
		return {
			MAX_COMMENT_LENGTH: 40,
		}
	},
	computed: {
		...mapFields('Dictionary', {
			dictionary: 'readableDictionary',
		}),
		...mapFields('Configurator', {
			variants: 'variants',
		}),
		labels() {
			return {
				'single.print': this.dictionary['configurator_finishes_settings_label_single_print'],
				'multiple.print': this.dictionary['configurator_finishes_settings_label_multiple_print'],
				'recommendation.print': this.dictionary['configurator_finishes_settings_label_recommendation_print'],
				'single.stick': this.dictionary['configurator_finishes_settings_label_single_stick'],
				'multiple.stick': this.dictionary['configurator_finishes_settings_label_multiple_stick'],
				'recommendation.stick': this.dictionary['configurator_finishes_settings_label_recommendation_stick'],
			}
		},
		settingsLabel() {
			const variantFinish = this.variants[this.variantIdx].finishes[this.finishIdx]
			return this.labels[`${variantFinish.type}.${this.finish.technique}`]
		},
	},
	created() {
		// console.log(this.finish)
	},
	methods: {},
}
</script>

<style scoped lang="sass">
.wrapper
	cursor: pointer
	transition: all 200ms
	border-radius: 4px
	border: none
	&:hover
		background: rgba(0,0,0,0.03)
	.even-size
		height: 80px
	small
		opacity: 0.5
		z-index: 1
		position: relative
		top: -10px
		font-size: 0.8em
		font-weight: 400
	.finish-image
		width: 80px
		height: 80px
		object-fit: contain
	.type-text
		font-weight: 700
		font-size: 12px
	.selected-colors
		position: relative
		left: -1px // hacky
		padding: 0px 0
		flex-grow: 2
		overflow: hidden
		width: 100%
		.colors-wrapper
			position: relative
			.color-blob
				border: 1px solid #ebebeb !important
				width: 40px
				height: 40px
				border-radius: 50%
				position: relative
				z-index: 10
				&:nth-of-type(2)
					left: -30px
					z-index: 8
				&:nth-of-type(3)
					left: -60px
					z-index: 7
				&:nth-of-type(4)
					left: -90px
					z-index: 6
				&:nth-of-type(5)
					left: -120px
					z-index: 5
				&:nth-of-type(6)
					left: -150px
					z-index: 4
</style>