<template>
	<div id="finish-file-upload">
		<FilePond
			name="ProductFiles"
			files=""
			ref="filePondInstance"
			label-idle="neue datei hochladen"
			:allow-multiple="false"
			:accepted-file-types="acceptedFiles"
			v-on:init="handleFilePondInit"
			class="add-new-file-btn"
		/>
	</div>
</template>

<script>
import { mapFields } from 'vuex-map-fields'

import vueFilePond from 'vue-filepond'
import 'filepond/dist/filepond.min.css'
import 'filepond-plugin-image-preview/dist/filepond-plugin-image-preview.min.css'
import FilePondPluginFileValidateType from 'filepond-plugin-file-validate-type'
import FilePondPluginImagePreview from 'filepond-plugin-image-preview'

const FilePond = vueFilePond(FilePondPluginFileValidateType, FilePondPluginImagePreview)

export default {
	name: 'FinishFileUpload',
	components: { FilePond },
	computed: {
		...mapFields('ConfiguratorFiles', {
			fileOptions: 'files',
		}),
		acceptedFiles() {
			if (navigator.userAgent.toLowerCase().indexOf('firefox') > -1) {
				return 'image/png, image/jpg, image/jpeg'
			} else {
				return 'image/png, image/jpg, image/jpeg, image/svg+xml'
			}
		},
	},
	methods: {
		handleFilePondInit: function () {
			// set filepond options
			const that = this
			this.$refs['filePondInstance']._pond.setOptions({
				server: {
					process: function (fieldName, file) {
						const uploadData = {
							file,
						}

						that.$store.dispatch('ConfiguratorFiles/uploadFile', uploadData).then((upload) => {
							// select file
							// that.$store.dispatch('Configurator/updateFinish', { key: 'fileName', value: upload.filename })
							that.$store.dispatch('Configurator/updateFinish', { key: 'fileName', value: upload._id })

							// remove file from uploader
							var pond_ids = []
							if (that.$refs['filePondInstance'].getFiles().length != 0) {
								that.$refs['filePondInstance'].getFiles().forEach(function (file) {
									pond_ids.push(file.id)
								})
							}
							that.$refs['filePondInstance'].removeFiles(pond_ids)
						})
					},
				},
			})
		},
	},
}
</script>

<style lang="sass">
#finish-file-upload
	.filepond--wrapper
		position: relative
		height: 100%
		overflow: hidden
	.filepond--root
		position: absolute
		top: 0
		left: 0
		width: 100%

	.v-slide-group__next
		position: absolute
		right: 10px
		width: 30px
		top: 0
		left: 0

	.filepond--drop-label
		position: absolute
		width: 100%
		background: white
		display: inline-flex
		justify-content: center
		align-items: center
		text-align: center
		height: 11vw !important
		width: 100% !important
		&:hover
			label
				color: rgba(0,0,0,0.1)
				transform: translateY(10px)
				&:before
					color: black
					transform: scale(1.8)

		label
			width: 100% !important
			white-space: break-spaces !important
			position: relative !important
			pointer-events: none
			font-weight: 700
			color: black
			transition: all 300ms ease-out
			&:before
				content: "+"
				display: block
				font-weight: 700
				font-size: 34px
				color: rgba(0,0,0,0.7)
				transition: all 200ms ease-out

	.filepond--credits
		display: none

	.filepond--root.filepond--hopper
		margin-bottom: 0
		background: white

	.add-new-file-btn
		border: 1px solid rgba(214, 214, 214, 0.5) !important
		border-radius: 4px
		font-size: 16px
		font-weight: 700
		background: white
		color: rgba(0,0,0,0.17)
		width: 8.5vw
		height: 11vw
		max-width: 160px
		max-height: 210px
		margin: 10px 10px 0px 0px
		display: flex
		flex-direction: row
		justify-content: space-evenly
		align-items: center
		justify-content: center
		flex-direction: column
		flex-wrap: wrap
		word-wrap: wrap
		cursor: pointer
		&.header
			text-transform: uppercase
			font-size: 10px
			font-weight: 700
			letter-spacing: 0.2em
			padding: 20px 0
		&.content
			background: white
			border: 1px solid rgba(214,214,214,1)
			border-radius: 4px
			padding: 20px 0
			font-size: 1.5em
			font-weight: 700
			letter-spacing: -0.02em
</style>