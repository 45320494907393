 <template>
	<div :class="{ label: true }" v-html="label"></div>
</template>

<style scoped lang="sass">
.label
    width: 100%
    // font-size: 12px
    margin-left: 0.1em
    font-size: 0.75em
    font-family: 'Helvetica'
    font-weight: 700
    text-transform: uppercase
    letter-spacing: 0.2em
    color: rgba(0,0,0,1)
    padding-bottom: 10px
</style>

<script>
export default {
	name: 'SectionLabel',
	props: {
		label: { type: String },
	},
}
</script>