 <template>
	<div>
		<div :class="{ button: true, fade: fade && !active, 'active-state': active, 'display-only': displayOnly }">
			<div class="inner">
				<div class="image" :class="{ 'color-circle': hasColor }" v-if="image">
					<v-img :src="image" contain> </v-img>
				</div>
				<div class="color-circle" v-if="hexColor" :style="{ background: '#' + hexColor }"></div>
				<div class="label px-2" v-if="!tooltip">{{ label }}</div>

				<v-tooltip bottom v-if="tooltip">
					<template v-slot:activator="{ on, attrs }">
						<span v-bind="attrs" v-on="on" class="label px-2">{{ label | truncate(20) }}</span>
					</template>
					<span>{{ label }}</span>
				</v-tooltip>

				<div class="sub-label px-2" v-if="subLabel">{{ subLabel }}</div>
			</div>
		</div>
	</div>
</template>

<script>
export default {
	name: 'BoxButton',
	components: {},
	props: {
		label: { type: String },
		subLabel: { type: String },
		image: { type: String, required: false },
		hexColor: { type: String, required: false },
		fade: { type: Boolean },
		active: { type: Boolean },
		hasColor: { type: Boolean },
		displayOnly: { type: Boolean },
		tooltip: { type: Boolean, default: false },
	},
}
</script>

<style scoped lang="sass">
.button
	border: 1px solid rgba(214, 214, 214, 0.5)
	border-radius: 4px
	font-size: 1em
	font-weight: 70
	width: 8.7vw
	height: 11vw

	// max-width: 160px
	// max-height: 210px

	display: flex
	align-items: center
	justify-content: flex-start
	flex-direction: column
	margin: 10px 10px 10px 0px
	cursor: pointer
	position: relative
	transition: all 400ms ease
	&:before
		content: " "
		position: absolute
		top: -11px
		right: -11px
		background-image: url('~@/assets/icons/select-check.png')
		width: 25px
		height: 25px
		opacity: 0
		transform: scale(0)
		transition: all 300ms ease
		transition-delay: 100ms

	.inner
		height: 100%
		width: 100%
		display: flex
		align-items: center
		justify-content: center
		flex-direction: column
		overflow: hidden

		.label
			font-size: 1em
			text-align: center
			color: rgba(0,0,0,0.9)
			font-weight: 700

		.sub-label
			font-size: 0.7em
			text-align: center
			color: rgba(0,0,0,0.9)
			font-weight: 500
			text-transform: capitalize

		.v-image
			height: 7.2vw
			width: 6.5vw

			// max-width: 120px
			// max-height: 170px
		.color-circle
			border-radius: 50%
			margin: 0
			margin-bottom: 10px
			padding: 0
			height: 6vw
			width: 6vw
			// border: solid 2px red
			.v-image
				border-radius: 50%
				height: 6vw
				width: 6vw
				box-shadow: 0 1px 2px rgba(0,0,0,0.3) !important
				margin-bottom: 10px
	&.display-only
		border: none !important
		margin: 0 !important
		justify-content: center
		&:hover
			filter: opacity(1) !important
	&.fade
		filter: opacity(0.5)
	&.active-state
		border-color: rgba(140,140,140,1)
		&:before
			opacity: 1
			transform: scale(1)
	&:hover
		filter: opacity(0.8)
		border-color: rgb(210,210,210)
</style>