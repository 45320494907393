var render = function () {
var this$1 = this;
var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{class:{ inactive: !_vm.selectedSide || !_vm.selectedPosition }},[_c('v-row',[_c('v-col',{staticClass:"new-finish-row py-0 pt-5",attrs:{"cols":"8"}},[_c('SectionLabel',{attrs:{"label":_vm.$getDictString('configurator_finish_heading_size')}}),_c('div',{staticClass:"d-flex pt-4"},[_c('TextButton',{attrs:{"label":"Breite","active":_vm.selectedSizeMeasure === 'width'},nativeOn:{"click":function($event){_vm.selectedSizeMeasure = 'width'}}}),_c('TextButton',{attrs:{"label":"Höhe","active":_vm.selectedSizeMeasure === 'height'},nativeOn:{"click":function($event){_vm.selectedSizeMeasure = 'height'}}})],1)],1),(_vm.selectedSizeMeasure === 'width')?_c('v-col',{staticClass:"py-0 pt-6 d-flex flex-column items-end",attrs:{"cols":"4"}},[_c('SectionLabel',{staticClass:"text-right",attrs:{"label":_vm.$getDictString('configurator_finish_heading_size_width')}}),_c('div',{staticClass:"text-left px-0 py-0 values d-flex justify-center items-center flex-column"},[_c('span',{staticClass:"large"},[_c('v-text-field',{attrs:{"type":"number","outlined":"","value":_vm.selectedWidth,"min":0,"step":"0.1","hide-details":"","suffix":"cm"},on:{"keydown":function (ev) {
								if (ev.keyCode === 13) {
									this$1.setSize('width', ev.target.value)
									// selectedWidth = ev.target.value
								}
							},"blur":function (ev) {
								this$1.setSize('width', ev.target.value)
								// selectedWidth = ev.target.value
							}}})],1)])],1):_vm._e(),(_vm.selectedSizeMeasure === 'height')?_c('v-col',{staticClass:"py-0 pt-6",attrs:{"cols":"4"}},[_c('SectionLabel',{staticClass:"text-right",attrs:{"label":_vm.$getDictString('configurator_finish_heading_size_height')}}),_c('div',{staticClass:"text-left px-0 py-0 values d-flex justify-center items-center flex-column"},[_c('span',{staticClass:"large"},[_c('v-text-field',{attrs:{"type":"number","outlined":"","value":_vm.selectedHeight,"min":0,"step":"0.1","hide-details":"","hint":"maximal 10cm","suffix":"cm"},on:{"keydown":function (ev) {
								if (ev.keyCode === 13) {
									this$1.setSize('height', ev.target.value)
									// selectedHeight = ev.target.value;
								}
							},"blur":function (ev) {
								this$1.setSize('height', ev.target.value)
								// selectedHeight = ev.target.value;
							}}})],1)])],1):_vm._e(),_c('v-col',{staticClass:"pt-0",attrs:{"cols":"12"}},[_c('div',{staticClass:"py-0 my-0 flex flex-row justify-start items-center flex-row"},[(_vm.selectedSizeMeasure === 'height' && _vm.maxHeight)?_c('v-slider',{staticClass:"selection-range ml-0 py-2",attrs:{"max":_vm.maxHeight,"min":0.1,"step":"0.1","hide-details":"","color":"black","track-color":"lightgrey"},model:{value:(_vm.selectedHeight),callback:function ($$v) {_vm.selectedHeight=$$v},expression:"selectedHeight"}}):_vm._e(),(_vm.selectedSizeMeasure === 'width' && _vm.maxWidth)?_c('v-slider',{staticClass:"selection-range ml-0 py-2",attrs:{"max":_vm.maxWidth,"min":0.1,"step":"0.1","hide-details":"","color":"black","track-color":"lightgrey"},model:{value:(_vm.selectedWidth),callback:function ($$v) {_vm.selectedWidth=$$v},expression:"selectedWidth"}}):_vm._e()],1)])],1),_c('v-row',[_c('v-col',{staticClass:"pt-0"},[_c('InfoText',{attrs:{"text":_vm.$getDictString('configurator_finish_text_size_1')}}),(_vm.sizeWarning)?_c('InfoText',{staticClass:"mt-5",attrs:{"warning":true,"text":_vm.$getDictString('configurator_finish_warning_size')}}):_vm._e()],1),_c('v-col',{staticClass:"py-0"},[_c('InfoText',{attrs:{"text":_vm.$getDictString('configurator_finish_text_size_2', {
						MAX_HEIGHT: _vm.maxHeight,
						MAX_WIDTH: _vm.maxWidth,
					})}})],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }