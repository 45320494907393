<template>
	<div class="color-selection">
		<v-row no-gutters>
			<v-col cols="5">
				<div class="original-color">
					<div class="color" :style="{ background: color.hexColor }"></div>
					<!-- <div class="hex"></div> -->
					<div class="label-box">
						<div class="label">{{ color.hexColor }}</div>
						<div class="pantone">automatisch</div>
					</div>
				</div>
			</v-col>
			<v-col cols="1" class="d-flex justify-center items-center">
				<div class="arrow">
					<v-icon :style="{ color: 'rgba(0, 0, 0, 0.17)' }">mdi-transfer-right</v-icon>
				</div>
			</v-col>
			<v-col cols="5">
				<div class="new-color" v-if="!edit">
					<div id="color-selection-modal-button" @click="showWarningModal" v-if="!selectedColor">Farbe Auswählen</div>
					<ColorSelectionWarningModal
						:showColorSelectionModalFn="showColorSelectionModalFn"
						v-if="!selectedColor"
						:showModal="showColorSelectionWarningModal"
					/>
					<ColorSelectionModal
						:showColorSelectionModalFn="showColorSelectionModalFn"
						:dialog="showColorSelectionModal"
						:color="color"
						:finishIdx="finishIdx"
						v-if="!selectedColor"
					/>
					<div class="selected-pantone" v-if="!!color.substituteColor">
						<div class="color" :style="{ 'background-color': getPantoneLabel(selectedColor).color }"></div>
						<div class="label-box">
							<div class="label">{{ getPantoneLabel(selectedColor).name }}</div>
							<div class="pantone">{{ getPantoneLabel(selectedColor).pantone }}</div>
						</div>
					</div>
				</div>
			</v-col>
			<v-col cols="1" class="d-flex justify-center items-center">
				<div class="delete-btn ml-1" @click="removeColor" v-if="!!color.substituteColor">
					<v-btn icon x-small text color="grey">
						<v-icon flat>mdi-delete</v-icon>
					</v-btn>
				</div>
			</v-col>
		</v-row>
	</div>
</template>

<script>
import ColorSelectionModal from './ColorSelectionModal'
import PantoneColorsArray from '@/assets/data/pantone.js'
import ColorSelectionWarningModal from './ColorSelectionWarningModal'
import { mapFields } from 'vuex-map-fields'

export default {
	name: 'ColorSelection',
	components: { ColorSelectionModal, ColorSelectionWarningModal },
	props: {
		color: { type: Object },
		finishIdx: { type: String },
	},
	data() {
		return {
			showColorSelectionModal: false,
			showColorSelectionWarningModal: false,
			edit: false,
			// selectedColor: null,
			pantoneColors: PantoneColorsArray,
		}
	},
	computed: {
		...mapFields('ConfiguratorDisplay', {
			colorSelectionWarningAccepted: 'colorSelectionWarningAccepted',
		}),
		...mapFields('Configurator', {
			variants: 'variants',
			selectedVariantId: 'selectedVariantId',
		}),
		selectedColor: {
			get() {
				if (this.variants[this.selectedVariantId] && this.variants[this.selectedVariantId].finishes[this.finishIdx]) {
					const colors = this.variants[this.selectedVariantId].finishes[this.finishIdx].colors
					return colors.find((c) => c.hexColor === this.color.hexColor).substituteColor
				} else {
					return '#000000'
				}
			},
			set(value) {
				const pantoneColorObject = this.pantoneColors.find((pc) => pc.pantone === value)
				this.$store.dispatch('Configurator/updateVariant', {
					key: 'finishColor',
					value: {
						substituteColor: value,
						substituteRgbColor: pantoneColorObject.color,
						hexColor: this.color.hexColor,
						finishIdx: this.finishIdx,
					},
				})
			},
		},
	},
	methods: {
		removeColor() {
			this.edit = false

			this.$store.dispatch('Configurator/updateVariant', {
				key: 'finishColor',
				value: { substituteColor: null, hexColor: this.color.hexColor, finishIdx: this.finishIdx },
			})
		},
		getPantoneLabel(pantone) {
			const obj = this.pantoneColors.find((s) => s.pantone === pantone)
			if (obj) {
				return obj
			} else {
				return { label: 'n/a', pantone }
			}
		},
		showWarningModal() {
			if (this.colorSelectionWarningAccepted) this.showColorSelectionModalFn(true)
			else this.showColorSelectionWarningModal = true
			// if (this.colorSelectionWarningAccepted) this.$store.dispatch('ConfiguratorDisplay/toggleColorSelectionModal')
			// else this.$store.dispatch('ConfiguratorDisplay/toggleColorSelectionWarningModal')
		},
		showColorSelectionModalFn(val) {
			this.showColorSelectionWarningModal = false
			this.showColorSelectionModal = val
		},
	},
}
</script>

<style scoped lang="sass">
#color-selection-modal-button
	padding: 10px
	font-size: 12px
	cursor: pointer
	font-weight: 700
	display: inline-flex
	justify-content: center
	align-items: center
	border: 1px solid rgb(214, 214, 214)
	border-radius: 4px
	width: 100%
	height: 100%
	.color
		width: 60px
		height: 100%
		border-radius: 4px 0 0 4px
		border-right: 1px solid rgb(214, 214, 214)
.color-selection
	background: white
	height: 50px
	width: 100%
	display: flex
	justify-content: space-between
	align-items: center
	margin-top: 20px
	.original-color
		display: inline-flex
		justify-content: flex-start
		border: 1px solid rgb(214, 214, 214)
		border-radius: 4px
		width: 100%
		height: 100%
		.color
			width: 60px
			height: 100%
			border-radius: 4px 0 0 4px
			border-right: 1px solid rgb(214, 214, 214)
	.label-box
		display: flex
		flex-direction: column
		align-items: flex-start
		justify-content: center
		text-align: left
		min-width: 95px
		padding: 5px 0
		.label, .pantone
			padding: 0 0 0 7px
			font-size: 12px
			font-weight: 700
			letter-spacing: -0.02em
			line-height: 1.2em
			&.pantone
				font-size: 11px
				font-weight: 500
				opacity: 0.5
				padding-top: 3px

	.new-color
		display: flex
		justify-content: flex-start
		align-items: center
		height: 100%
		.selected-pantone
			display: flex
			justify-content: flex-start
			align-items: center
			height: 100%
			width: 100%
			position: relative
			border: 1px solid rgb(214, 214, 214)
			border-radius: 4px
			.arrow
				position: absolute
				left: -32px
			.color
				width: 60px
				height: 100%
				border-radius: 4px 0 0 4px
			.label-box
				display: flex
				flex-direction: column
				align-items: flex-start
				justify-content: center
				text-align: left
				min-width: 95px
				.label, .pantone
					padding: 0 0 0 7px
					font-size: 12px
					font-weight: 700
					letter-spacing: -0.02em
					line-height: 1.2em
					width: 100%
					overflow: hidden
					white-space: nowrap
					text-overflow: ellipsis
					&.pantone
						font-size: 11px
						font-weight: 500
						opacity: 1
						padding-top: 3px
			.delete-btn
				cursor: pointer
				height: 100%
				width: 20px
				display: flex
				justify-content: center
				align-items: center
				font-weight: 700
</style>