<template>
	<div class="d-flex flex-column fill-height">
		<SectionLabel :label="dictionary['configurator_finish_heading_technique']" />
		<div class="button-wrapper d-flex justify-start flex-column">
			<TextButton
				class="flex-1"
				v-for="(option, idx) in techniqueOptions[selectedCategory]"
				:key="idx"
				:label="option.label"
				:active="selectedTechnique === option.value"
				@click.native="
					() => {
						selectedTechnique = option.value
						//controllerEvent('innendruck')
					}
				"
				@mouseenter.native="false && controllerEvent(option.value)"
				@mouseleave.native="false && controllerEvent('reset')"
			/>
		</div>
	</div>
</template>

<script>
import { mapFields } from 'vuex-map-fields'

import { EventBus } from '@/utils/eventbus'
import PositionSettings from '@/assets/data/finishSettings.js'
import SectionLabel from '../../components/SectionLabel'
import TextButton from '../../components/TextButton'

export default {
	name: 'Technique',
	components: { TextButton, SectionLabel },
	data() {
		return {
			techniqueOptions: PositionSettings.techniqueOptions,
			sideOptions: PositionSettings.sideOptions,
			positionOptions: PositionSettings.positionOptions,
			maxHeight: null,
			maxWidth: null,
		}
	},
	computed: {
		...mapFields('Dictionary', {
			dictionary: 'readableDictionary',
		}),
		...mapFields('Configurator', {
			selectedCategory: 'category',
			selectedFinishId: 'selectedFinishId',
			productFinishes: 'finishes',
			selectedProduct: 'product',
		}),
		selectedTechnique: {
			get() {
				return this.productFinishes[this.selectedFinishId] && this.productFinishes[this.selectedFinishId].technique
			},
			set(value) {
				const finish = this.productFinishes[this.selectedFinishId]

				// unset techniqueType
				if (value !== 'lables' && finish.selectedTechniqueType !== '')
					this.$store.dispatch('Configurator/updateFinish', { key: 'techniqueType', value: '' })

				// set side and position
				const newSide = this.sideOptions[this.selectedCategory][value][0].value
				this.$store.dispatch('Configurator/updateFinish', { key: 'side', value: newSide })

				const newPosition = this.allowedPositionOptions[newSide][0].value
				this.$store.dispatch('Configurator/updateFinish', { key: 'position', value: newPosition })

				// set size
				// this.$store.dispatch('Configurator/updateFinish', { key: 'size', value: { width: 5, height: 0 } })
				// this.$store.dispatch('Configurator/updateFinish', { key: 'sizeMeasure', value: 'width' })

				// check if size is inside max-bounds
				const sizeMeasure = this.productFinishes[this.selectedFinishId].sizeMeasure
				const sizeObj = this.productFinishes[this.selectedFinishId].size
				const maxSize = sizeMeasure === 'width' ? this.maxWidth : this.maxHeight
				console.log(`${sizeObj[sizeMeasure]} > ${maxSize}`)
				if (sizeObj[sizeMeasure] > maxSize) {
					const newSizes = { width: 0, height: 0 }
					newSizes[sizeMeasure] = maxSize
					this.$store.dispatch('Configurator/updateFinish', {
						key: 'size',
						value: newSizes,
					})
				}

				this.$store.dispatch('Configurator/updateFinish', { key: 'technique', value })
			},
		},
		allowedPositionOptions() {
			// const options = { ...this.positionOptions[this.selectedCategory] }
			const options = { ...this.positionOptions[this.selectedProduct.productType] }
			for (let key in this.productFinishes) {
				const finish = this.productFinishes[key]
				if (finish.idx === undefined || finish.idx.toString() !== this.selectedFinishId.toString()) {
					options[finish.side] = options[finish.side].filter((po) => po.value !== finish.position)
				}
			}
			return options
		},
	},
	mounted() {
		this.getMaxSizes()
	},
	watch: {
		productFinishes() {
			this.getMaxSizes()
		},
	},
	methods: {
		getMaxSizes() {
			const cb = (maxSizes) => {
				this.maxHeight = maxSizes.maxHeight
				this.maxWidth = maxSizes.maxWidth
			}
			EventBus.$emit('svgComponentData/getMaxSizes', { finish: this.productFinishes[this.selectedFinishId], cb })
		},
	},
}
</script>