export default [
	{ color: 'rgb(246, 235, 97)', name: 'Portica', pantone: '100 C' },
	{ color: 'rgb(247, 234, 72)', name: 'Starship', pantone: '101 C' },
	{ color: 'rgb(252, 227, 0)', name: 'Turbo', pantone: '102 C' },
	{ color: 'rgb(197, 169, 0)', name: 'Buddha Gold', pantone: '103 C' },
	{ color: 'rgb(175, 152, 0)', name: 'Buddha Gold', pantone: '104 C' },
	{ color: 'rgb(137, 122, 39)', name: 'Wasabi', pantone: '105 C' },
	{ color: 'rgb(249, 229, 71)', name: 'Energy Yellow', pantone: '106 C' },
	{ color: 'rgb(251, 225, 34)', name: 'Candlelight', pantone: '107 C' },
	{ color: 'rgb(254, 219, 0)', name: 'School bus Yellow', pantone: '108 C' },
	{ color: 'rgb(255, 209, 0)', name: 'Gold', pantone: '109 C' },
	{ color: 'rgb(218, 170, 0)', name: 'Corn', pantone: '110 C' },
	{ color: 'rgb(170, 138, 0)', name: 'Pirate Gold', pantone: '111 C' },
	{ color: 'rgb(156, 132, 18)', name: 'Buttered Rum', pantone: '112 C' },
	{ color: 'rgb(250, 224, 83)', name: 'Energy Yellow', pantone: '113 C' },
	{ color: 'rgb(251, 221, 64)', name: 'Bright Sun', pantone: '114 C' },
	{ color: 'rgb(253, 218, 36)', name: 'Candlelight', pantone: '115 C' },
	{ color: 'rgb(255, 205, 0)', name: 'Gelb', pantone: '116 C' },
	{ color: 'rgb(201, 151, 0)', name: 'Buddha Gold', pantone: '117 C' },
	{ color: 'rgb(172, 132, 0)', name: 'Pirate Gold', pantone: '118 C' },
	{ color: 'rgb(137, 115, 34)', name: 'Kumera', pantone: '119 C' },
	{ color: 'rgb(248, 224, 142)', name: 'Marzipan', pantone: '1205 C' },
	{ color: 'rgb(232, 225, 199)', name: 'Cremefarben', pantone: '0804 TPX' },
	{ color: 'rgb(251, 219, 101)', name: 'Goldenrod', pantone: '120 C' },
	{ color: 'rgb(251, 216, 114)', name: 'Goldenrod', pantone: '1215 C' },
	{ color: 'rgb(253, 215, 87)', name: 'Dandelion', pantone: '121 C' },
	{ color: 'rgb(255, 200, 69)', name: 'Bright Sun', pantone: '1225 C' },
	{ color: 'rgb(255, 184, 28)', name: 'My Sin', pantone: '1235 C' },
	{ color: 'rgb(255, 199, 44)', name: 'Sunglow', pantone: '123 C' },
	{ color: 'rgb(198, 146, 20)', name: 'Pizza', pantone: '1245 C' },
	{ color: 'rgb(234, 170, 0)', name: 'Web Orange', pantone: '124 C' },
	{ color: 'rgb(173, 132, 31)', name: 'Mandalay', pantone: '1255 C' },
	{ color: 'rgb(181, 133, 0)', name: 'Pirate Gold', pantone: '125 C' },
	{ color: 'rgb(136, 107, 37)', name: 'Kumera', pantone: '1265 C' },
	{ color: 'rgb(154, 118, 17)', name: 'Buttered Rum', pantone: '126 C' },
	{ color: 'rgb(243, 221, 109)', name: 'Golden Sand', pantone: '127 C' },
	{ color: 'rgb(243, 213, 78)', name: 'Cream Can', pantone: '128 C' },
	{ color: 'rgb(243, 208, 62)', name: 'Saffron', pantone: '129 C' },
	{ color: 'rgb(242, 169, 0)', name: 'Web Orange', pantone: '130 C' },
	{ color: 'rgb(204, 138, 0)', name: 'Pirate Gold', pantone: '131 C' },
	{ color: 'rgb(160, 116, 0)', name: 'Chelsea Gem', pantone: '132 C' },
	{ color: 'rgb(108, 87, 27)', name: 'Himalaya', pantone: '133 C' },
	{ color: 'rgb(253, 208, 134)', name: 'Grandis', pantone: '1345 C' },
	{ color: 'rgb(253, 210, 110)', name: 'Goldenrod', pantone: '134 C' },
	{ color: 'rgb(255, 197, 110)', name: 'Koromiko', pantone: '1355 C' },
	{ color: 'rgb(255, 198, 88)', name: 'Golden Tainoi', pantone: '135 C' },
	{ color: 'rgb(255, 181, 73)', name: 'Yellow Orange', pantone: '1365 C' },
	{ color: 'rgb(255, 191, 63)', name: 'Yellow Orange', pantone: '136 C' },
	{ color: 'rgb(255, 158, 27)', name: 'Tree Poppy', pantone: '1375 C' },
	{ color: 'rgb(255, 163, 0)', name: 'Web Orange', pantone: '137 C' },
	{ color: 'rgb(213, 120, 0)', name: 'Mango Tango', pantone: '1385 C' },
	{ color: 'rgb(222, 124, 0)', name: 'Mango Tango', pantone: '138 C' },
	{ color: 'rgb(153, 96, 23)', name: 'Hawaiian Tan', pantone: '1395 C' },
	{ color: 'rgb(175, 109, 4)', name: 'Mai Tai', pantone: '139 C' },
	{ color: 'rgb(110, 76, 30)', name: 'West Coast', pantone: '1405 C' },
	{ color: 'rgb(116, 83, 28)', name: 'Himalaya', pantone: '140 C' },
	{ color: 'rgb(242, 199, 92)', name: 'Cream Can', pantone: '141 C' },
	{ color: 'rgb(241, 190, 72)', name: 'Ronchi', pantone: '142 C' },
	{ color: 'rgb(241, 180, 52)', name: 'Saffron', pantone: '143 C' },
	{ color: 'rgb(237, 139, 0)', name: 'Tangerine', pantone: '144 C' },
	{ color: 'rgb(207, 127, 0)', name: 'Indochine', pantone: '145 C' },
	{ color: 'rgb(167, 109, 17)', name: 'Reno Sand', pantone: '146 C' },
	{ color: 'rgb(113, 92, 42)', name: 'Dallas', pantone: '147 C' },
	{ color: 'rgb(255, 174, 98)', name: 'Koromiko', pantone: '1485 C' },
	{ color: 'rgb(254, 203, 139)', name: 'Chardonnay', pantone: '148 C' },
	{ color: 'rgb(255, 143, 28)', name: 'West Side', pantone: '1495 C' },
	{ color: 'rgb(255, 194, 123)', name: 'Macaroni and Cheese', pantone: '149 C' },
	{ color: 'rgb(255, 105, 0)', name: 'Blaze Orange', pantone: '1505 C' },
	{ color: 'rgb(255, 178, 91)', name: 'Texas Rose', pantone: '150 C' },
	{ color: 'rgb(255, 130, 0)', name: 'Flush Orange', pantone: '151 C' },
	{ color: 'rgb(185, 71, 0)', name: 'Rose of Sharon', pantone: '1525 C' },
	{ color: 'rgb(229, 114, 0)', name: 'Mango Tango', pantone: '152 C' },
	{ color: 'rgb(148, 69, 11)', name: 'Korma', pantone: '1535 C' },
	{ color: 'rgb(190, 106, 20)', name: 'Orange Roughy', pantone: '153 C' },
	{ color: 'rgb(101, 56, 25)', name: 'Espresso', pantone: '1545 C' },
	{ color: 'rgb(155, 90, 26)', name: 'Hawaiian Tan', pantone: '154 C' },
	{ color: 'rgb(255, 185, 144)', name: 'Hit Pink', pantone: '1555 C' },
	{ color: 'rgb(239, 209, 159)', name: 'Chalky', pantone: '155 C' },
	{ color: 'rgb(255, 160, 106)', name: 'Atomic Tangerine', pantone: '1565 C' },
	{ color: 'rgb(239, 190, 125)', name: 'Tacao', pantone: '156 C' },
	{ color: 'rgb(255, 127, 50)', name: 'Crusta', pantone: '1575 C' },
	{ color: 'rgb(236, 161, 84)', name: 'Porsche', pantone: '157 C' },
	{ color: 'rgb(255, 106, 19)', name: 'Pumpkin', pantone: '1585 C' },
	{ color: 'rgb(232, 119, 34)', name: 'Tango', pantone: '158 C' },
	{ color: 'rgb(216, 96, 24)', name: 'Hot Cinnamon', pantone: '1595 C' },
	{ color: 'rgb(203, 96, 21)', name: 'Orange Roughy', pantone: '159 C' },
	{ color: 'rgb(166, 85, 35)', name: 'Desert', pantone: '1605 C' },
	{ color: 'rgb(161, 86, 28)', name: 'Hawaiian Tan', pantone: '160 C' },
	{ color: 'rgb(139, 71, 32)', name: 'Cumin', pantone: '1615 C' },
	{ color: 'rgb(96, 61, 32)', name: 'Pickled Bean', pantone: '161 C' },
	{ color: 'rgb(255, 163, 139)', name: 'Mona Lisa', pantone: '1625 C' },
	{ color: 'rgb(255, 190, 159)', name: 'Wax Flower', pantone: '162 C' },
	{ color: 'rgb(255, 141, 109)', name: 'Salmon', pantone: '1635 C' },
	{ color: 'rgb(255, 157, 110)', name: 'Atomic Tangerine', pantone: '163 C' },
	{ color: 'rgb(255, 106, 57)', name: 'Orange', pantone: '1645 C' },
	{ color: 'rgb(255, 127, 65)', name: 'Coral', pantone: '164 C' },
	{ color: 'rgb(252, 76, 2)', name: 'Vermilion', pantone: '1655 C' },
	{ color: 'rgb(255, 103, 31)', name: 'Orange', pantone: '165 C' },
	{ color: 'rgb(220, 68, 5)', name: 'Trinidad', pantone: '1665 C' },
	{ color: 'rgb(227, 82, 5)', name: 'Trinidad', pantone: '166 C' },
	{ color: 'rgb(169, 67, 30)', name: 'Cognac', pantone: '1675 C' },
	{ color: 'rgb(190, 83, 28)', name: 'Orange Roughy', pantone: '167 C' },
	{ color: 'rgb(131, 57, 33)', name: 'Red Robin', pantone: '1685 C' },
	{ color: 'rgb(115, 56, 29)', name: 'Walnut', pantone: '168 C' },
	{ color: 'rgb(255, 179, 171)', name: 'Cornflower Lilac', pantone: '169 C' },
	{ color: 'rgb(255, 134, 116)', name: 'Salmon', pantone: '170 C' },
	{ color: 'rgb(255, 92, 57)', name: 'Outrageous Orange', pantone: '171 C' },
	{ color: 'rgb(250, 70, 22)', name: 'Pomegranate', pantone: '172 C' },
	{ color: 'rgb(207, 69, 32)', name: 'Orange Roughy', pantone: '173 C' },
	{ color: 'rgb(150, 56, 33)', name: 'Prairie Sand', pantone: '174 C' },
	{ color: 'rgb(107, 53, 41)', name: 'Irish Coffee', pantone: '175 C' },
	{ color: 'rgb(255, 163, 181)', name: 'Sundown', pantone: '1765 C' },
	{ color: 'rgb(252, 175, 192)', name: 'Lavender Pink', pantone: '1767 C' },
	{ color: 'rgb(255, 177, 187)', name: 'Sundown', pantone: '176 C' },
	{ color: 'rgb(255, 141, 161)', name: 'Pink Salmon', pantone: '1775 C' },
	{ color: 'rgb(251, 99, 126)', name: 'Brink Pink', pantone: '1777 C' },
	{ color: 'rgb(255, 128, 139)', name: 'Geraldine', pantone: '177 C' },
	{ color: 'rgb(248, 72, 94)', name: 'Carnation', pantone: '1785 C' },
	{ color: 'rgb(244, 54, 76)', name: 'Radical Red', pantone: '1787 C' },
	{ color: 'rgb(238, 39, 55)', name: 'Alizarin Crimson', pantone: '1788 C' },
	{ color: 'rgb(255, 88, 93)', name: 'Persimmon', pantone: '178 C' },
	{ color: 'rgb(210, 38, 48)', name: 'Cardinal', pantone: '1795 C' },
	{ color: 'rgb(203, 51, 59)', name: 'Flush Mahogany', pantone: '1797 C' },
	{ color: 'rgb(224, 60, 49)', name: 'Cinnabar', pantone: '179 C' },
	{ color: 'rgb(175, 39, 47)', name: 'Tall Poppy', pantone: '1805 C' },
	{ color: 'rgb(164, 52, 58)', name: 'Stiletto', pantone: '1807 C' },
	{ color: 'rgb(190, 58, 52)', name: 'Well Read', pantone: '180 C' },
	{ color: 'rgb(124, 37, 41)', name: 'Crown of Thorns', pantone: '1815 C' },
	{ color: 'rgb(100, 51, 53)', name: 'Buccaneer', pantone: '1817 C' },
	{ color: 'rgb(129, 49, 47)', name: 'El Salva', pantone: '181 C' },
	{ color: 'rgb(250, 187, 203)', name: 'Cupid', pantone: '182 C' },
	{ color: 'rgb(252, 155, 179)', name: 'Sweet Pink', pantone: '183 C' },
	{ color: 'rgb(246, 82, 117)', name: 'French Rose', pantone: '184 C' },
	{ color: 'rgb(228, 0, 43)', name: 'Monza', pantone: '185 C' },
	{ color: 'rgb(200, 16, 46)', name: 'Rot', pantone: '186 C' },
	{ color: 'rgb(166, 25, 46)', name: 'Tamarillo', pantone: '187 C' },
	{ color: 'rgb(118, 35, 47)', name: 'Crown of Thorns', pantone: '188 C' },
	{ color: 'rgb(245, 182, 205)', name: 'Azalea', pantone: '1895 C' },
	{ color: 'rgb(248, 163, 188)', name: 'Illusion', pantone: '189 C' },
	{ color: 'rgb(245, 155, 187)', name: 'Illusion', pantone: '1905 C' },
	{ color: 'rgb(246, 117, 153)', name: 'Froly', pantone: '190 C' },
	{ color: 'rgb(239, 74, 129)', name: 'French Rose', pantone: '1915 C' },
	{ color: 'rgb(239, 66, 111)', name: 'French Rose', pantone: '191 C' },
	{ color: 'rgb(224, 0, 77)', name: 'Razzmatazz', pantone: '1925 C' },
	{ color: 'rgb(228, 0, 70)', name: 'Red Ribbon', pantone: '192 C' },
	{ color: 'rgb(197, 0, 62)', name: 'Monza', pantone: '1935 C' },
	{ color: 'rgb(191, 13, 62)', name: 'Shiraz', pantone: '193 C' },
	{ color: 'rgb(166, 9, 61)', name: 'Shiraz', pantone: '1945 C' },
	{ color: 'rgb(155, 39, 67)', name: 'Mexican Red', pantone: '194 C' },
	{ color: 'rgb(138, 21, 56)', name: 'Claret', pantone: '1955 C' },
	{ color: 'rgb(120, 47, 64)', name: 'Solid Pink', pantone: '195 C' },
	{ color: 'rgb(236, 199, 205)', name: 'Vanilla Ice', pantone: '196 C' },
	{ color: 'rgb(232, 156, 174)', name: 'Kobi', pantone: '197 C' },
	{ color: 'rgb(223, 70, 97)', name: 'Mandy', pantone: '198 C' },
	{ color: 'rgb(213, 0, 50)', name: 'Monza', pantone: '199 C' },
	{ color: 'rgb(186, 12, 47)', name: 'Shiraz', pantone: '200 C' },
	{ color: 'rgb(157, 34, 53)', name: 'Mexican Red', pantone: '201 C' },
	{ color: 'rgb(134, 38, 51)', name: 'Burnt Umber', pantone: '202 C' },
	{ color: 'rgb(236, 179, 203)', name: 'Kobi', pantone: '203 C' },
	{ color: 'rgb(231, 130, 169)', name: 'Carissma', pantone: '204 C' },
	{ color: 'rgb(224, 69, 123)', name: 'Cranberry', pantone: '205 C' },
	{ color: 'rgb(206, 0, 55)', name: 'Monza', pantone: '206 C' },
	{ color: 'rgb(165, 0, 52)', name: 'Burgundy', pantone: '207 C' },
	{ color: 'rgb(134, 31, 65)', name: 'Claret', pantone: '208 C' },
	{ color: 'rgb(111, 38, 61)', name: 'Tawny Port', pantone: '209 C' },
	{ color: 'rgb(249, 159, 201)', name: 'Lavender Pink', pantone: '210 C' },
	{ color: 'rgb(245, 126, 182)', name: 'Pink', pantone: '211 C' },
	{ color: 'rgb(240, 78, 152)', name: 'Brilliant Rose', pantone: '212 C' },
	{ color: 'rgb(227, 28, 121)', name: 'Cerise Red', pantone: '213 C' },
	{ color: 'rgb(206, 15, 105)', name: 'Razzmatazz', pantone: '214 C' },
	{ color: 'rgb(172, 20, 90)', name: 'Jazzberry Jam', pantone: '215 C' },
	{ color: 'rgb(125, 34, 72)', name: 'Tawny Port', pantone: '216 C' },
	{ color: 'rgb(234, 190, 219)', name: 'Melanie', pantone: '217 C' },
	{ color: 'rgb(229, 109, 177)', name: 'Deep Blush', pantone: '218 C' },
	{ color: 'rgb(218, 24, 132)', name: 'Red Violet', pantone: '219 C' },
	{ color: 'rgb(165, 0, 80)', name: 'Fresh Eggplant', pantone: '220 C' },
	{ color: 'rgb(145, 0, 72)', name: 'Siren', pantone: '221 C' },
	{ color: 'rgb(108, 29, 69)', name: 'Rubinrot', pantone: '222 C' },
	{ color: 'rgb(239, 149, 207)', name: 'Illusion', pantone: '223 C' },
	{ color: 'rgb(235, 111, 189)', name: 'Carissma', pantone: '224 C' },
	{ color: 'rgb(223, 25, 149)', name: 'Red Violet', pantone: '225 C' },
	{ color: 'rgb(208, 0, 111)', name: 'Razzmatazz', pantone: '226 C' },
	{ color: 'rgb(170, 0, 97)', name: 'Flirt', pantone: '227 C' },
	{ color: 'rgb(137, 12, 88)', name: 'Rose Bud Cherry', pantone: '228 C' },
	{ color: 'rgb(103, 33, 70)', name: 'Tawny Port', pantone: '229 C' },
	{ color: 'rgb(244, 166, 215)', name: 'Illusion', pantone: '230 C' },
	{ color: 'rgb(242, 119, 198)', name: 'Persian Pink', pantone: '231 C' },
	{ color: 'rgb(233, 60, 172)', name: 'Brilliant Rose', pantone: '232 C' },
	{ color: 'rgb(198, 0, 126)', name: 'Lipstick', pantone: '233 C' },
	{ color: 'rgb(162, 0, 103)', name: 'Flirt', pantone: '234 C' },
	{ color: 'rgb(132, 11, 85)', name: 'Rose Bud Cherry', pantone: '235 C' },
	{ color: 'rgb(239, 186, 225)', name: 'Kobi', pantone: '2365 C' },
	{ color: 'rgb(241, 167, 220)', name: 'Illusion', pantone: '236 C' },
	{ color: 'rgb(226, 119, 205)', name: 'Orchid', pantone: '2375 C' },
	{ color: 'rgb(236, 134, 208)', name: 'Lavender Magenta', pantone: '237 C' },
	{ color: 'rgb(213, 57, 181)', name: 'Cerise', pantone: '2385 C' },
	{ color: 'rgb(228, 93, 191)', name: 'Orchid', pantone: '238 C' },
	{ color: 'rgb(200, 0, 161)', name: 'Hollywood Cerise', pantone: '2395 C' },
	{ color: 'rgb(219, 62, 177)', name: 'Cerise', pantone: '239 C' },
	{ color: 'rgb(176, 0, 142)', name: 'Flirt', pantone: '2405 C' },
	{ color: 'rgb(197, 41, 155)', name: 'Medium Red Violet', pantone: '240 C' },
	{ color: 'rgb(158, 0, 126)', name: 'Flirt', pantone: '2415 C' },
	{ color: 'rgb(175, 22, 133)', name: 'Red Violet', pantone: '241 C' },
	{ color: 'rgb(131, 0, 101)', name: 'Fresh Eggplant', pantone: '2425 C' },
	{ color: 'rgb(128, 34, 95)', name: 'Tawny Port', pantone: '242 C' },
	{ color: 'rgb(234, 184, 228)', name: 'Light Orchid', pantone: '243 C' },
	{ color: 'rgb(229, 155, 220)', name: 'Light Orchid', pantone: '244 C' },
	{ color: 'rgb(221, 127, 211)', name: 'Orchid', pantone: '245 C' },
	{ color: 'rgb(199, 36, 177)', name: 'Cerise', pantone: '246 C' },
	{ color: 'rgb(187, 22, 163)', name: 'Red Violet', pantone: '247 C' },
	{ color: 'rgb(165, 24, 144)', name: 'Violet Eggplant', pantone: '248 C' },
	{ color: 'rgb(128, 39, 108)', name: 'Plum', pantone: '249 C' },
	{ color: 'rgb(231, 186, 228)', name: 'Melanie', pantone: '250 C' },
	{ color: 'rgb(221, 156, 223)', name: 'Light Wisteria', pantone: '251 C' },
	{ color: 'rgb(201, 100, 207)', name: 'Lavender', pantone: '252 C' },
	{ color: 'rgb(173, 26, 172)', name: 'Violet Eggplant', pantone: '253 C' },
	{ color: 'rgb(152, 29, 151)', name: 'Violet Eggplant', pantone: '254 C' },
	{ color: 'rgb(114, 36, 108)', name: 'Plum', pantone: '255 C' },
	{ color: 'rgb(215, 169, 227)', name: 'Light Wisteria', pantone: '2562 C' },
	{ color: 'rgb(203, 163, 216)', name: 'Light Wisteria', pantone: '2563 C' },
	{ color: 'rgb(193, 160, 218)', name: 'Light Wisteria', pantone: '2567 C' },
	{ color: 'rgb(214, 191, 221)', name: 'Prelude', pantone: '256 C' },
	{ color: 'rgb(201, 139, 219)', name: 'Lavender', pantone: '2572 C' },
	{ color: 'rgb(184, 132, 203)', name: 'East Side', pantone: '2573 C' },
	{ color: 'rgb(167, 123, 202)', name: 'East Side', pantone: '2577 C' },
	{ color: 'rgb(198, 161, 207)', name: 'East Side', pantone: '257 C' },
	{ color: 'rgb(172, 79, 198)', name: 'Amethyst', pantone: '2582 C' },
	{ color: 'rgb(160, 94, 181)', name: 'Wisteria', pantone: '2583 C' },
	{ color: 'rgb(130, 70, 175)', name: 'Studio', pantone: '2587 C' },
	{ color: 'rgb(140, 71, 153)', name: 'Affair', pantone: '258 C' },
	{ color: 'rgb(155, 38, 182)', name: 'Purple Heart', pantone: '2592 C' },
	{ color: 'rgb(132, 50, 155)', name: 'Vivid Violet', pantone: '2593 C' },
	{ color: 'rgb(92, 6, 140)', name: 'Purple', pantone: '2597 C' },
	{ color: 'rgb(109, 32, 119)', name: 'Honey Flower', pantone: '259 C' },
	{ color: 'rgb(135, 24, 157)', name: 'Seance', pantone: '2602 C' },
	{ color: 'rgb(112, 32, 130)', name: 'Seance', pantone: '2603 C' },
	{ color: 'rgb(80, 7, 120)', name: 'Windsor', pantone: '2607 C' },
	{ color: 'rgb(100, 38, 103)', name: 'Eminence', pantone: '260 C' },
	{ color: 'rgb(119, 37, 131)', name: 'Seance', pantone: '2612 C' },
	{ color: 'rgb(103, 30, 117)', name: 'Honey Flower', pantone: '2613 C' },
	{ color: 'rgb(71, 10, 104)', name: 'Dunkelviolett', pantone: '2617 C' },
	{ color: 'rgb(93, 40, 95)', name: 'Bossanova', pantone: '261 C' },
	{ color: 'rgb(101, 49, 101)', name: 'Finn', pantone: '2622 C' },
	{ color: 'rgb(95, 33, 103)', name: 'Honey Flower', pantone: '2623 C' },
	{ color: 'rgb(60, 16, 83)', name: 'Jagger', pantone: '2627 C' },
	{ color: 'rgb(81, 40, 79)', name: 'Finn', pantone: '262 C' },
	{ color: 'rgb(197, 180, 227)', name: 'Prelude', pantone: '2635 C' },
	{ color: 'rgb(215, 198, 230)', name: 'Prelude', pantone: '263 C' },
	{ color: 'rgb(173, 150, 220)', name: 'Cold Purple', pantone: '2645 C' },
	{ color: 'rgb(193, 167, 226)', name: 'Light Wisteria', pantone: '264 C' },
	{ color: 'rgb(150, 120, 211)', name: 'Lilac Bush', pantone: '2655 C' },
	{ color: 'rgb(144, 99, 205)', name: 'Amethyst', pantone: '265 C' },
	{ color: 'rgb(125, 85, 199)', name: 'Fuchsia Blue', pantone: '2665 C' },
	{ color: 'rgb(117, 59, 189)', name: 'Purple Heart', pantone: '266 C' },
	{ color: 'rgb(95, 37, 159)', name: 'Daisy Bush', pantone: '267 C' },
	{ color: 'rgb(51, 0, 114)', name: 'Christalle', pantone: '2685 C' },
	{ color: 'rgb(88, 44, 131)', name: 'Eminence', pantone: '268 C' },
	{ color: 'rgb(46, 26, 71)', name: 'Valhalla', pantone: '2695 C' },
	{ color: 'rgb(81, 45, 109)', name: 'Jacarta', pantone: '269 C' },
	{ color: 'rgb(167, 164, 224)', name: 'Cold Purple', pantone: '2705 C' },
	{ color: 'rgb(203, 211, 235)', name: 'Periwinkle Gray', pantone: '2706 C' },
	{ color: 'rgb(195, 215, 238)', name: 'Spindle', pantone: '2707 C' },
	{ color: 'rgb(184, 204, 234)', name: 'Spindle', pantone: '2708 C' },
	{ color: 'rgb(180, 181, 223)', name: 'Pigeon Post', pantone: '270 C' },
	{ color: 'rgb(139, 132, 215)', name: 'Chetwode Blue', pantone: '2715 C' },
	{ color: 'rgb(159, 174, 229)', name: 'Dull Lavender', pantone: '2716 C' },
	{ color: 'rgb(167, 198, 237)', name: 'Cornflower', pantone: '2717 C' },
	{ color: 'rgb(92, 136, 218)', name: 'Havelock Blue', pantone: '2718 C' },
	{ color: 'rgb(149, 149, 210)', name: 'Blue Bell', pantone: '271 C' },
	{ color: 'rgb(104, 91, 199)', name: 'Blue Marguerite', pantone: '2725 C' },
	{ color: 'rgb(72, 92, 199)', name: 'Indigo', pantone: '2726 C' },
	{ color: 'rgb(48, 127, 226)', name: 'Royal Blue', pantone: '2727 C' },
	{ color: 'rgb(0, 71, 187)', name: 'Cobalt', pantone: '2728 C' },
	{ color: 'rgb(116, 116, 193)', name: 'Blue Marguerite', pantone: '272 C' },
	{ color: 'rgb(46, 0, 139)', name: 'Kingfisher Daisy', pantone: '2735 C' },
	{ color: 'rgb(30, 34, 170)', name: 'Persian Blue', pantone: '2736 C' },
	{ color: 'rgb(6, 3, 141)', name: 'Blau', pantone: '2738 C' },
	{ color: 'rgb(36, 19, 95)', name: 'Lucky Point', pantone: '273 C' },
	{ color: 'rgb(40, 0, 113)', name: 'Paua', pantone: '2745 C' },
	{ color: 'rgb(23, 28, 143)', name: 'Deep Koamaru', pantone: '2746 C' },
	{ color: 'rgb(0, 26, 114)', name: 'Resolution Blue', pantone: '2747 C' },
	{ color: 'rgb(0, 24, 113)', name: 'Resolution Blue', pantone: '2748 C' },
	{ color: 'rgb(33, 21, 81)', name: 'Valhalla', pantone: '274 C' },
	{ color: 'rgb(37, 14, 98)', name: 'Violent Violet', pantone: '2755 C' },
	{ color: 'rgb(21, 31, 109)', name: 'Lucky Point', pantone: '2756 C' },
	{ color: 'rgb(0, 30, 96)', name: 'Midnight Blue', pantone: '2757 C' },
	{ color: 'rgb(0, 30, 98)', name: 'Midnight Blue', pantone: '2758 C' },
	{ color: 'rgb(32, 23, 71)', name: 'Valhalla', pantone: '275 C' },
	{ color: 'rgb(32, 21, 71)', name: 'Valhalla', pantone: '2765 C' },
	{ color: 'rgb(20, 27, 77)', name: 'Bunting', pantone: '2766 C' },
	{ color: 'rgb(19, 41, 75)', name: 'Blue Zodiac', pantone: '2767 C' },
	{ color: 'rgb(7, 29, 73)', name: 'Downriver', pantone: '2768 C' },
	{ color: 'rgb(34, 28, 53)', name: 'Mirage', pantone: '276 C' },
	{ color: 'rgb(171, 202, 233)', name: 'Spindle', pantone: '277 C' },
	{ color: 'rgb(139, 184, 232)', name: 'Cornflower', pantone: '278 C' },
	{ color: 'rgb(65, 143, 222)', name: 'Havelock Blue', pantone: '279 C' },
	{ color: 'rgb(1, 33, 105)', name: 'Midnight Blue', pantone: '280 C' },
	{ color: 'rgb(0, 32, 91)', name: 'Prussian Blue', pantone: '281 C' },
	{ color: 'rgb(4, 30, 66)', name: 'Tangaroa', pantone: '282 C' },
	{ color: 'rgb(146, 193, 233)', name: 'Cornflower', pantone: '283 C' },
	{ color: 'rgb(108, 172, 228)', name: 'Hellblau', pantone: '284 C' },
	{ color: 'rgb(0, 114, 206)', name: 'Science Blue', pantone: '285 C' },
	{ color: 'rgb(0, 51, 160)', name: 'Smalt', pantone: '286 C' },
	{ color: 'rgb(0, 48, 135)', name: 'Resolution Blue', pantone: '287 C' },
	{ color: 'rgb(0, 45, 114)', name: 'Midnight Blue', pantone: '288 C' },
	{ color: 'rgb(12, 35, 64)', name: 'Blue Zodiac', pantone: '289 C' },
	{ color: 'rgb(141, 200, 232)', name: 'Cornflower', pantone: '2905 C' },
	{ color: 'rgb(185, 217, 235)', name: 'Spindle', pantone: '290 C' },
	{ color: 'rgb(98, 181, 229)', name: 'Picton Blue', pantone: '2915 C' },
	{ color: 'rgb(155, 203, 235)', name: 'Cornflower', pantone: '291 C' },
	{ color: 'rgb(0, 156, 222)', name: 'Cerulean', pantone: '2925 C' },
	{ color: 'rgb(105, 179, 231)', name: 'Seagull', pantone: '292 C' },
	{ color: 'rgb(0, 87, 184)', name: 'Endeavour', pantone: '2935 C' },
	{ color: 'rgb(0, 61, 165)', name: 'Cobalt', pantone: '293 C' },
	{ color: 'rgb(0, 76, 151)', name: 'Congress Blue', pantone: '2945 C' },
	{ color: 'rgb(0, 47, 108)', name: 'Midnight Blue', pantone: '294 C' },
	{ color: 'rgb(0, 56, 101)', name: 'Midnight Blue', pantone: '2955 C' },
	{ color: 'rgb(0, 40, 85)', name: 'Prussian Blue', pantone: '295 C' },
	{ color: 'rgb(0, 38, 62)', name: 'Daintree', pantone: '2965 C' },
	{ color: 'rgb(4, 28, 44)', name: 'Black Pearl', pantone: '296 C' },
	{ color: 'rgb(153, 214, 234)', name: 'Cornflower', pantone: '2975 C' },
	{ color: 'rgb(113, 197, 232)', name: 'Seagull', pantone: '297 C' },
	{ color: 'rgb(91, 194, 231)', name: 'Turquoise Blue', pantone: '2985 C' },
	{ color: 'rgb(65, 182, 230)', name: 'Türkis', pantone: '298 C' },
	{ color: 'rgb(0, 169, 224)', name: 'Cerulean', pantone: '2995 C' },
	{ color: 'rgb(0, 163, 224)', name: 'Cerulean', pantone: '299 C' },
	{ color: 'rgb(0, 119, 200)', name: 'Lochmara', pantone: '3005 C' },
	{ color: 'rgb(0, 94, 184)', name: 'Endeavour', pantone: '300 C' },
	{ color: 'rgb(0, 98, 155)', name: 'Bahama Blue', pantone: '3015 C' },
	{ color: 'rgb(0, 75, 135)', name: 'Congress Blue', pantone: '301 C' },
	{ color: 'rgb(0, 79, 113)', name: 'Regal Blue', pantone: '3025 C' },
	{ color: 'rgb(0, 59, 92)', name: 'Astronaut Blue', pantone: '302 C' },
	{ color: 'rgb(0, 62, 81)', name: 'Sherpa Blue', pantone: '3035 C' },
	{ color: 'rgb(0, 42, 58)', name: 'Daintree', pantone: '303 C' },
	{ color: 'rgb(154, 219, 232)', name: 'Blizzard Blue', pantone: '304 C' },
	{ color: 'rgb(89, 203, 232)', name: 'Turquoise Blue', pantone: '305 C' },
	{ color: 'rgb(0, 181, 226)', name: 'Cerulean', pantone: '306 C' },
	{ color: 'rgb(0, 107, 166)', name: 'Allports', pantone: '307 C' },
	{ color: 'rgb(0, 88, 124)', name: 'Orient', pantone: '308 C' },
	{ color: 'rgb(0, 59, 73)', name: 'Cyprus', pantone: '309 C' },
	{ color: 'rgb(104, 210, 223)', name: 'Viking', pantone: '3105 C' },
	{ color: 'rgb(106, 209, 227)', name: 'Aquamarine Blue', pantone: '310 C' },
	{ color: 'rgb(0, 193, 213)', name: "Robin's Egg Blue", pantone: '3115 C' },
	{ color: 'rgb(5, 195, 222)', name: "Robin's Egg Blue", pantone: '311 C' },
	{ color: 'rgb(0, 174, 199)', name: 'Pacific Blue', pantone: '3125 C' },
	{ color: 'rgb(0, 169, 206)', name: 'Cerulean', pantone: '312 C' },
	{ color: 'rgb(0, 142, 170)', name: 'Bondi Blue', pantone: '3135 C' },
	{ color: 'rgb(0, 146, 188)', name: 'Bondi Blue', pantone: '313 C' },
	{ color: 'rgb(0, 119, 139)', name: 'Blue Lagoon', pantone: '3145 C' },
	{ color: 'rgb(0, 127, 163)', name: 'Deep Cerulean', pantone: '314 C' },
	{ color: 'rgb(0, 98, 114)', name: 'Mosque', pantone: '3155 C' },
	{ color: 'rgb(0, 103, 127)', name: 'Orient', pantone: '315 C' },
	{ color: 'rgb(0, 79, 89)', name: 'Sherpa Blue', pantone: '3165 C' },
	{ color: 'rgb(0, 72, 81)', name: 'Sherpa Blue', pantone: '316 C' },
	{ color: 'rgb(177, 228, 227)', name: 'Powder Blue', pantone: '317 C' },
	{ color: 'rgb(136, 219, 223)', name: 'Riptide', pantone: '318 C' },
	{ color: 'rgb(45, 204, 211)', name: 'Scooter', pantone: '319 C' },
	{ color: 'rgb(0, 156, 166)', name: 'Bondi Blue', pantone: '320 C' },
	{ color: 'rgb(0, 140, 149)', name: 'Blue Lagoon', pantone: '321 C' },
	{ color: 'rgb(0, 115, 119)', name: 'Pine Green', pantone: '322 C' },
	{ color: 'rgb(0, 95, 97)', name: 'Blue Stone', pantone: '323 C' },
	{ color: 'rgb(113, 219, 212)', name: 'Viking', pantone: '3242 C' },
	{ color: 'rgb(124, 224, 211)', name: 'Riptide', pantone: '3245 C' },
	{ color: 'rgb(109, 205, 184)', name: 'Downy', pantone: '3248 C' },
	{ color: 'rgb(156, 219, 217)', name: 'Aqua Island', pantone: '324 C' },
	{ color: 'rgb(42, 210, 201)', name: 'Turquoise', pantone: '3252 C' },
	{ color: 'rgb(44, 213, 196)', name: 'Turquoise', pantone: '3255 C' },
	{ color: 'rgb(73, 197, 177)', name: 'Puerto Rico', pantone: '3258 C' },
	{ color: 'rgb(100, 204, 201)', name: 'Downy', pantone: '325 C' },
	{ color: 'rgb(0, 191, 179)', name: "Robin's Egg Blue", pantone: '3262 C' },
	{ color: 'rgb(0, 199, 177)', name: 'Caribbean Green', pantone: '3265 C' },
	{ color: 'rgb(0, 171, 142)', name: 'Persian Green', pantone: '3268 C' },
	{ color: 'rgb(0, 178, 169)', name: 'Persian Green', pantone: '326 C' },
	{ color: 'rgb(0, 164, 153)', name: 'Persian Green', pantone: '3272 C' },
	{ color: 'rgb(0, 179, 152)', name: 'Persian Green', pantone: '3275 C' },
	{ color: 'rgb(0, 155, 119)', name: 'Green Haze', pantone: '3278 C' },
	{ color: 'rgb(0, 134, 117)', name: 'Deep Sea', pantone: '327 C' },
	{ color: 'rgb(0, 133, 120)', name: 'Teal', pantone: '3282 C' },
	{ color: 'rgb(0, 150, 129)', name: 'Persian Green', pantone: '3285 C' },
	{ color: 'rgb(0, 130, 100)', name: 'Deep Sea', pantone: '3288 C' },
	{ color: 'rgb(0, 115, 103)', name: 'Tropical Rain Forest', pantone: '328 C' },
	{ color: 'rgb(0, 89, 79)', name: 'Sherpa Blue', pantone: '3292 C' },
	{ color: 'rgb(0, 120, 100)', name: 'Tropical Rain Forest', pantone: '3295 C' },
	{ color: 'rgb(0, 106, 82)', name: 'Tropical Rain Forest', pantone: '3298 C' },
	{ color: 'rgb(0, 104, 94)', name: 'Blue Stone', pantone: '329 C' },
	{ color: 'rgb(0, 76, 69)', name: 'Aqua Deep', pantone: '3302 C' },
	{ color: 'rgb(0, 78, 66)', name: 'Aqua Deep', pantone: '3305 C' },
	{ color: 'rgb(3, 70, 56)', name: 'Sherwood Green', pantone: '3308 C' },
	{ color: 'rgb(0, 83, 76)', name: 'Sherpa Blue', pantone: '330 C' },
	{ color: 'rgb(167, 230, 215)', name: 'Cruise', pantone: '331 C' },
	{ color: 'rgb(140, 226, 208)', name: 'Riptide', pantone: '332 C' },
	{ color: 'rgb(60, 219, 192)', name: 'Turquoise', pantone: '333 C' },
	{ color: 'rgb(0, 151, 117)', name: 'Green Haze', pantone: '334 C' },
	{ color: 'rgb(0, 123, 95)', name: 'Tropical Rain Forest', pantone: '335 C' },
	{ color: 'rgb(0, 102, 79)', name: 'Tropical Rain Forest', pantone: '336 C' },
	{ color: 'rgb(122, 225, 191)', name: 'Riptide', pantone: '3375 C' },
	{ color: 'rgb(143, 214, 189)', name: 'Vista Blue', pantone: '337 C' },
	{ color: 'rgb(71, 215, 172)', name: 'Shamrock', pantone: '3385 C' },
	{ color: 'rgb(110, 206, 178)', name: 'Downy', pantone: '338 C' },
	{ color: 'rgb(0, 195, 137)', name: 'Caribbean Green', pantone: '3395 C' },
	{ color: 'rgb(0, 179, 136)', name: 'Persian Green', pantone: '339 C' },
	{ color: 'rgb(0, 175, 102)', name: 'Jade', pantone: '3405 C' },
	{ color: 'rgb(0, 150, 94)', name: 'Green Haze', pantone: '340 C' },
	{ color: 'rgb(0, 119, 73)', name: 'Fun Green', pantone: '3415 C' },
	{ color: 'rgb(0, 122, 83)', name: 'Tropical Rain Forest', pantone: '341 C' },
	{ color: 'rgb(0, 99, 65)', name: 'Grün', pantone: '3425 C' },
	{ color: 'rgb(0, 103, 71)', name: 'Fun Green', pantone: '342 C' },
	{ color: 'rgb(21, 71, 52)', name: 'Green Pea', pantone: '3435 C' },
	{ color: 'rgb(17, 87, 64)', name: 'Eden', pantone: '343 C' },
	{ color: 'rgb(160, 218, 179)', name: 'Celadon', pantone: '344 C' },
	{ color: 'rgb(145, 214, 172)', name: 'Vista Blue', pantone: '345 C' },
	{ color: 'rgb(113, 204, 152)', name: 'De York', pantone: '346 C' },
	{ color: 'rgb(0, 154, 68)', name: 'Green Haze', pantone: '347 C' },
	{ color: 'rgb(0, 132, 61)', name: 'Fun Green', pantone: '348 C' },
	{ color: 'rgb(4, 106, 56)', name: 'Fun Green', pantone: '349 C' },
	{ color: 'rgb(44, 82, 52)', name: 'Killarney', pantone: '350 C' },
	{ color: 'rgb(162, 228, 184)', name: 'Chinook', pantone: '351 C' },
	{ color: 'rgb(143, 226, 176)', name: 'Algae Green', pantone: '352 C' },
	{ color: 'rgb(128, 224, 167)', name: 'Algae Green', pantone: '353 C' },
	{ color: 'rgb(0, 177, 64)', name: 'Green Haze', pantone: '354 C' },
	{ color: 'rgb(0, 150, 57)', name: 'Fun Green', pantone: '355 C' },
	{ color: 'rgb(0, 122, 51)', name: 'Fun Green', pantone: '356 C' },
	{ color: 'rgb(33, 87, 50)', name: 'Everglade', pantone: '357 C' },
	{ color: 'rgb(173, 220, 145)', name: 'Feijoa', pantone: '358 C' },
	{ color: 'rgb(161, 216, 132)', name: 'Feijoa', pantone: '359 C' },
	{ color: 'rgb(108, 194, 74)', name: 'Mantis', pantone: '360 C' },
	{ color: 'rgb(67, 176, 42)', name: 'Apple', pantone: '361 C' },
	{ color: 'rgb(80, 158, 47)', name: 'Apple', pantone: '362 C' },
	{ color: 'rgb(76, 140, 43)', name: 'Olive Drab', pantone: '363 C' },
	{ color: 'rgb(74, 119, 41)', name: 'Fern Frond', pantone: '364 C' },
	{ color: 'rgb(194, 225, 137)', name: 'Yellow Green', pantone: '365 C' },
	{ color: 'rgb(183, 221, 121)', name: 'Yellow Green', pantone: '366 C' },
	{ color: 'rgb(164, 214, 94)', name: 'Apfelgrün', pantone: '367 C' },
	{ color: 'rgb(120, 190, 32)', name: 'Lima', pantone: '368 C' },
	{ color: 'rgb(100, 167, 11)', name: 'Christi', pantone: '369 C' },
	{ color: 'rgb(101, 141, 27)', name: 'Vida Loca', pantone: '370 C' },
	{ color: 'rgb(84, 98, 35)', name: 'Fern Frond', pantone: '371 C' },
	{ color: 'rgb(212, 235, 142)', name: 'Primrose', pantone: '372 C' },
	{ color: 'rgb(205, 234, 128)', name: 'Sulu', pantone: '373 C' },
	{ color: 'rgb(197, 232, 108)', name: 'Sulu', pantone: '374 C' },
	{ color: 'rgb(151, 215, 0)', name: 'Pistachio', pantone: '375 C' },
	{ color: 'rgb(132, 189, 0)', name: 'Pistachio', pantone: '376 C' },
	{ color: 'rgb(122, 154, 1)', name: 'Limeade', pantone: '377 C' },
	{ color: 'rgb(89, 98, 29)', name: 'Fern Frond', pantone: '378 C' },
	{ color: 'rgb(226, 232, 104)', name: 'Confetti', pantone: '379 C' },
	{ color: 'rgb(219, 228, 66)', name: 'Pear', pantone: '380 C' },
	{ color: 'rgb(206, 220, 0)', name: 'Rio Grande', pantone: '381 C' },
	{ color: 'rgb(196, 214, 0)', name: 'Rio Grande', pantone: '382 C' },
	{ color: 'rgb(168, 173, 0)', name: 'Buddha Gold', pantone: '383 C' },
	{ color: 'rgb(148, 147, 0)', name: 'Olive', pantone: '384 C' },
	{ color: 'rgb(120, 113, 33)', name: 'Pacifika', pantone: '385 C' },
	{ color: 'rgb(233, 236, 107)', name: 'Manz', pantone: '386 C' },
	{ color: 'rgb(227, 233, 53)', name: 'Pear', pantone: '387 C' },
	{ color: 'rgb(224, 231, 33)', name: 'Sunflower', pantone: '388 C' },
	{ color: 'rgb(208, 223, 0)', name: 'Electric Lime', pantone: '389 C' },
	{ color: 'rgb(181, 189, 0)', name: 'Buddha Gold', pantone: '390 C' },
	{ color: 'rgb(154, 149, 0)', name: 'Olive', pantone: '391 C' },
	{ color: 'rgb(130, 122, 4)', name: 'Olive', pantone: '392 C' },
	{ color: 'rgb(243, 234, 93)', name: 'Energy Yellow', pantone: '3935 C' },
	{ color: 'rgb(240, 236, 116)', name: 'Manz', pantone: '393 C' },
	{ color: 'rgb(243, 229, 0)', name: 'Turbo', pantone: '3945 C' },
	{ color: 'rgb(237, 233, 57)', name: 'Starship', pantone: '394 C' },
	{ color: 'rgb(239, 223, 0)', name: 'Turbo', pantone: '3955 C' },
	{ color: 'rgb(236, 232, 26)', name: 'Barberry', pantone: '395 C' },
	{ color: 'rgb(238, 220, 0)', name: 'Turbo', pantone: '3965 C' },
	{ color: 'rgb(225, 224, 0)', name: 'Turbo', pantone: '396 C' },
	{ color: 'rgb(187, 166, 0)', name: 'Buddha Gold', pantone: '3975 C' },
	{ color: 'rgb(191, 184, 0)', name: 'Buddha Gold', pantone: '397 C' },
	{ color: 'rgb(154, 135, 0)', name: 'Olive', pantone: '3985 C' },
	{ color: 'rgb(173, 164, 0)', name: 'Buddha Gold', pantone: '398 C' },
	{ color: 'rgb(104, 92, 32)', name: 'Himalaya', pantone: '3995 C' },
	{ color: 'rgb(160, 146, 0)', name: 'Pirate Gold', pantone: '399 C' },
	{ color: 'rgb(196, 191, 182)', name: 'Cotton Seed', pantone: '400 C' },
	{ color: 'rgb(175, 169, 160)', name: 'Cloudy', pantone: '401 C' },
	{ color: 'rgb(157, 150, 141)', name: 'Zorba', pantone: '402 C' },
	{ color: 'rgb(140, 133, 123)', name: 'Natural Gray', pantone: '403 C' },
	{ color: 'rgb(119, 110, 100)', name: 'Sandstone', pantone: '404 C' },
	{ color: 'rgb(105, 97, 88)', name: 'Pine Cone', pantone: '405 C' },
	{ color: 'rgb(196, 188, 183)', name: 'Silver Rust', pantone: '406 C' },
	{ color: 'rgb(178, 168, 162)', name: 'Cloudy', pantone: '407 C' },
	{ color: 'rgb(151, 140, 135)', name: 'Schooner', pantone: '408 C' },
	{ color: 'rgb(133, 120, 116)', name: 'Americano', pantone: '409 C' },
	{ color: 'rgb(116, 102, 97)', name: 'Sandstone', pantone: '410 C' },
	{ color: 'rgb(94, 81, 77)', name: 'Dorado', pantone: '411 C' },
	{ color: 'rgb(56, 47, 45)', name: 'Dune', pantone: '412 C' },
	{ color: 'rgb(186, 187, 177)', name: 'Mist Gray', pantone: '413 C' },
	{ color: 'rgb(168, 169, 158)', name: 'Delta', pantone: '414 C' },
	{ color: 'rgb(145, 147, 136)', name: 'Lemon Grass', pantone: '415 C' },
	{ color: 'rgb(126, 127, 116)', name: 'Bandicoot', pantone: '416 C' },
	{ color: 'rgb(101, 102, 92)', name: 'Storm Dust', pantone: '417 C' },
	{ color: 'rgb(81, 83, 74)', name: 'Fuscous Gray', pantone: '418 C' },
	{ color: 'rgb(199, 201, 199)', name: 'Pumice', pantone: '420 C' },
	{ color: 'rgb(178, 180, 178)', name: 'Pumice', pantone: '421 C' },
	{ color: 'rgb(158, 162, 162)', name: 'Edward', pantone: '422 C' },
	{ color: 'rgb(137, 141, 141)', name: 'Gunsmoke', pantone: '423 C' },
	{ color: 'rgb(112, 115, 114)', name: 'Corduroy', pantone: '424 C' },
	{ color: 'rgb(84, 88, 90)', name: 'Abbey', pantone: '425 C' },
	{ color: 'rgb(37, 40, 42)', name: 'Shark', pantone: '426 C' },
	{ color: 'rgb(208, 211, 212)', name: 'Iron', pantone: '427 C' },
	{ color: 'rgb(193, 198, 200)', name: 'Silver Sand', pantone: '428 C' },
	{ color: 'rgb(162, 170, 173)', name: 'Gray Chateau', pantone: '429 C' },
	{ color: 'rgb(124, 135, 142)', name: 'Oslo Gray', pantone: '430 C' },
	{ color: 'rgb(91, 103, 112)', name: 'Silbergrau', pantone: '431 C' },
	{ color: 'rgb(51, 63, 72)', name: 'Limed Spruce', pantone: '432 C' },
	{ color: 'rgb(29, 37, 45)', name: 'Ebony Clay', pantone: '433 C' },
	{ color: 'rgb(208, 196, 197)', name: 'Cold Turkey', pantone: '434 C' },
	{ color: 'rgb(193, 178, 182)', name: 'Pink Swan', pantone: '435 C' },
	{ color: 'rgb(171, 152, 157)', name: 'Dusty Gray', pantone: '436 C' },
	{ color: 'rgb(123, 100, 105)', name: 'Falcon', pantone: '437 C' },
	{ color: 'rgb(88, 68, 70)', name: 'Don Juan', pantone: '438 C' },
	{ color: 'rgb(69, 53, 54)', name: 'Woody Brown', pantone: '439 C' },
	{ color: 'rgb(56, 46, 44)', name: 'Dune', pantone: '440 C' },
	{ color: 'rgb(190, 198, 196)', name: 'Powder Ash', pantone: '441 C' },
	{ color: 'rgb(162, 172, 171)', name: 'Edward', pantone: '442 C' },
	{ color: 'rgb(145, 157, 157)', name: 'Granny Smith', pantone: '443 C' },
	{ color: 'rgb(113, 124, 125)', name: 'Sirocco', pantone: '444 C' },
	{ color: 'rgb(80, 87, 89)', name: 'Abbey', pantone: '445 C' },
	{ color: 'rgb(63, 68, 68)', name: 'Cape Cod', pantone: '446 C' },
	{ color: 'rgb(55, 58, 54)', name: 'Lunar Green', pantone: '447 C' },
	{ color: 'rgb(97, 79, 37)', name: 'Dallas', pantone: '4485 C' },
	{ color: 'rgb(74, 65, 42)', name: 'Judge Gray', pantone: '448 C' },
	{ color: 'rgb(140, 119, 50)', name: 'Pesto', pantone: '4495 C' },
	{ color: 'rgb(82, 71, 39)', name: 'Woodland', pantone: '449 C' },
	{ color: 'rgb(153, 133, 66)', name: 'Sycamore', pantone: '4505 C' },
	{ color: 'rgb(89, 74, 37)', name: 'Irish Coffee', pantone: '450 C' },
	{ color: 'rgb(179, 163, 105)', name: 'Teak', pantone: '4515 C' },
	{ color: 'rgb(155, 148, 95)', name: 'Barley Corn', pantone: '451 C' },
	{ color: 'rgb(197, 183, 131)', name: 'Sorrell Brown', pantone: '4525 C' },
	{ color: 'rgb(176, 170, 126)', name: 'Mongoose', pantone: '452 C' },
	{ color: 'rgb(207, 196, 147)', name: 'Yuma', pantone: '4535 C' },
	{ color: 'rgb(191, 187, 152)', name: 'Coral Reef', pantone: '453 C' },
	{ color: 'rgb(213, 203, 159)', name: 'Winter Hazel', pantone: '4545 C' },
	{ color: 'rgb(202, 199, 167)', name: 'Thistle Green', pantone: '454 C' },
	{ color: 'rgb(105, 91, 36)', name: 'Dallas', pantone: '455 C' },
	{ color: 'rgb(162, 142, 42)', name: 'Luxor Gold', pantone: '456 C' },
	{ color: 'rgb(184, 157, 24)', name: 'Sahara', pantone: '457 C' },
	{ color: 'rgb(217, 199, 86)', name: 'Tacha', pantone: '458 C' },
	{ color: 'rgb(222, 205, 99)', name: 'Chenin', pantone: '459 C' },
	{ color: 'rgb(228, 215, 126)', name: 'Putty', pantone: '460 C' },
	{ color: 'rgb(233, 223, 151)', name: 'Primrose', pantone: '461 C' },
	{ color: 'rgb(79, 44, 29)', name: 'Braun', pantone: '4625 C' },
	{ color: 'rgb(92, 70, 43)', name: 'Quincy', pantone: '462 C' },
	{ color: 'rgb(148, 96, 55)', name: 'Potters Clay', pantone: '4635 C' },
	{ color: 'rgb(116, 79, 40)', name: 'Dallas', pantone: '463 C' },
	{ color: 'rgb(173, 124, 89)', name: 'Limed Oak', pantone: '4645 C' },
	{ color: 'rgb(139, 91, 41)', name: 'Kumera', pantone: '464 C' },
	{ color: 'rgb(191, 148, 116)', name: 'Muddy Waters', pantone: '4655 C' },
	{ color: 'rgb(185, 151, 91)', name: 'Muddy Waters', pantone: '465 C' },
	{ color: 'rgb(205, 167, 136)', name: 'Tan', pantone: '4665 C' },
	{ color: 'rgb(198, 170, 118)', name: 'Laser', pantone: '466 C' },
	{ color: 'rgb(220, 191, 166)', name: 'Cameo', pantone: '4675 C' },
	{ color: 'rgb(211, 188, 141)', name: 'Straw', pantone: '467 C' },
	{ color: 'rgb(224, 198, 173)', name: 'Grain Brown', pantone: '4685 C' },
	{ color: 'rgb(221, 203, 164)', name: 'Sapling', pantone: '468 C' },
	{ color: 'rgb(91, 52, 39)', name: 'Irish Coffee', pantone: '4695 C' },
	{ color: 'rgb(105, 63, 35)', name: 'Pickled Bean', pantone: '469 C' },
	{ color: 'rgb(124, 77, 58)', name: 'Ironstone', pantone: '4705 C' },
	{ color: 'rgb(164, 90, 42)', name: 'Paarl', pantone: '470 C' },
	{ color: 'rgb(149, 108, 88)', name: 'Leather', pantone: '4715 C' },
	{ color: 'rgb(184, 97, 37)', name: 'Desert', pantone: '471 C' },
	{ color: 'rgb(174, 138, 121)', name: 'Sandrift', pantone: '4725 C' },
	{ color: 'rgb(229, 158, 109)', name: 'Apricot', pantone: '472 C' },
	{ color: 'rgb(192, 163, 146)', name: 'Quicksand', pantone: '4735 C' },
	{ color: 'rgb(240, 191, 155)', name: 'New Orleans', pantone: '473 C' },
	{ color: 'rgb(205, 181, 167)', name: 'Vanilla', pantone: '4745 C' },
	{ color: 'rgb(241, 198, 167)', name: 'Mandys Pink', pantone: '474 C' },
	{ color: 'rgb(215, 196, 183)', name: 'Soft Amber', pantone: '4755 C' },
	{ color: 'rgb(243, 207, 179)', name: 'Mandys Pink', pantone: '475 C' },
	{ color: 'rgb(78, 54, 41)', name: 'Saddle', pantone: '476 C' },
	{ color: 'rgb(98, 59, 42)', name: 'Quincy', pantone: '477 C' },
	{ color: 'rgb(112, 63, 42)', name: 'Old Copper', pantone: '478 C' },
	{ color: 'rgb(170, 128, 102)', name: 'Sandal', pantone: '479 C' },
	{ color: 'rgb(198, 169, 146)', name: 'Indian Khaki', pantone: '480 C' },
	{ color: 'rgb(211, 187, 168)', name: 'Vanilla', pantone: '481 C' },
	{ color: 'rgb(219, 200, 182)', name: 'Akaroa', pantone: '482 C' },
	{ color: 'rgb(101, 48, 36)', name: 'Irish Coffee', pantone: '483 C' },
	{ color: 'rgb(154, 51, 36)', name: 'Prairie Sand', pantone: '484 C' },
	{ color: 'rgb(218, 41, 28)', name: 'Alizarin Crimson', pantone: '485 C' },
	{ color: 'rgb(232, 146, 124)', name: 'Apricot', pantone: '486 C' },
	{ color: 'rgb(234, 167, 148)', name: 'Tonys Pink', pantone: '487 C' },
	{ color: 'rgb(236, 186, 168)', name: 'Zinnwaldite', pantone: '488 C' },
	{ color: 'rgb(236, 195, 178)', name: 'Zinnwaldite', pantone: '489 C' },
	{ color: 'rgb(93, 42, 44)', name: 'Buccaneer', pantone: '490 C' },
	{ color: 'rgb(127, 48, 53)', name: 'Solid Pink', pantone: '491 C' },
	{ color: 'rgb(143, 50, 55)', name: 'El Salva', pantone: '492 C' },
	{ color: 'rgb(220, 134, 153)', name: 'Petite Orchid', pantone: '493 C' },
	{ color: 'rgb(233, 162, 178)', name: 'Kobi', pantone: '494 C' },
	{ color: 'rgb(241, 189, 200)', name: 'Beauty Bush', pantone: '495 C' },
	{ color: 'rgb(242, 198, 207)', name: 'Beauty Bush', pantone: '496 C' },
	{ color: 'rgb(63, 32, 33)', name: 'Jon', pantone: '4975 C' },
	{ color: 'rgb(81, 47, 46)', name: 'Cowboy', pantone: '497 C' },
	{ color: 'rgb(135, 75, 82)', name: 'Copper Rust', pantone: '4985 C' },
	{ color: 'rgb(106, 55, 53)', name: 'Buccaneer', pantone: '498 C' },
	{ color: 'rgb(156, 97, 105)', name: 'Copper Rose', pantone: '4995 C' },
	{ color: 'rgb(122, 62, 58)', name: 'Lotus', pantone: '499 C' },
	{ color: 'rgb(176, 124, 131)', name: 'Turkish Rose', pantone: '5005 C' },
	{ color: 'rgb(198, 133, 143)', name: 'Puce', pantone: '500 C' },
	{ color: 'rgb(204, 161, 166)', name: 'Eunry', pantone: '5015 C' },
	{ color: 'rgb(218, 165, 173)', name: 'Careys Pink', pantone: '501 C' },
	{ color: 'rgb(219, 183, 187)', name: 'Blossom', pantone: '5025 C' },
	{ color: 'rgb(229, 186, 193)', name: 'Cavern Pink', pantone: '502 C' },
	{ color: 'rgb(223, 194, 195)', name: 'Pink Flare', pantone: '5035 C' },
	{ color: 'rgb(233, 196, 199)', name: 'Cavern Pink', pantone: '503 C' },
	{ color: 'rgb(87, 41, 50)', name: 'Buccaneer', pantone: '504 C' },
	{ color: 'rgb(111, 44, 63)', name: 'Tawny Port', pantone: '505 C' },
	{ color: 'rgb(132, 52, 78)', name: 'Camelot', pantone: '506 C' },
	{ color: 'rgb(213, 146, 170)', name: 'Can Can', pantone: '507 C' },
	{ color: 'rgb(228, 169, 187)', name: 'Shilo', pantone: '508 C' },
	{ color: 'rgb(232, 179, 195)', name: 'Shilo', pantone: '509 C' },
	{ color: 'rgb(235, 190, 203)', name: 'Beauty Bush', pantone: '510 C' },
	{ color: 'rgb(81, 42, 68)', name: 'Livid Brown', pantone: '5115 C' },
	{ color: 'rgb(97, 44, 81)', name: 'Finn', pantone: '511 C' },
	{ color: 'rgb(105, 60, 94)', name: 'Cosmic', pantone: '5125 C' },
	{ color: 'rgb(131, 49, 119)', name: 'Plum', pantone: '512 C' },
	{ color: 'rgb(126, 84, 117)', name: 'Falcon', pantone: '5135 C' },
	{ color: 'rgb(147, 50, 142)', name: 'Plum', pantone: '513 C' },
	{ color: 'rgb(155, 119, 147)', name: 'Mountbatten Pink', pantone: '5145 C' },
	{ color: 'rgb(212, 139, 200)', name: 'Light Orchid', pantone: '514 C' },
	{ color: 'rgb(191, 165, 184)', name: 'Lily', pantone: '5155 C' },
	{ color: 'rgb(226, 172, 215)', name: 'Light Orchid', pantone: '515 C' },
	{ color: 'rgb(211, 192, 205)', name: 'Maverick', pantone: '5165 C' },
	{ color: 'rgb(230, 190, 221)', name: 'Melanie', pantone: '516 C' },
	{ color: 'rgb(216, 200, 209)', name: 'Lola', pantone: '5175 C' },
	{ color: 'rgb(235, 198, 223)', name: 'Melanie', pantone: '517 C' },
	{ color: 'rgb(74, 48, 65)', name: 'Matterhorn', pantone: '5185 C' },
	{ color: 'rgb(75, 48, 72)', name: 'Eggplant', pantone: '518 C' },
	{ color: 'rgb(102, 67, 90)', name: 'Eggplant', pantone: '5195 C' },
	{ color: 'rgb(89, 49, 95)', name: 'Bossanova', pantone: '519 C' },
	{ color: 'rgb(134, 100, 122)', name: 'Falcon', pantone: '5205 C' },
	{ color: 'rgb(100, 47, 108)', name: 'Bossanova', pantone: '520 C' },
	{ color: 'rgb(175, 149, 166)', name: 'Dusty Gray', pantone: '5215 C' },
	{ color: 'rgb(165, 127, 178)', name: 'Lavender Purple', pantone: '521 C' },
	{ color: 'rgb(198, 176, 188)', name: 'Lily', pantone: '5225 C' },
	{ color: 'rgb(186, 156, 197)', name: 'London Hue', pantone: '522 C' },
	{ color: 'rgb(208, 190, 199)', name: 'Cold Turkey', pantone: '5235 C' },
	{ color: 'rgb(201, 177, 208)', name: 'London Hue', pantone: '523 C' },
	{ color: 'rgb(219, 205, 211)', name: 'Lola', pantone: '5245 C' },
	{ color: 'rgb(213, 194, 216)', name: 'Chatelle', pantone: '524 C' },
	{ color: 'rgb(30, 26, 52)', name: 'Mirage', pantone: '5255 C' },
	{ color: 'rgb(87, 44, 95)', name: 'Bossanova', pantone: '525 C' },
	{ color: 'rgb(64, 58, 96)', name: 'Martinique', pantone: '5265 C' },
	{ color: 'rgb(112, 47, 138)', name: 'Eminence', pantone: '526 C' },
	{ color: 'rgb(89, 84, 120)', name: 'Smoky', pantone: '5275 C' },
	{ color: 'rgb(128, 49, 167)', name: 'Vivid Violet', pantone: '527 C' },
	{ color: 'rgb(141, 137, 165)', name: 'Manatee', pantone: '5285 C' },
	{ color: 'rgb(181, 128, 209)', name: 'East Side', pantone: '528 C' },
	{ color: 'rgb(179, 176, 196)', name: 'Chatelle', pantone: '5295 C' },
	{ color: 'rgb(202, 162, 221)', name: 'Light Wisteria', pantone: '529 C' },
	{ color: 'rgb(198, 196, 210)', name: 'Gray Suit', pantone: '5305 C' },
	{ color: 'rgb(215, 185, 228)', name: 'Prelude', pantone: '530 C' },
	{ color: 'rgb(216, 215, 223)', name: 'Mischka', pantone: '5315 C' },
	{ color: 'rgb(223, 200, 231)', name: 'Prelude', pantone: '531 C' },
	{ color: 'rgb(28, 31, 42)', name: 'Ebony Clay', pantone: '532 C' },
	{ color: 'rgb(31, 42, 68)', name: 'Cloud Burst', pantone: '533 C' },
	{ color: 'rgb(27, 54, 93)', name: 'Biscay', pantone: '534 C' },
	{ color: 'rgb(142, 159, 188)', name: 'Nepal', pantone: '535 C' },
	{ color: 'rgb(162, 178, 200)', name: 'Rock Blue', pantone: '536 C' },
	{ color: 'rgb(187, 199, 214)', name: 'Heather', pantone: '537 C' },
	{ color: 'rgb(197, 207, 218)', name: 'Heather', pantone: '538 C' },
	{ color: 'rgb(8, 31, 44)', name: 'Black Pearl', pantone: '5395 C' },
	{ color: 'rgb(0, 38, 58)', name: 'Daintree', pantone: '539 C' },
	{ color: 'rgb(79, 117, 139)', name: 'Smalt Blue', pantone: '5405 C' },
	{ color: 'rgb(0, 48, 87)', name: 'Prussian Blue', pantone: '540 C' },
	{ color: 'rgb(91, 127, 149)', name: 'Horizon', pantone: '5415 C' },
	{ color: 'rgb(0, 60, 113)', name: 'Regal Blue', pantone: '541 C' },
	{ color: 'rgb(122, 153, 172)', name: 'Bali Hai', pantone: '5425 C' },
	{ color: 'rgb(123, 175, 212)', name: 'Half Baked', pantone: '542 C' },
	{ color: 'rgb(166, 187, 200)', name: 'Casper', pantone: '5435 C' },
	{ color: 'rgb(164, 200, 225)', name: 'Regent St Blue', pantone: '543 C' },
	{ color: 'rgb(183, 201, 211)', name: 'Heather', pantone: '5445 C' },
	{ color: 'rgb(189, 214, 230)', name: 'Periwinkle Gray', pantone: '544 C' },
	{ color: 'rgb(191, 206, 214)', name: 'Heather', pantone: '5455 C' },
	{ color: 'rgb(198, 218, 231)', name: 'Botticelli', pantone: '545 C' },
	{ color: 'rgb(7, 39, 45)', name: 'Tiber', pantone: '5463 C' },
	{ color: 'rgb(24, 51, 47)', name: 'Timber Green', pantone: '5467 C' },
	{ color: 'rgb(7, 43, 49)', name: 'Tiber', pantone: '546 C' },
	{ color: 'rgb(17, 94, 103)', name: 'Genoa', pantone: '5473 C' },
	{ color: 'rgb(62, 93, 88)', name: 'Mineral Green', pantone: '5477 C' },
	{ color: 'rgb(0, 49, 60)', name: 'Cyprus', pantone: '547 C' },
	{ color: 'rgb(79, 134, 142)', name: 'Smalt Blue', pantone: '5483 C' },
	{ color: 'rgb(93, 121, 117)', name: 'Cutty Sark', pantone: '5487 C' },
	{ color: 'rgb(0, 61, 76)', name: 'Sherpa Blue', pantone: '548 C' },
	{ color: 'rgb(127, 169, 174)', name: 'Gumbo', pantone: '5493 C' },
	{ color: 'rgb(130, 153, 149)', name: 'Granny Smith', pantone: '5497 C' },
	{ color: 'rgb(107, 164, 184)', name: 'Hippie Blue', pantone: '549 C' },
	{ color: 'rgb(148, 183, 187)', name: 'Shadow Green', pantone: '5503 C' },
	{ color: 'rgb(157, 176, 172)', name: 'Edward', pantone: '5507 C' },
	{ color: 'rgb(141, 185, 202)', name: 'Glacier', pantone: '550 C' },
	{ color: 'rgb(171, 199, 202)', name: 'Opal', pantone: '5513 C' },
	{ color: 'rgb(177, 192, 188)', name: 'Powder Ash', pantone: '5517 C' },
	{ color: 'rgb(163, 199, 210)', name: 'Casper', pantone: '551 C' },
	{ color: 'rgb(182, 207, 208)', name: 'Jet Stream', pantone: '5523 C' },
	{ color: 'rgb(188, 201, 197)', name: 'Powder Ash', pantone: '5527 C' },
	{ color: 'rgb(185, 211, 220)', name: 'Ziggurat', pantone: '552 C' },
	{ color: 'rgb(24, 48, 40)', name: 'Timber Green', pantone: '5535 C' },
	{ color: 'rgb(40, 71, 52)', name: 'Everglade', pantone: '553 C' },
	{ color: 'rgb(67, 105, 91)', name: 'Mineral Green', pantone: '5545 C' },
	{ color: 'rgb(32, 92, 64)', name: 'Green Pea', pantone: '554 C' },
	{ color: 'rgb(92, 127, 113)', name: 'Viridian Green', pantone: '5555 C' },
	{ color: 'rgb(40, 114, 79)', name: 'Sea Green', pantone: '555 C' },
	{ color: 'rgb(127, 156, 144)', name: 'Oxley', pantone: '5565 C' },
	{ color: 'rgb(111, 162, 135)', name: 'Oxley', pantone: '556 C' },
	{ color: 'rgb(146, 172, 160)', name: 'Pewter', pantone: '5575 C' },
	{ color: 'rgb(133, 176, 154)', name: 'Bay Leaf', pantone: '557 C' },
	{ color: 'rgb(167, 189, 177)', name: 'Summer Green', pantone: '5585 C' },
	{ color: 'rgb(154, 190, 170)', name: 'Summer Green', pantone: '558 C' },
	{ color: 'rgb(191, 206, 194)', name: 'Powder Ash', pantone: '5595 C' },
	{ color: 'rgb(173, 202, 184)', name: 'Spring Rain', pantone: '559 C' },
	{ color: 'rgb(34, 55, 43)', name: 'Everglade', pantone: '5605 C' },
	{ color: 'rgb(29, 60, 52)', name: 'Te Papa Green', pantone: '560 C' },
	{ color: 'rgb(94, 116, 97)', name: 'Finlandia', pantone: '5615 C' },
	{ color: 'rgb(0, 89, 76)', name: 'Aqua Deep', pantone: '561 C' },
	{ color: 'rgb(112, 133, 115)', name: 'Xanadu', pantone: '5625 C' },
	{ color: 'rgb(0, 111, 98)', name: 'Tropical Rain Forest', pantone: '562 C' },
	{ color: 'rgb(148, 165, 150)', name: 'Envy', pantone: '5635 C' },
	{ color: 'rgb(107, 187, 174)', name: 'Tradewind', pantone: '563 C' },
	{ color: 'rgb(163, 178, 164)', name: 'Pewter', pantone: '5645 C' },
	{ color: 'rgb(134, 200, 188)', name: 'Half Baked', pantone: '564 C' },
	{ color: 'rgb(176, 189, 176)', name: 'Green Spring', pantone: '5655 C' },
	{ color: 'rgb(161, 214, 202)', name: 'Sinbad', pantone: '565 C' },
	{ color: 'rgb(186, 197, 185)', name: 'Green Spring', pantone: '5665 C' },
	{ color: 'rgb(185, 220, 210)', name: 'Edgewater', pantone: '566 C' },
	{ color: 'rgb(23, 63, 53)', name: 'Gable Green', pantone: '567 C' },
	{ color: 'rgb(0, 108, 91)', name: 'Tropical Rain Forest', pantone: '568 C' },
	{ color: 'rgb(0, 129, 109)', name: 'Deep Sea', pantone: '569 C' },
	{ color: 'rgb(107, 202, 186)', name: 'Downy', pantone: '570 C' },
	{ color: 'rgb(152, 219, 206)', name: 'Aqua Island', pantone: '571 C' },
	{ color: 'rgb(165, 223, 211)', name: 'Aqua Island', pantone: '572 C' },
	{ color: 'rgb(181, 227, 216)', name: 'Jagged Ice', pantone: '573 C' },
	{ color: 'rgb(62, 72, 39)', name: 'Woodland', pantone: '5743 C' },
	{ color: 'rgb(61, 68, 30)', name: 'Thatch Green', pantone: '5747 C' },
	{ color: 'rgb(78, 91, 49)', name: 'Woodland', pantone: '574 C' },
	{ color: 'rgb(94, 103, 56)', name: 'Verdigris', pantone: '5753 C' },
	{ color: 'rgb(109, 113, 46)', name: 'Pesto', pantone: '5757 C' },
	{ color: 'rgb(103, 130, 58)', name: 'Pesto', pantone: '575 C' },
	{ color: 'rgb(115, 123, 76)', name: 'Go Ben', pantone: '5763 C' },
	{ color: 'rgb(138, 141, 74)', name: 'Shadow', pantone: '5767 C' },
	{ color: 'rgb(120, 157, 74)', name: 'Asparagus', pantone: '576 C' },
	{ color: 'rgb(137, 144, 100)', name: 'Avocado', pantone: '5773 C' },
	{ color: 'rgb(162, 165, 105)', name: 'Green Smoke', pantone: '5777 C' },
	{ color: 'rgb(169, 196, 127)', name: 'Olivine', pantone: '577 C' },
	{ color: 'rgb(163, 170, 131)', name: 'Sage', pantone: '5783 C' },
	{ color: 'rgb(186, 189, 139)', name: 'Swamp Green', pantone: '5787 C' },
	{ color: 'rgb(183, 206, 149)', name: 'Pine Glade', pantone: '578 C' },
	{ color: 'rgb(179, 185, 149)', name: 'Swamp Green', pantone: '5793 C' },
	{ color: 'rgb(198, 200, 155)', name: 'Thistle Green', pantone: '5797 C' },
	{ color: 'rgb(188, 209, 155)', name: 'Pine Glade', pantone: '579 C' },
	{ color: 'rgb(195, 198, 168)', name: 'Thistle Green', pantone: '5803 C' },
	{ color: 'rgb(208, 209, 171)', name: 'Green Mist', pantone: '5807 C' },
	{ color: 'rgb(196, 214, 164)', name: 'Sprout', pantone: '580 C' },
	{ color: 'rgb(85, 80, 37)', name: 'Woodland', pantone: '5815 C' },
	{ color: 'rgb(98, 93, 32)', name: 'Himalaya', pantone: '581 C' },
	{ color: 'rgb(137, 129, 61)', name: 'Sycamore', pantone: '5825 C' },
	{ color: 'rgb(142, 140, 19)', name: 'Hacienda', pantone: '582 C' },
	{ color: 'rgb(160, 153, 88)', name: 'Barley Corn', pantone: '5835 C' },
	{ color: 'rgb(183, 191, 16)', name: 'La Rioja', pantone: '583 C' },
	{ color: 'rgb(175, 169, 110)', name: 'Green Smoke', pantone: '5845 C' },
	{ color: 'rgb(210, 215, 85)', name: 'Tacha', pantone: '584 C' },
	{ color: 'rgb(192, 187, 135)', name: 'Indian Khaki', pantone: '5855 C' },
	{ color: 'rgb(219, 222, 112)', name: 'Chenin', pantone: '585 C' },
	{ color: 'rgb(203, 199, 147)', name: 'Yuma', pantone: '5865 C' },
	{ color: 'rgb(224, 226, 124)', name: 'Chenin', pantone: '586 C' },
	{ color: 'rgb(210, 206, 158)', name: 'Yuma', pantone: '5875 C' },
	{ color: 'rgb(227, 228, 141)', name: 'Zombie', pantone: '587 C' },
	{ color: 'rgb(241, 235, 156)', name: 'Primrose', pantone: '600 C' },
	{ color: 'rgb(240, 233, 145)', name: 'Khaki', pantone: '601 C' },
	{ color: 'rgb(240, 232, 123)', name: 'Manz', pantone: '602 C' },
	{ color: 'rgb(237, 224, 75)', name: 'Confetti', pantone: '603 C' },
	{ color: 'rgb(234, 218, 36)', name: 'Sunflower', pantone: '604 C' },
	{ color: 'rgb(225, 205, 0)', name: 'Corn', pantone: '605 C' },
	{ color: 'rgb(207, 181, 0)', name: 'Buddha Gold', pantone: '606 C' },
	{ color: 'rgb(235, 228, 154)', name: 'Primrose', pantone: '607 C' },
	{ color: 'rgb(233, 225, 134)', name: 'Wild Rice', pantone: '608 C' },
	{ color: 'rgb(230, 222, 119)', name: 'Chenin', pantone: '609 C' },
	{ color: 'rgb(225, 213, 85)', name: 'Wattle', pantone: '610 C' },
	{ color: 'rgb(215, 200, 38)', name: 'Key Lime Pie', pantone: '611 C' },
	{ color: 'rgb(196, 176, 0)', name: 'Buddha Gold', pantone: '612 C' },
	{ color: 'rgb(179, 155, 0)', name: 'Buddha Gold', pantone: '613 C' },
	{ color: 'rgb(220, 213, 154)', name: 'Sapling', pantone: '614 C' },
	{ color: 'rgb(214, 207, 141)', name: 'Winter Hazel', pantone: '615 C' },
	{ color: 'rgb(208, 200, 131)', name: 'Straw', pantone: '616 C' },
	{ color: 'rgb(192, 181, 97)', name: 'Laser', pantone: '617 C' },
	{ color: 'rgb(172, 159, 60)', name: 'Tussock', pantone: '618 C' },
	{ color: 'rgb(159, 145, 42)', name: 'Luxor Gold', pantone: '619 C' },
	{ color: 'rgb(138, 123, 25)', name: 'Hacienda', pantone: '620 C' },
	{ color: 'rgb(209, 224, 215)', name: 'Paris White', pantone: '621 C' },
	{ color: 'rgb(183, 205, 194)', name: 'Powder Ash', pantone: '622 C' },
	{ color: 'rgb(154, 185, 173)', name: 'Summer Green', pantone: '623 C' },
	{ color: 'rgb(120, 159, 144)', name: 'Oxley', pantone: '624 C' },
	{ color: 'rgb(80, 127, 112)', name: 'Como', pantone: '625 C' },
	{ color: 'rgb(40, 92, 77)', name: 'Plantation', pantone: '626 C' },
	{ color: 'rgb(19, 50, 43)', name: 'Gable Green', pantone: '627 C' },
	{ color: 'rgb(184, 221, 225)', name: 'Ziggurat', pantone: '628 C' },
	{ color: 'rgb(155, 211, 221)', name: 'Morning Glory', pantone: '629 C' },
	{ color: 'rgb(119, 197, 213)', name: 'Downy', pantone: '630 C' },
	{ color: 'rgb(62, 177, 200)', name: 'Pelorous', pantone: '631 C' },
	{ color: 'rgb(0, 147, 178)', name: 'Bondi Blue', pantone: '632 C' },
	{ color: 'rgb(0, 115, 150)', name: 'Allports', pantone: '633 C' },
	{ color: 'rgb(0, 95, 131)', name: 'Orient', pantone: '634 C' },
	{ color: 'rgb(164, 219, 232)', name: 'Regent St Blue', pantone: '635 C' },
	{ color: 'rgb(139, 211, 230)', name: 'Cornflower', pantone: '636 C' },
	{ color: 'rgb(78, 195, 224)', name: 'Picton Blue', pantone: '637 C' },
	{ color: 'rgb(0, 175, 215)', name: 'Cerulean', pantone: '638 C' },
	{ color: 'rgb(0, 149, 200)', name: 'Pacific Blue', pantone: '639 C' },
	{ color: 'rgb(0, 130, 186)', name: 'Lochmara', pantone: '640 C' },
	{ color: 'rgb(0, 103, 160)', name: 'Allports', pantone: '641 C' },
	{ color: 'rgb(209, 221, 230)', name: 'Botticelli', pantone: '642 C' },
	{ color: 'rgb(198, 214, 227)', name: 'Botticelli', pantone: '643 C' },
	{ color: 'rgb(155, 184, 211)', name: 'Polo Blue', pantone: '644 C' },
	{ color: 'rgb(125, 161, 196)', name: 'Glacier', pantone: '645 C' },
	{ color: 'rgb(94, 138, 180)', name: 'Hippie Blue', pantone: '646 C' },
	{ color: 'rgb(35, 97, 146)', name: 'Jelly Bean', pantone: '647 C' },
	{ color: 'rgb(0, 46, 93)', name: 'Midnight Blue', pantone: '648 C' },
	{ color: 'rgb(219, 226, 233)', name: 'Mystic', pantone: '649 C' },
	{ color: 'rgb(206, 217, 229)', name: 'Botticelli', pantone: '650 C' },
	{ color: 'rgb(167, 188, 214)', name: 'Pigeon Post', pantone: '651 C' },
	{ color: 'rgb(125, 155, 193)', name: 'Ship Cove', pantone: '652 C' },
	{ color: 'rgb(50, 98, 149)', name: 'Azure', pantone: '653 C' },
	{ color: 'rgb(0, 58, 112)', name: 'Regal Blue', pantone: '654 C' },
	{ color: 'rgb(0, 37, 84)', name: 'Prussian Blue', pantone: '655 C' },
	{ color: 'rgb(221, 229, 237)', name: 'Mystic', pantone: '656 C' },
	{ color: 'rgb(200, 216, 235)', name: 'Periwinkle Gray', pantone: '657 C' },
	{ color: 'rgb(177, 201, 232)', name: 'Spindle', pantone: '658 C' },
	{ color: 'rgb(123, 164, 219)', name: 'Danube', pantone: '659 C' },
	{ color: 'rgb(64, 126, 201)', name: 'Indigo', pantone: '660 C' },
	{ color: 'rgb(0, 53, 148)', name: 'Smalt', pantone: '661 C' },
	{ color: 'rgb(0, 26, 112)', name: 'Resolution Blue', pantone: '662 C' },
	{ color: 'rgb(229, 225, 230)', name: 'Mischka', pantone: '663 C' },
	{ color: 'rgb(224, 219, 227)', name: 'Mischka', pantone: '664 C' },
	{ color: 'rgb(198, 188, 208)', name: 'Chatelle', pantone: '665 C' },
	{ color: 'rgb(161, 146, 178)', name: 'Amethyst Smoke', pantone: '666 C' },
	{ color: 'rgb(124, 105, 146)', name: 'Rum', pantone: '667 C' },
	{ color: 'rgb(97, 75, 121)', name: 'Mulled Wine', pantone: '668 C' },
	{ color: 'rgb(63, 42, 86)', name: 'Bossanova', pantone: '669 C' },
	{ color: 'rgb(234, 211, 226)', name: 'Prim', pantone: '670 C' },
	{ color: 'rgb(230, 188, 216)', name: 'Melanie', pantone: '671 C' },
	{ color: 'rgb(223, 160, 201)', name: 'Light Orchid', pantone: '672 C' },
	{ color: 'rgb(217, 134, 186)', name: 'Shocking', pantone: '673 C' },
	{ color: 'rgb(198, 87, 154)', name: 'Mulberry', pantone: '674 C' },
	{ color: 'rgb(174, 37, 115)', name: 'Hibiscus', pantone: '675 C' },
	{ color: 'rgb(150, 0, 81)', name: 'Fresh Eggplant', pantone: '676 C' },
	{ color: 'rgb(229, 206, 219)', name: 'Twilight', pantone: '677 C' },
	{ color: 'rgb(227, 200, 216)', name: 'Twilight', pantone: '678 C' },
	{ color: 'rgb(222, 190, 210)', name: 'Melanie', pantone: '679 C' },
	{ color: 'rgb(201, 150, 182)', name: 'Viola', pantone: '680 C' },
	{ color: 'rgb(176, 108, 150)', name: 'Tapestry', pantone: '681 C' },
	{ color: 'rgb(153, 72, 120)', name: 'Cannon Pink', pantone: '682 C' },
	{ color: 'rgb(124, 40, 85)', name: 'Tawny Port', pantone: '683 C' },
	{ color: 'rgb(228, 198, 212)', name: 'Melanie', pantone: '684 C' },
	{ color: 'rgb(220, 182, 201)', name: 'Pink Flare', pantone: '685 C' },
	{ color: 'rgb(208, 161, 186)', name: 'Careys Pink', pantone: '686 C' },
	{ color: 'rgb(190, 132, 163)', name: 'Viola', pantone: '687 C' },
	{ color: 'rgb(167, 99, 137)', name: 'Tapestry', pantone: '688 C' },
	{ color: 'rgb(137, 59, 103)', name: 'Cannon Pink', pantone: '689 C' },
	{ color: 'rgb(97, 33, 65)', name: 'Tawny Port', pantone: '690 C' },
	{ color: 'rgb(233, 205, 208)', name: 'Oyster Pink', pantone: '691 C' },
	{ color: 'rgb(228, 190, 195)', name: 'Cavern Pink', pantone: '692 C' },
	{ color: 'rgb(215, 163, 171)', name: 'Careys Pink', pantone: '693 C' },
	{ color: 'rgb(196, 132, 144)', name: 'Old Rose', pantone: '694 C' },
	{ color: 'rgb(180, 107, 122)', name: 'Turkish Rose', pantone: '695 C' },
	{ color: 'rgb(152, 72, 86)', name: 'Copper Rust', pantone: '696 C' },
	{ color: 'rgb(137, 60, 71)', name: 'Solid Pink', pantone: '697 C' },
	{ color: 'rgb(242, 212, 215)', name: 'Vanilla Ice', pantone: '698 C' },
	{ color: 'rgb(244, 195, 204)', name: 'Azalea', pantone: '699 C' },
	{ color: 'rgb(242, 172, 185)', name: 'Wewak', pantone: '700 C' },
	{ color: 'rgb(230, 134, 153)', name: 'Deep Blush', pantone: '701 C' },
	{ color: 'rgb(210, 91, 115)', name: 'Chestnut Rose', pantone: '702 C' },
	{ color: 'rgb(184, 58, 75)', name: 'Night Shadz', pantone: '703 C' },
	{ color: 'rgb(158, 42, 47)', name: 'Mexican Red', pantone: '704 C' },
	{ color: 'rgb(245, 218, 223)', name: 'Vanilla Ice', pantone: '705 C' },
	{ color: 'rgb(247, 206, 215)', name: 'Azalea', pantone: '706 C' },
	{ color: 'rgb(249, 181, 196)', name: 'Illusion', pantone: '707 C' },
	{ color: 'rgb(248, 144, 165)', name: 'Tickle Me Pink', pantone: '708 C' },
	{ color: 'rgb(239, 96, 121)', name: 'Froly', pantone: '709 C' },
	{ color: 'rgb(224, 62, 82)', name: 'Cerise Red', pantone: '710 C' },
	{ color: 'rgb(203, 44, 48)', name: 'Persian Red', pantone: '711 C' },
	{ color: 'rgb(252, 200, 155)', name: 'Peach Orange', pantone: '712 C' },
	{ color: 'rgb(253, 190, 135)', name: 'Macaroni and Cheese', pantone: '713 C' },
	{ color: 'rgb(253, 170, 99)', name: 'Atomic Tangerine', pantone: '714 C' },
	{ color: 'rgb(246, 141, 46)', name: 'Sea Buckthorn', pantone: '715 C' },
	{ color: 'rgb(234, 118, 0)', name: 'Mango Tango', pantone: '716 C' },
	{ color: 'rgb(212, 93, 0)', name: 'Tenn', pantone: '717 C' },
	{ color: 'rgb(190, 77, 0)', name: 'Rose of Sharon', pantone: '718 C' },
	{ color: 'rgb(237, 200, 163)', name: 'Pancho', pantone: '719 C' },
	{ color: 'rgb(231, 183, 138)', name: 'Gold Sand', pantone: '720 C' },
	{ color: 'rgb(221, 164, 111)', name: 'Di Serria', pantone: '721 C' },
	{ color: 'rgb(200, 130, 66)', name: 'Raw Sienna', pantone: '722 C' },
	{ color: 'rgb(179, 105, 36)', name: 'Desert', pantone: '723 C' },
	{ color: 'rgb(147, 77, 17)', name: 'Korma', pantone: '724 C' },
	{ color: 'rgb(125, 63, 22)', name: 'Copper Canyon', pantone: '725 C' },
	{ color: 'rgb(224, 192, 159)', name: 'Brandy', pantone: '726 C' },
	{ color: 'rgb(217, 180, 143)', name: 'Straw', pantone: '727 C' },
	{ color: 'rgb(205, 160, 119)', name: 'Whiskey', pantone: '728 C' },
	{ color: 'rgb(181, 129, 80)', name: 'Driftwood', pantone: '729 C' },
	{ color: 'rgb(158, 101, 46)', name: 'Paarl', pantone: '730 C' },
	{ color: 'rgb(119, 66, 18)', name: 'Raw Umber', pantone: '731 C' },
	{ color: 'rgb(98, 52, 18)', name: 'Jambalaya', pantone: '732 C' },
	{ color: 'rgb(245, 225, 164)', name: 'Sandwisp', pantone: '7401 C' },
	{ color: 'rgb(236, 216, 152)', name: 'Chalky', pantone: '7402 C' },
	{ color: 'rgb(238, 212, 132)', name: 'Flax', pantone: '7403 C' },
	{ color: 'rgb(244, 218, 64)', name: 'Golden Dream', pantone: '7404 C' },
	{ color: 'rgb(242, 205, 0)', name: 'Supernova', pantone: '7405 C' },
	{ color: 'rgb(241, 196, 0)', name: 'Amber', pantone: '7406 C' },
	{ color: 'rgb(203, 160, 82)', name: 'Tussock', pantone: '7407 C' },
	{ color: 'rgb(246, 190, 0)', name: 'Amber', pantone: '7408 C' },
	{ color: 'rgb(240, 179, 35)', name: 'Fuel Yellow', pantone: '7409 C' },
	{ color: 'rgb(254, 173, 119)', name: 'Macaroni and Cheese', pantone: '7410 C' },
	{ color: 'rgb(230, 166, 93)', name: 'Porsche', pantone: '7411 C' },
	{ color: 'rgb(211, 130, 53)', name: 'Brandy Punch', pantone: '7412 C' },
	{ color: 'rgb(220, 134, 51)', name: 'Brandy Punch', pantone: '7413 C' },
	{ color: 'rgb(193, 108, 24)', name: 'Bourbon', pantone: '7414 C' },
	{ color: 'rgb(230, 186, 168)', name: 'Cashmere', pantone: '7415 C' },
	{ color: 'rgb(229, 106, 84)', name: 'Terracotta', pantone: '7416 C' },
	{ color: 'rgb(224, 78, 57)', name: 'Punch', pantone: '7417 C' },
	{ color: 'rgb(205, 84, 91)', name: 'Chestnut Rose', pantone: '7418 C' },
	{ color: 'rgb(176, 74, 90)', name: 'Hippie Pink', pantone: '7419 C' },
	{ color: 'rgb(155, 34, 66)', name: 'Mexican Red', pantone: '7420 C' },
	{ color: 'rgb(101, 29, 50)', name: 'Wine Berry', pantone: '7421 C' },
	{ color: 'rgb(244, 205, 212)', name: 'We Peep', pantone: '7422 C' },
	{ color: 'rgb(224, 98, 135)', name: 'Cranberry', pantone: '7423 C' },
	{ color: 'rgb(226, 69, 133)', name: 'Cerise', pantone: '7424 C' },
	{ color: 'rgb(181, 37, 85)', name: 'Maroon Flush', pantone: '7425 C' },
	{ color: 'rgb(164, 18, 63)', name: 'Shiraz', pantone: '7426 C' },
	{ color: 'rgb(151, 27, 47)', name: 'Old Brick', pantone: '7427 C' },
	{ color: 'rgb(106, 44, 62)', name: 'Tawny Port', pantone: '7428 C' },
	{ color: 'rgb(226, 188, 203)', name: 'Melanie', pantone: '7429 C' },
	{ color: 'rgb(220, 169, 191)', name: 'Blossom', pantone: '7430 C' },
	{ color: 'rgb(201, 128, 158)', name: 'Puce', pantone: '7431 C' },
	{ color: 'rgb(181, 92, 128)', name: 'Tapestry', pantone: '7432 C' },
	{ color: 'rgb(167, 58, 100)', name: 'Rouge', pantone: '7433 C' },
	{ color: 'rgb(155, 50, 89)', name: 'Night Shadz', pantone: '7434 C' },
	{ color: 'rgb(135, 38, 81)', name: 'Camelot', pantone: '7435 C' },
	{ color: 'rgb(238, 218, 234)', name: 'Prim', pantone: '7436 C' },
	{ color: 'rgb(204, 174, 208)', name: 'London Hue', pantone: '7437 C' },
	{ color: 'rgb(213, 158, 215)', name: 'Light Wisteria', pantone: '7438 C' },
	{ color: 'rgb(178, 136, 185)', name: 'Lavender Purple', pantone: '7439 C' },
	{ color: 'rgb(162, 119, 166)', name: 'Trendy Pink', pantone: '7440 C' },
	{ color: 'rgb(159, 92, 192)', name: 'Amethyst', pantone: '7441 C' },
	{ color: 'rgb(150, 60, 189)', name: 'Fuchsia Blue', pantone: '7442 C' },
	{ color: 'rgb(221, 218, 232)', name: 'Mischka', pantone: '7443 C' },
	{ color: 'rgb(182, 184, 220)', name: 'Pigeon Post', pantone: '7444 C' },
	{ color: 'rgb(167, 162, 195)', name: 'Logan', pantone: '7445 C' },
	{ color: 'rgb(137, 134, 202)', name: 'Blue Bell', pantone: '7446 C' },
	{ color: 'rgb(93, 71, 119)', name: 'Mulled Wine', pantone: '7447 C' },
	{ color: 'rgb(75, 56, 76)', name: 'Mortar', pantone: '7448 C' },
	{ color: 'rgb(65, 39, 59)', name: 'Livid Brown', pantone: '7449 C' },
	{ color: 'rgb(189, 197, 219)', name: 'Blue Haze', pantone: '7450 C' },
	{ color: 'rgb(137, 171, 227)', name: 'Cornflower', pantone: '7451 C' },
	{ color: 'rgb(128, 148, 221)', name: 'Chetwode Blue', pantone: '7452 C' },
	{ color: 'rgb(123, 166, 222)', name: 'Danube', pantone: '7453 C' },
	{ color: 'rgb(95, 143, 180)', name: 'Hippie Blue', pantone: '7454 C' },
	{ color: 'rgb(58, 93, 174)', name: 'Azure', pantone: '7455 C' },
	{ color: 'rgb(96, 110, 178)', name: 'Scampi', pantone: '7456 C' },
	{ color: 'rgb(187, 221, 230)', name: 'Jagged Ice', pantone: '7457 C' },
	{ color: 'rgb(113, 178, 201)', name: 'Glacier', pantone: '7458 C' },
	{ color: 'rgb(66, 152, 181)', name: 'Boston Blue', pantone: '7459 C' },
	{ color: 'rgb(0, 134, 191)', name: 'Lochmara', pantone: '7460 C' },
	{ color: 'rgb(0, 125, 186)', name: 'Lochmara', pantone: '7461 C' },
	{ color: 'rgb(0, 85, 140)', name: 'Orient', pantone: '7462 C' },
	{ color: 'rgb(0, 43, 73)', name: 'Prussian Blue', pantone: '7463 C' },
	{ color: 'rgb(160, 209, 202)', name: 'Sinbad', pantone: '7464 C' },
	{ color: 'rgb(64, 193, 172)', name: 'Puerto Rico', pantone: '7465 C' },
	{ color: 'rgb(0, 176, 185)', name: 'Pacific Blue', pantone: '7466 C' },
	{ color: 'rgb(0, 163, 173)', name: 'Bondi Blue', pantone: '7467 C' },
	{ color: 'rgb(0, 115, 152)', name: 'Allports', pantone: '7468 C' },
	{ color: 'rgb(0, 95, 134)', name: 'Orient', pantone: '7469 C' },
	{ color: 'rgb(0, 90, 112)', name: 'Blue Stone', pantone: '7470 C' },
	{ color: 'rgb(126, 221, 211)', name: 'Bermuda', pantone: '7471 C' },
	{ color: 'rgb(92, 184, 178)', name: 'Tradewind', pantone: '7472 C' },
	{ color: 'rgb(39, 153, 137)', name: 'Jungle Green', pantone: '7473 C' },
	{ color: 'rgb(0, 118, 129)', name: 'Blue Lagoon', pantone: '7474 C' },
	{ color: 'rgb(72, 122, 123)', name: 'Faded Jade', pantone: '7475 C' },
	{ color: 'rgb(13, 82, 87)', name: 'Eden', pantone: '7476 C' },
	{ color: 'rgb(36, 76, 90)', name: 'Blue Dianne', pantone: '7477 C' },
	{ color: 'rgb(155, 227, 191)', name: 'Algae Green', pantone: '7478 C' },
	{ color: 'rgb(38, 208, 124)', name: 'Shamrock', pantone: '7479 C' },
	{ color: 'rgb(0, 191, 111)', name: 'Jade', pantone: '7480 C' },
	{ color: 'rgb(0, 183, 79)', name: 'Jade', pantone: '7481 C' },
	{ color: 'rgb(0, 159, 77)', name: 'Green Haze', pantone: '7482 C' },
	{ color: 'rgb(39, 93, 56)', name: 'Everglade', pantone: '7483 C' },
	{ color: 'rgb(0, 87, 63)', name: 'Aqua Deep', pantone: '7484 C' },
	{ color: 'rgb(208, 222, 187)', name: 'Sprout', pantone: '7485 C' },
	{ color: 'rgb(188, 225, 148)', name: 'Feijoa', pantone: '7486 C' },
	{ color: 'rgb(142, 221, 101)', name: 'Pastel Green', pantone: '7487 C' },
	{ color: 'rgb(120, 214, 75)', name: 'Atlantis', pantone: '7488 C' },
	{ color: 'rgb(116, 170, 80)', name: 'Chelsea Cucumber', pantone: '7489 C' },
	{ color: 'rgb(113, 153, 73)', name: 'Asparagus', pantone: '7490 C' },
	{ color: 'rgb(121, 134, 60)', name: 'Sycamore', pantone: '7491 C' },
	{ color: 'rgb(191, 204, 128)', name: 'Pine Glade', pantone: '7492 C' },
	{ color: 'rgb(187, 197, 146)', name: 'Pine Glade', pantone: '7493 C' },
	{ color: 'rgb(156, 175, 136)', name: 'Locust', pantone: '7494 C' },
	{ color: 'rgb(143, 153, 62)', name: 'Sycamore', pantone: '7495 C' },
	{ color: 'rgb(118, 136, 29)', name: 'Trendy Green', pantone: '7496 C' },
	{ color: 'rgb(122, 114, 86)', name: 'Go Ben', pantone: '7497 C' },
	{ color: 'rgb(91, 98, 54)', name: 'Verdigris', pantone: '7498 C' },
	{ color: 'rgb(241, 230, 178)', name: 'Sidecar', pantone: '7499 C' },
	{ color: 'rgb(223, 209, 167)', name: 'Sapling', pantone: '7500 C' },
	{ color: 'rgb(217, 200, 158)', name: 'Pavlova', pantone: '7501 C' },
	{ color: 'rgb(206, 184, 136)', name: 'Sorrell Brown', pantone: '7502 C' },
	{ color: 'rgb(168, 153, 104)', name: 'Sandal', pantone: '7503 C' },
	{ color: 'rgb(148, 121, 93)', name: 'Domino', pantone: '7504 C' },
	{ color: 'rgb(129, 96, 64)', name: 'Spicy Mix', pantone: '7505 C' },
	{ color: 'rgb(239, 219, 178)', name: 'Chamois', pantone: '7506 C' },
	{ color: 'rgb(252, 210, 153)', name: 'Cherokee', pantone: '7507 C' },
	{ color: 'rgb(225, 184, 127)', name: 'Harvest Gold', pantone: '7508 C' },
	{ color: 'rgb(214, 164, 97)', name: 'Di Serria', pantone: '7509 C' },
	{ color: 'rgb(198, 137, 63)', name: 'Tussock', pantone: '7510 C' },
	{ color: 'rgb(183, 119, 41)', name: 'Marigold', pantone: '7511 C' },
	{ color: 'rgb(166, 99, 27)', name: 'Desert', pantone: '7512 C' },
	{ color: 'rgb(225, 183, 167)', name: 'Rose Fog', pantone: '7513 C' },
	{ color: 'rgb(213, 162, 134)', name: 'My Pink', pantone: '7514 C' },
	{ color: 'rgb(197, 139, 104)', name: 'Antique Brass', pantone: '7515 C' },
	{ color: 'rgb(153, 85, 43)', name: 'Paarl', pantone: '7516 C' },
	{ color: 'rgb(133, 67, 30)', name: 'Bull Shot', pantone: '7517 C' },
	{ color: 'rgb(109, 79, 71)', name: 'Ferra', pantone: '7518 C' },
	{ color: 'rgb(94, 75, 60)', name: 'Kabul', pantone: '7519 C' },
	{ color: 'rgb(234, 190, 176)', name: 'Zinnwaldite', pantone: '7520 C' },
	{ color: 'rgb(192, 156, 131)', name: 'Brandy Rose', pantone: '7521 C' },
	{ color: 'rgb(180, 106, 85)', name: 'Santa Fe', pantone: '7522 C' },
	{ color: 'rgb(171, 92, 87)', name: 'Matrix', pantone: '7523 C' },
	{ color: 'rgb(164, 82, 72)', name: 'Sepia Skin', pantone: '7524 C' },
	{ color: 'rgb(154, 106, 79)', name: 'Leather', pantone: '7525 C' },
	{ color: 'rgb(138, 57, 27)', name: 'Russet', pantone: '7526 C' },
	{ color: 'rgb(214, 210, 196)', name: 'Swirl', pantone: '7527 C' },
	{ color: 'rgb(197, 185, 172)', name: 'Bison Hide', pantone: '7528 C' },
	{ color: 'rgb(183, 169, 154)', name: 'Malta', pantone: '7529 C' },
	{ color: 'rgb(163, 147, 130)', name: 'Donkey Brown', pantone: '7530 C' },
	{ color: 'rgb(122, 104, 85)', name: 'Coffee', pantone: '7531 C' },
	{ color: 'rgb(99, 81, 61)', name: 'Hellbraun', pantone: '7532 C' },
	{ color: 'rgb(71, 55, 41)', name: 'English Walnut', pantone: '7533 C' },
	{ color: 'rgb(209, 204, 189)', name: 'Sisal', pantone: '7534 C' },
	{ color: 'rgb(183, 176, 156)', name: 'Nomad', pantone: '7535 C' },
	{ color: 'rgb(166, 159, 136)', name: 'Tallow', pantone: '7536 C' },
	{ color: 'rgb(167, 172, 162)', name: 'Bud', pantone: '7537 C' },
	{ color: 'rgb(148, 154, 144)', name: 'Lemon Grass', pantone: '7538 C' },
	{ color: 'rgb(142, 144, 137)', name: 'Lemon Grass', pantone: '7539 C' },
	{ color: 'rgb(75, 79, 84)', name: 'Abbey', pantone: '7540 C' },
	{ color: 'rgb(217, 225, 226)', name: 'Geyser', pantone: '7541 C' },
	{ color: 'rgb(164, 188, 194)', name: 'Tower Gray', pantone: '7542 C' },
	{ color: 'rgb(152, 164, 174)', name: 'Gray Chateau', pantone: '7543 C' },
	{ color: 'rgb(118, 134, 146)', name: 'Slate Gray', pantone: '7544 C' },
	{ color: 'rgb(66, 85, 99)', name: 'Fiord', pantone: '7545 C' },
	{ color: 'rgb(37, 55, 70)', name: 'Pickled Bluewood', pantone: '7546 C' },
	{ color: 'rgb(19, 30, 41)', name: 'Mirage', pantone: '7547 C' },
	{ color: 'rgb(255, 198, 0)', name: 'Supernova', pantone: '7548 C' },
	{ color: 'rgb(255, 181, 0)', name: 'Selective Yellow', pantone: '7549 C' },
	{ color: 'rgb(209, 144, 0)', name: 'Buddha Gold', pantone: '7550 C' },
	{ color: 'rgb(180, 126, 0)', name: 'Pirate Gold', pantone: '7551 C' },
	{ color: 'rgb(115, 83, 29)', name: 'Himalaya', pantone: '7552 C' },
	{ color: 'rgb(90, 69, 34)', name: 'Irish Coffee', pantone: '7553 C' },
	{ color: 'rgb(75, 61, 42)', name: 'Judge Gray', pantone: '7554 C' },
	{ color: 'rgb(210, 159, 19)', name: 'Pizza', pantone: '7555 C' },
	{ color: 'rgb(183, 139, 32)', name: 'Nugget', pantone: '7556 C' },
	{ color: 'rgb(159, 125, 35)', name: 'Reef Gold', pantone: '7557 C' },
	{ color: 'rgb(150, 113, 38)', name: 'Kumera', pantone: '7558 C' },
	{ color: 'rgb(143, 106, 42)', name: 'Kumera', pantone: '7559 C' },
	{ color: 'rgb(125, 98, 46)', name: 'Pesto', pantone: '7560 C' },
	{ color: 'rgb(108, 93, 52)', name: 'Yellow Metal', pantone: '7561 C' },
	{ color: 'rgb(189, 155, 96)', name: 'Twine', pantone: '7562 C' },
	{ color: 'rgb(214, 154, 45)', name: 'Hokey Pokey', pantone: '7563 C' },
	{ color: 'rgb(219, 138, 6)', name: 'Tahiti Gold', pantone: '7564 C' },
	{ color: 'rgb(205, 121, 37)', name: 'Ochre', pantone: '7565 C' },
	{ color: 'rgb(173, 100, 51)', name: 'Copper', pantone: '7566 C' },
	{ color: 'rgb(137, 83, 47)', name: 'Mule Fawn', pantone: '7567 C' },
	{ color: 'rgb(119, 81, 53)', name: 'Shingle Fawn', pantone: '7568 C' },
	{ color: 'rgb(215, 136, 37)', name: 'Brandy Punch', pantone: '7569 C' },
	{ color: 'rgb(211, 131, 43)', name: 'Brandy Punch', pantone: '7570 C' },
	{ color: 'rgb(198, 125, 48)', name: 'Brandy Punch', pantone: '7571 C' },
	{ color: 'rgb(182, 114, 51)', name: 'Copper', pantone: '7572 C' },
	{ color: 'rgb(167, 102, 43)', name: 'Paarl', pantone: '7573 C' },
	{ color: 'rgb(158, 106, 56)', name: 'Sepia Skin', pantone: '7574 C' },
	{ color: 'rgb(131, 93, 50)', name: 'Potters Clay', pantone: '7575 C' },
	{ color: 'rgb(219, 134, 78)', name: 'Raw Sienna', pantone: '7576 C' },
	{ color: 'rgb(224, 126, 60)', name: 'Red Damask', pantone: '7577 C' },
	{ color: 'rgb(220, 107, 47)', name: 'Flame Pea', pantone: '7578 C' },
	{ color: 'rgb(220, 88, 42)', name: 'Flame Pea', pantone: '7579 C' },
	{ color: 'rgb(192, 81, 49)', name: 'Tuscany', pantone: '7580 C' },
	{ color: 'rgb(134, 74, 51)', name: 'Mule Fawn', pantone: '7581 C' },
	{ color: 'rgb(103, 71, 54)', name: 'Millbrook', pantone: '7582 C' },
	{ color: 'rgb(196, 98, 45)', name: 'Tuscany', pantone: '7583 C' },
	{ color: 'rgb(186, 88, 38)', name: 'Tuscany', pantone: '7584 C' },
	{ color: 'rgb(175, 92, 55)', name: 'Brown Rust', pantone: '7585 C' },
	{ color: 'rgb(158, 83, 48)', name: 'Paarl', pantone: '7586 C' },
	{ color: 'rgb(146, 76, 46)', name: 'Mule Fawn', pantone: '7587 C' },
	{ color: 'rgb(123, 77, 53)', name: 'Old Copper', pantone: '7588 C' },
	{ color: 'rgb(92, 71, 56)', name: 'Millbrook', pantone: '7589 C' },
	{ color: 'rgb(212, 181, 158)', name: 'Cameo', pantone: '7590 C' },
	{ color: 'rgb(192, 125, 89)', name: 'Antique Brass', pantone: '7591 C' },
	{ color: 'rgb(177, 85, 51)', name: 'Medium Carmine', pantone: '7592 C' },
	{ color: 'rgb(157, 67, 44)', name: 'Paarl', pantone: '7593 C' },
	{ color: 'rgb(124, 58, 45)', name: 'Nutmeg', pantone: '7594 C' },
	{ color: 'rgb(107, 61, 46)', name: 'Spice', pantone: '7595 C' },
	{ color: 'rgb(92, 61, 49)', name: 'Millbrook', pantone: '7596 C' },
	{ color: 'rgb(209, 65, 36)', name: 'Punch', pantone: '7597 C' },
	{ color: 'rgb(189, 71, 42)', name: 'Tuscany', pantone: '7598 C' },
	{ color: 'rgb(179, 61, 38)', name: 'Tall Poppy', pantone: '7599 C' },
	{ color: 'rgb(141, 63, 43)', name: 'Mule Fawn', pantone: '7600 C' },
	{ color: 'rgb(131, 65, 44)', name: 'Nutmeg', pantone: '7601 C' },
	{ color: 'rgb(123, 73, 49)', name: 'Old Copper', pantone: '7602 C' },
	{ color: 'rgb(103, 66, 48)', name: 'Quincy', pantone: '7603 C' },
	{ color: 'rgb(228, 213, 211)', name: 'Wafer', pantone: '7604 C' },
	{ color: 'rgb(225, 187, 180)', name: 'Cavern Pink', pantone: '7605 C' },
	{ color: 'rgb(214, 147, 138)', name: 'My Pink', pantone: '7606 C' },
	{ color: 'rgb(194, 110, 96)', name: 'Contessa', pantone: '7607 C' },
	{ color: 'rgb(164, 73, 61)', name: 'Apple Blossom', pantone: '7608 C' },
	{ color: 'rgb(130, 59, 52)', name: 'Sanguine Brown', pantone: '7609 C' },
	{ color: 'rgb(104, 52, 49)', name: 'Buccaneer', pantone: '7610 C' },
	{ color: 'rgb(221, 188, 176)', name: 'Cavern Pink', pantone: '7611 C' },
	{ color: 'rgb(202, 154, 142)', name: 'Oriental Pink', pantone: '7612 C' },
	{ color: 'rgb(188, 138, 126)', name: 'Brandy Rose', pantone: '7613 C' },
	{ color: 'rgb(163, 127, 116)', name: 'Pharlap', pantone: '7614 C' },
	{ color: 'rgb(134, 103, 97)', name: 'Sand Dune', pantone: '7615 C' },
	{ color: 'rgb(107, 76, 76)', name: 'Ferra', pantone: '7616 C' },
	{ color: 'rgb(88, 61, 62)', name: 'Congo Brown', pantone: '7617 C' },
	{ color: 'rgb(198, 110, 78)', name: 'Fuzzy Wuzzy Brown', pantone: '7618 C' },
	{ color: 'rgb(192, 76, 54)', name: 'Mojo', pantone: '7619 C' },
	{ color: 'rgb(183, 49, 44)', name: 'Tall Poppy', pantone: '7620 C' },
	{ color: 'rgb(171, 35, 40)', name: 'Mexican Red', pantone: '7621 C' },
	{ color: 'rgb(147, 39, 44)', name: 'Burnt Umber', pantone: '7622 C' },
	{ color: 'rgb(138, 42, 43)', name: 'Burnt Umber', pantone: '7623 C' },
	{ color: 'rgb(128, 47, 45)', name: 'Nutmeg', pantone: '7624 C' },
	{ color: 'rgb(225, 82, 61)', name: 'Cinnabar', pantone: '7625 C' },
	{ color: 'rgb(198, 53, 39)', name: 'Tall Poppy', pantone: '7626 C' },
	{ color: 'rgb(167, 43, 42)', name: 'Mexican Red', pantone: '7627 C' },
	{ color: 'rgb(158, 42, 43)', name: 'Mexican Red', pantone: '7628 C' },
	{ color: 'rgb(109, 51, 50)', name: 'Buccaneer', pantone: '7629 C' },
	{ color: 'rgb(99, 50, 49)', name: 'Buccaneer', pantone: '7630 C' },
	{ color: 'rgb(87, 45, 45)', name: 'Cowboy', pantone: '7631 C' },
	{ color: 'rgb(214, 201, 202)', name: 'Swiss Coffee', pantone: '7632 C' },
	{ color: 'rgb(196, 164, 167)', name: 'Thatch', pantone: '7633 C' },
	{ color: 'rgb(193, 103, 132)', name: 'Contessa', pantone: '7634 C' },
	{ color: 'rgb(198, 54, 99)', name: 'Hibiscus', pantone: '7635 C' },
	{ color: 'rgb(188, 32, 75)', name: 'Maroon Flush', pantone: '7636 C' },
	{ color: 'rgb(145, 47, 70)', name: 'Stiletto', pantone: '7637 C' },
	{ color: 'rgb(126, 45, 64)', name: 'Solid Pink', pantone: '7638 C' },
	{ color: 'rgb(147, 109, 115)', name: 'Opium', pantone: '7639 C' },
	{ color: 'rgb(147, 64, 84)', name: 'Vin Rouge', pantone: '7640 C' },
	{ color: 'rgb(142, 44, 72)', name: 'Stiletto', pantone: '7641 C' },
	{ color: 'rgb(115, 46, 74)', name: 'Finn', pantone: '7642 C' },
	{ color: 'rgb(103, 46, 69)', name: 'Finn', pantone: '7643 C' },
	{ color: 'rgb(88, 45, 64)', name: 'Livid Brown', pantone: '7644 C' },
	{ color: 'rgb(80, 43, 58)', name: 'Livid Brown', pantone: '7645 C' },
	{ color: 'rgb(165, 110, 135)', name: 'Strikemaster', pantone: '7646 C' },
	{ color: 'rgb(168, 61, 114)', name: 'Dunkelrosa', pantone: '7647 C' },
	{ color: 'rgb(153, 30, 102)', name: 'Disco', pantone: '7648 C' },
	{ color: 'rgb(138, 27, 97)', name: 'Disco', pantone: '7649 C' },
	{ color: 'rgb(114, 34, 87)', name: 'Tawny Port', pantone: '7650 C' },
	{ color: 'rgb(106, 42, 91)', name: 'Finn', pantone: '7651 C' },
	{ color: 'rgb(94, 39, 81)', name: 'Finn', pantone: '7652 C' },
	{ color: 'rgb(148, 135, 148)', name: 'Venus', pantone: '7653 C' },
	{ color: 'rgb(162, 120, 156)', name: 'Bouquet', pantone: '7654 C' },
	{ color: 'rgb(161, 90, 149)', name: 'Strikemaster', pantone: '7655 C' },
	{ color: 'rgb(142, 58, 128)', name: 'Plum', pantone: '7656 C' },
	{ color: 'rgb(110, 43, 98)', name: 'Finn', pantone: '7657 C' },
	{ color: 'rgb(106, 52, 96)', name: 'Cosmic', pantone: '7658 C' },
	{ color: 'rgb(93, 55, 84)', name: 'Eggplant', pantone: '7659 C' },
	{ color: 'rgb(153, 145, 164)', name: 'Amethyst Smoke', pantone: '7660 C' },
	{ color: 'rgb(141, 110, 151)', name: 'Trendy Pink', pantone: '7661 C' },
	{ color: 'rgb(122, 65, 131)', name: 'Affair', pantone: '7662 C' },
	{ color: 'rgb(107, 48, 119)', name: 'Eminence', pantone: '7663 C' },
	{ color: 'rgb(101, 50, 121)', name: 'Eminence', pantone: '7664 C' },
	{ color: 'rgb(94, 54, 110)', name: 'Bossanova', pantone: '7665 C' },
	{ color: 'rgb(92, 78, 99)', name: 'Scarpa Flow', pantone: '7666 C' },
	{ color: 'rgb(110, 124, 160)', name: 'Lynch', pantone: '7667 C' },
	{ color: 'rgb(104, 110, 159)', name: 'Kimberly', pantone: '7668 C' },
	{ color: 'rgb(97, 94, 155)', name: 'Scampi', pantone: '7669 C' },
	{ color: 'rgb(86, 82, 148)', name: 'Butterfly Bush', pantone: '7670 C' },
	{ color: 'rgb(81, 70, 137)', name: 'Victoria', pantone: '7671 C' },
	{ color: 'rgb(76, 65, 132)', name: 'Victoria', pantone: '7672 C' },
	{ color: 'rgb(83, 84, 134)', name: 'East Bay', pantone: '7673 C' },
	{ color: 'rgb(135, 140, 180)', name: 'Wild Blue Yonder', pantone: '7674 C' },
	{ color: 'rgb(124, 127, 171)', name: 'Kimberly', pantone: '7675 C' },
	{ color: 'rgb(117, 102, 160)', name: 'Violett', pantone: '7676 C' },
	{ color: 'rgb(111, 80, 145)', name: 'Butterfly Bush', pantone: '7677 C' },
	{ color: 'rgb(104, 71, 141)', name: 'Affair', pantone: '7678 C' },
	{ color: 'rgb(86, 61, 130)', name: 'Victoria', pantone: '7679 C' },
	{ color: 'rgb(82, 49, 120)', name: 'Minsk', pantone: '7680 C' },
	{ color: 'rgb(148, 169, 203)', name: 'Polo Blue', pantone: '7681 C' },
	{ color: 'rgb(103, 135, 183)', name: 'Ship Cove', pantone: '7682 C' },
	{ color: 'rgb(66, 109, 169)', name: 'San Marino', pantone: '7683 C' },
	{ color: 'rgb(56, 94, 157)', name: 'Azure', pantone: '7684 C' },
	{ color: 'rgb(44, 86, 151)', name: 'St Tropaz', pantone: '7685 C' },
	{ color: 'rgb(29, 79, 145)', name: 'Matisse', pantone: '7686 C' },
	{ color: 'rgb(29, 66, 138)', name: 'Chathams Blue', pantone: '7687 C' },
	{ color: 'rgb(70, 152, 203)', name: 'Shakespeare', pantone: '7688 C' },
	{ color: 'rgb(41, 143, 194)', name: 'Curious Blue', pantone: '7689 C' },
	{ color: 'rgb(0, 118, 168)', name: 'Deep Cerulean', pantone: '7690 C' },
	{ color: 'rgb(0, 98, 152)', name: 'Bahama Blue', pantone: '7691 C' },
	{ color: 'rgb(0, 85, 135)', name: 'Orient', pantone: '7692 C' },
	{ color: 'rgb(0, 73, 118)', name: 'Regal Blue', pantone: '7693 C' },
	{ color: 'rgb(1, 66, 106)', name: 'Regal Blue', pantone: '7694 C' },
	{ color: 'rgb(123, 167, 188)', name: 'Neptune', pantone: '7695 C' },
	{ color: 'rgb(99, 153, 174)', name: 'Hippie Blue', pantone: '7696 C' },
	{ color: 'rgb(78, 135, 160)', name: 'Wedgewood', pantone: '7697 C' },
	{ color: 'rgb(65, 116, 141)', name: 'Ming', pantone: '7698 C' },
	{ color: 'rgb(52, 101, 127)', name: 'Ming', pantone: '7699 C' },
	{ color: 'rgb(22, 92, 125)', name: 'Chathams Blue', pantone: '7700 C' },
	{ color: 'rgb(0, 87, 118)', name: 'Orient', pantone: '7701 C' },
	{ color: 'rgb(72, 169, 197)', name: 'Pelorous', pantone: '7702 C' },
	{ color: 'rgb(0, 156, 189)', name: 'Pacific Blue', pantone: '7703 C' },
	{ color: 'rgb(0, 133, 173)', name: 'Deep Cerulean', pantone: '7704 C' },
	{ color: 'rgb(0, 112, 150)', name: 'Allports', pantone: '7705 C' },
	{ color: 'rgb(0, 106, 142)', name: 'Color 7706c', pantone: '7706 C' },
	{ color: 'rgb(0, 97, 127)', name: 'Orient', pantone: '7707 C' },
	{ color: 'rgb(0, 86, 112)', name: 'Blue Stone', pantone: '7708 C' },
	{ color: 'rgb(99, 177, 188)', name: 'Fountain Blue', pantone: '7709 C' },
	{ color: 'rgb(0, 167, 181)', name: 'Bondi Blue', pantone: '7710 C' },
	{ color: 'rgb(0, 151, 169)', name: 'Bondi Blue', pantone: '7711 C' },
	{ color: 'rgb(0, 133, 155)', name: 'Deep Cerulean', pantone: '7712 C' },
	{ color: 'rgb(0, 125, 138)', name: 'Blue Lagoon', pantone: '7713 C' },
	{ color: 'rgb(0, 118, 128)', name: 'Teal', pantone: '7714 C' },
	{ color: 'rgb(0, 98, 105)', name: 'Blue Stone', pantone: '7715 C' },
	{ color: 'rgb(0, 150, 143)', name: 'Persian Green', pantone: '7716 C' },
	{ color: 'rgb(0, 133, 125)', name: 'Teal', pantone: '7717 C' },
	{ color: 'rgb(0, 118, 114)', name: 'Pine Green', pantone: '7718 C' },
	{ color: 'rgb(0, 109, 104)', name: 'Tropical Rain Forest', pantone: '7719 C' },
	{ color: 'rgb(0, 99, 91)', name: 'Blue Stone', pantone: '7720 C' },
	{ color: 'rgb(0, 94, 93)', name: 'Blue Stone', pantone: '7721 C' },
	{ color: 'rgb(0, 81, 81)', name: 'Sherpa Blue', pantone: '7722 C' },
	{ color: 'rgb(80, 166, 132)', name: 'Aqua Forest', pantone: '7723 C' },
	{ color: 'rgb(0, 150, 108)', name: 'Green Haze', pantone: '7724 C' },
	{ color: 'rgb(0, 135, 85)', name: 'Deep Sea', pantone: '7725 C' },
	{ color: 'rgb(0, 123, 75)', name: 'Tropical Rain Forest', pantone: '7726 C' },
	{ color: 'rgb(0, 111, 68)', name: 'Fun Green', pantone: '7727 C' },
	{ color: 'rgb(0, 104, 69)', name: 'Fun Green', pantone: '7728 C' },
	{ color: 'rgb(0, 88, 68)', name: 'Aqua Deep', pantone: '7729 C' },
	{ color: 'rgb(75, 149, 96)', name: 'Fruit Salad', pantone: '7730 C' },
	{ color: 'rgb(34, 136, 72)', name: 'Eucalyptus', pantone: '7731 C' },
	{ color: 'rgb(0, 122, 62)', name: 'Fun Green', pantone: '7732 C' },
	{ color: 'rgb(0, 112, 65)', name: 'Fun Green', pantone: '7733 C' },
	{ color: 'rgb(40, 97, 64)', name: 'Plantation', pantone: '7734 C' },
	{ color: 'rgb(54, 87, 59)', name: 'Tom Thumb', pantone: '7735 C' },
	{ color: 'rgb(57, 85, 66)', name: 'Tom Thumb', pantone: '7736 C' },
	{ color: 'rgb(107, 165, 57)', name: 'Sushi', pantone: '7737 C' },
	{ color: 'rgb(72, 162, 63)', name: 'Apple', pantone: '7738 C' },
	{ color: 'rgb(49, 155, 66)', name: 'Sea Green', pantone: '7739 C' },
	{ color: 'rgb(58, 145, 63)', name: 'Apple', pantone: '7740 C' },
	{ color: 'rgb(68, 136, 62)', name: 'Goblin', pantone: '7741 C' },
	{ color: 'rgb(74, 119, 60)', name: 'Fern Green', pantone: '7742 C' },
	{ color: 'rgb(68, 105, 61)', name: 'Chalet Green', pantone: '7743 C' },
	{ color: 'rgb(186, 188, 22)', name: 'La Rioja', pantone: '7744 C' },
	{ color: 'rgb(171, 173, 35)', name: 'Lemon Ginger', pantone: '7745 C' },
	{ color: 'rgb(153, 155, 48)', name: 'Luxor Gold', pantone: '7746 C' },
	{ color: 'rgb(136, 141, 48)', name: 'Sycamore', pantone: '7747 C' },
	{ color: 'rgb(124, 128, 52)', name: 'Pesto', pantone: '7748 C' },
	{ color: 'rgb(114, 115, 55)', name: 'Yellow Metal', pantone: '7749 C' },
	{ color: 'rgb(101, 102, 53)', name: 'Costa Del Sol', pantone: '7750 C' },
	{ color: 'rgb(202, 182, 75)', name: 'Turmeric', pantone: '7751 C' },
	{ color: 'rgb(207, 176, 35)', name: 'Hokey Pokey', pantone: '7752 C' },
	{ color: 'rgb(193, 160, 30)', name: 'Nugget', pantone: '7753 C' },
	{ color: 'rgb(160, 134, 41)', name: 'Luxor Gold', pantone: '7754 C' },
	{ color: 'rgb(137, 118, 48)', name: 'Pesto', pantone: '7755 C' },
	{ color: 'rgb(115, 102, 53)', name: 'Yellow Metal', pantone: '7756 C' },
	{ color: 'rgb(103, 94, 51)', name: 'Costa Del Sol', pantone: '7757 C' },
	{ color: 'rgb(212, 195, 4)', name: 'Corn', pantone: '7758 C' },
	{ color: 'rgb(196, 178, 0)', name: 'Buddha Gold', pantone: '7759 C' },
	{ color: 'rgb(145, 133, 44)', name: 'Luxor Gold', pantone: '7760 C' },
	{ color: 'rgb(116, 113, 54)', name: 'Yellow Metal', pantone: '7761 C' },
	{ color: 'rgb(93, 100, 57)', name: 'Verdigris', pantone: '7762 C' },
	{ color: 'rgb(88, 92, 59)', name: 'Hemlock', pantone: '7763 C' },
	{ color: 'rgb(83, 84, 53)', name: 'Hemlock', pantone: '7764 C' },
	{ color: 'rgb(187, 179, 35)', name: 'Hokey Pokey', pantone: '7765 C' },
	{ color: 'rgb(180, 169, 31)', name: 'Lucky', pantone: '7766 C' },
	{ color: 'rgb(170, 157, 46)', name: 'Alpine', pantone: '7767 C' },
	{ color: 'rgb(143, 126, 53)', name: 'Sycamore', pantone: '7768 C' },
	{ color: 'rgb(113, 97, 53)', name: 'Yellow Metal', pantone: '7769 C' },
	{ color: 'rgb(99, 89, 57)', name: 'Verdigris', pantone: '7770 C' },
	{ color: 'rgb(78, 73, 52)', name: 'Mondo', pantone: '7771 C' },
	{ color: 'rgb(0, 154, 206)', name: 'Pacific Blue', pantone: '801 C' },
	{ color: 'rgb(68, 214, 44)', name: 'Lima', pantone: '802 C' },
	{ color: 'rgb(255, 233, 0)', name: 'Turbo', pantone: '803 C' },
	{ color: 'rgb(255, 170, 77)', name: 'Yellow Orange', pantone: '804 C' },
	{ color: 'rgb(255, 114, 118)', name: 'Bittersweet', pantone: '805 C' },
	{ color: 'rgb(255, 62, 181)', name: 'Razzle Dazzle Rose', pantone: '806 C' },
	{ color: 'rgb(234, 39, 194)', name: 'Shocking Pink', pantone: '807 C' },
	{ color: 'rgb(132, 117, 78)', name: 'Shadow', pantone: '871 C' },
	{ color: 'rgb(133, 113, 77)', name: 'Shadow', pantone: '872 C' },
	{ color: 'rgb(134, 109, 75)', name: 'Shadow', pantone: '873 C' },
	{ color: 'rgb(139, 111, 78)', name: 'Shadow', pantone: '874 C' },
	{ color: 'rgb(135, 103, 79)', name: 'Shadow', pantone: '875 C' },
	{ color: 'rgb(139, 99, 75)', name: 'Spicy Mix', pantone: '876 C' },
	{ color: 'rgb(138, 141, 143)', name: 'Oslo Gray', pantone: '877 C' },
	{ color: 'rgb(158, 151, 142)', name: 'Zorba', pantone: 'Black 0961 C' },
	{ color: 'rgb(51, 47, 33)', name: 'Birch', pantone: 'Black 2 C' },
	{ color: 'rgb(33, 39, 33)', name: 'Heavy Metal', pantone: 'Black 3 C' },
	{ color: 'rgb(49, 38, 29)', name: 'Zeus', pantone: 'Black 4 C' },
	{ color: 'rgb(62, 43, 46)', name: 'Woody Brown', pantone: 'Black 5 C' },
	{ color: 'rgb(16, 24, 32)', name: 'Bunker', pantone: 'Black 6 C' },
	{ color: 'rgb(61, 57, 53)', name: 'Merlin', pantone: 'Black 7 C' },
	{ color: 'rgb(45, 41, 38)', name: 'Dune', pantone: 'Black C' },
	{ color: 'rgb(0, 0, 0)', name: 'Schwarz', pantone: 'Black' },
	{ color: 'rgb(16, 6, 159)', name: 'Ultramarine', pantone: 'Blue 072 C' },
	{ color: 'rgb(116, 209, 234)', name: 'Sky Blue', pantone: 'Blue 0821 C' },
	{ color: 'rgb(0, 176, 139)', name: 'Persian Green', pantone: 'Bright Green C' },
	{ color: 'rgb(255, 94, 0)', name: 'Blaze Orange', pantone: 'Bright Orange C' },
	{ color: 'rgb(249, 56, 34)', name: 'Pomegranate', pantone: 'Bright Red C' },
	{ color: 'rgb(99, 102, 106)', name: 'Shuttle Gray', pantone: 'Cool Gray 10 C' },
	{ color: 'rgb(83, 86, 90)', name: 'Abbey', pantone: 'Cool Gray 11 C' },
	{ color: 'rgb(217, 217, 214)', name: 'Quill Gray', pantone: 'Cool Gray 1 C' },
	{ color: 'rgb(208, 208, 206)', name: 'Quill Gray', pantone: 'Cool Gray 2 C' },
	{ color: 'rgb(200, 201, 199)', name: 'Kangaroo', pantone: 'Cool Gray 3 C' },
	{ color: 'rgb(187, 188, 188)', name: 'Silver Sand', pantone: 'Cool Gray 4 C' },
	{ color: 'rgb(177, 179, 179)', name: 'Edward', pantone: 'Cool Gray 5 C' },
	{ color: 'rgb(167, 168, 170)', name: 'Aluminium', pantone: 'Cool Gray 6 C' },
	{ color: 'rgb(151, 153, 155)', name: 'Oslo Gray', pantone: 'Cool Gray 7 C' },
	{ color: 'rgb(136, 139, 141)', name: 'Oslo Gray', pantone: 'Cool Gray 8 C' },
	{ color: 'rgb(117, 120, 123)', name: 'Rolling Stone', pantone: 'Cool Gray 9 C' },
	{ color: 'rgb(0, 35, 156)', name: 'International Klein Blue', pantone: 'Dark Blue C' },
	{ color: 'rgb(157, 231, 215)', name: 'Water Leaf', pantone: 'Green 0921 C' },
	{ color: 'rgb(0, 171, 132)', name: 'Persian Green', pantone: 'Green C' },
	{ color: 'rgb(241, 178, 220)', name: 'Azalea', pantone: 'Magenta 0521 C' },
	{ color: 'rgb(0, 132, 202)', name: 'Lochmara', pantone: 'Medium Blue C' },
	{ color: 'rgb(78, 0, 142)', name: 'Pigment Indigo', pantone: 'Medium Purple C' },
	{ color: 'rgb(255, 217, 0)', name: 'School bus Yellow', pantone: 'Medium Yellow C' },
	{ color: 'rgb(34, 34, 35)', name: 'Shark', pantone: 'Neutral Black C' },
	{ color: 'rgb(254, 80, 0)', name: 'International Orange', pantone: 'Orange 021 C' },
	{ color: 'rgb(214, 37, 152)', name: 'Cerise', pantone: 'Pink C' },
	{ color: 'rgb(0, 133, 202)', name: 'Lochmara', pantone: 'Process Blue C' },
	{ color: 'rgb(187, 41, 187)', name: 'Medium Red Violet', pantone: 'Purple C' },
	{ color: 'rgb(239, 51, 64)', name: 'Amaranth', pantone: 'Red 032 C' },
	{ color: 'rgb(252, 174, 187)', name: 'Lavender Pink', pantone: 'Red 0331 C' },
	{ color: 'rgb(0, 20, 137)', name: 'Resolution Blue', pantone: 'Reflex Blue C' },
	{ color: 'rgb(225, 0, 152)', name: 'Hollywood Cerise', pantone: 'Rhodamine Red C' },
	{ color: 'rgb(206, 0, 88)', name: 'Razzmatazz', pantone: 'Rubine Red C' },
	{ color: 'rgb(206, 0, 86)', name: 'Razzmatazz', pantone: 'Strong Red C' },
	{ color: 'rgb(191, 155, 222)', name: 'Light Wisteria', pantone: 'Violet 0631 C' },
	{ color: 'rgb(68, 0, 153)', name: 'Pigment Indigo', pantone: 'Violet C' },
	{ color: 'rgb(121, 110, 101)', name: 'Sandstone', pantone: 'Warm Gray 10 C' },
	{ color: 'rgb(110, 98, 89)', name: 'Pine Cone', pantone: 'Warm Gray 11 C' },
	{ color: 'rgb(215, 210, 203)', name: 'Timberwolf', pantone: 'Warm Gray 1 C' },
	{ color: 'rgb(203, 196, 188)', name: 'Silver Rust', pantone: 'Warm Gray 2 C' },
	{ color: 'rgb(191, 184, 175)', name: 'Tide', pantone: 'Warm Gray 3 C' },
	{ color: 'rgb(182, 173, 165)', name: 'Silk', pantone: 'Warm Gray 4 C' },
	{ color: 'rgb(172, 163, 154)', name: 'Bronco', pantone: 'Warm Gray 5 C' },
	{ color: 'rgb(165, 156, 148)', name: 'Zorba', pantone: 'Warm Gray 6 C' },
	{ color: 'rgb(150, 140, 131)', name: 'Schooner', pantone: 'Warm Gray 7 C' },
	{ color: 'rgb(140, 130, 121)', name: 'Squirrel', pantone: 'Warm Gray 8 C' },
	{ color: 'rgb(131, 120, 111)', name: 'Americano', pantone: 'Warm Gray 9 C' },
	{ color: 'rgb(249, 66, 58)', name: 'Sunset Orange', pantone: 'Warm Red C' },
	{ color: 'rgb(255, 255, 255)', name: 'Weiß', pantone: 'White' },
	{ color: 'rgb(242, 240, 161)', name: 'Sandwisp', pantone: 'Yellow 0131 C' },
	{ color: 'rgb(254, 221, 0)', name: 'School bus Yellow', pantone: 'Yellow C' },
]
