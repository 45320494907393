<template>
	<v-dialog v-model="showModal" width="500" transition="dialog-bottom-transition">
		<v-card id="continue-configuration-modal">
			<v-toolbar dark color="black" elevation="0" tile class="toolbar">
				<!-- <v-btn icon dark @click="showModal = false">
					<v-icon>mdi-close</v-icon>
				</v-btn> -->
				<v-toolbar-title>{{ $getDictString('configurator_continue_config_modal_heading') }}</v-toolbar-title>
				<v-spacer></v-spacer>
				<!-- <v-toolbar-items>
					<v-btn dark text @click="dialog = false"> Save </v-btn>
				</v-toolbar-items> -->
			</v-toolbar>
			<!-- <v-card-text> -->
			<div class="d-flex flex-wrap color-scroll-box align-content-start pa-5">
				{{ $getDictString('configurator_continue_config_modal_message') }}
			</div>
			<!-- </v-card-text> -->

			<v-divider></v-divider>

			<v-card-actions>
				<v-btn color="primary" class="weakBtn" text @click="newConfig">{{
					$getDictString('configurator_continue_config_modal_start_new_button')
				}}</v-btn>
				<v-spacer></v-spacer>
				<v-btn color="primary" class="strongBtn" text @click="showModal = false">{{
					$getDictString('configurator_continue_config_modal_continue_button')
				}}</v-btn>
			</v-card-actions>
		</v-card>
	</v-dialog>
</template>

<script>
import { mapFields } from 'vuex-map-fields'

export default {
	name: 'ContinueConfigurationModal',
	computed: {
		...mapFields('ConfiguratorDisplay', {
			showModal: 'continueConfigurationModal',
		}),
	},
	methods: {
		newConfig() {
			this.$store.dispatch('Configurator/resetEverything').then(() => {
				this.showModal = false
			})
		},
	},
}
</script>

<style lang="sass">
#continue-configuration-modal
	font-weight: 700
	.toolbar, .v-toolbar
		border-radius: 4px 4px 0 0 !important
		border: solid 1px transparent
		.v-toolbar__title
			font-size: 2em !important
	.strongBtn
		background: black
		color: white !important
		text-transform: none
		font-weight: 700
		font-size: 1em
		letter-spacing: -0.01em
		padding: 20px 20px
	.weakBtn
		background: white
		color: black !important
		text-transform: none
		font-weight: 700
		font-size: 1em
		letter-spacing: -0.01em
		padding: 20px 20px
		border: solid 2px black
</style>