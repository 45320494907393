<template>
	<div class="ani-container">
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 132.097 141.194">
      <g id="LONGSLEEVE_FRONT" transform="translate(-162.383 -827.243)">
        <g id="LINES">
          <path id="Path_1458" data-name="Path 1458" d="M144.939,501.806a24.69,24.69,0,0,0-7.652-3.121c-4.617-1.151-6.165-2.245-6.165-2.245s-8.878-3.611-11.4-4.3" transform="translate(131 338)" fill="none" stroke="#5a5b5e" stroke-width="0.06" stroke-dasharray="0.478 0.239"/>
          <path id="Path_1459" data-name="Path 1459" d="M77.39,492.159c-2.521.687-11.4,4.3-11.4,4.3s-1.549,1.1-6.165,2.246a24.726,24.726,0,0,0-7.655,3.122c-.826.494-.234,1.534.343,3.506s2.853,12.818,2.853,12.818c.926,4.711,4.208,22.843,4.208,22.843s.329,1.718.672,3.824c.2,1.248.153.689.3,1.935l.038.283a9.3,9.3,0,0,1,.227,2.4c.005.96.163,2.137.161,3.592,0,4.066.111,9,.116,9.271s.6,8.709.986,13.2.163,6.3-.33,7.505a67.61,67.61,0,0,0-2.444,7.341c-.628,2.52-1.691,9.369-.791,11.339a28.42,28.42,0,0,0,2.4,4.054,1.243,1.243,0,0,1,.075,1.535,14.021,14.021,0,0,0-1.6,3.507,25.1,25.1,0,0,1-.67,3.4,14.612,14.612,0,0,0-.314,4.711,5.067,5.067,0,0,1-.029,2.354c-.165.441-.7,1.918.355,2.685a22.774,22.774,0,0,0,14.013,3.506c7.663-.494,11.968-.818,13.88-1.368s7.607-.658,11.663-.327,9.322.657,14.056,2.465,12.647,2.463,15.491,2.024,6.1-2.631,6.693-3.614.689-1.755.85-1.92a38.033,38.033,0,0,0-.079-6.463c-.2-2.85-.607-4.82-.733-6.188a22.011,22.011,0,0,0-.711-4.275c-.4-1.262-1.636-3.89-1.224-5.863s1.158-3.941,1.208-4.218A33.243,33.243,0,0,0,135,589.359c-.051-4.107-.267-21.691-.876-26.4a6.772,6.772,0,0,1,.308-1.918,91.654,91.654,0,0,0,1.234-10.683c.129-2.957,1.466-10.077,1.466-10.077s3.279-18.133,4.205-22.844c0,0,2.277-10.846,2.855-12.821s1.168-3.01.34-3.5a24.59,24.59,0,0,0-7.653-3.123c-4.616-1.153-6.163-2.248-6.163-2.248s-8.877-3.607-11.4-4.3" transform="translate(131 338)" fill="none" stroke="#464749" stroke-width="0.121"/>
          <path id="Path_1460" data-name="Path 1460" d="M51.765,502.518a24.745,24.745,0,0,1,7.653-3.121c4.615-1.149,6.163-2.245,6.163-2.245s8.879-3.609,11.4-4.3" transform="translate(131 338)" fill="none" stroke="#5a5b5e" stroke-width="0.06" stroke-dasharray="0.478 0.239"/>
          <path id="Path_1461" data-name="Path 1461" d="M58.509,619.88s4.4,2.4,7.912,2.622a37.877,37.877,0,0,0,8.834-.437,131.178,131.178,0,0,1,15.238-.756c3.289.136,11.922,1.024,12.58.988a98.29,98.29,0,0,1,11.046,1.708,110.4,110.4,0,0,0,13.925,2.37c2.413.071,6.964-2.49,7.419-4.677" transform="translate(131 338)" fill="none" stroke="#5a5b5e" stroke-width="0.06" stroke-dasharray="0.478 0.239"/>
          <path id="Path_1462" data-name="Path 1462" d="M58.5,619.277s4.407,2.406,7.914,2.623a37.839,37.839,0,0,0,8.833-.439,132.764,132.764,0,0,1,15.237-.757c3.29.139,11.925,1.027,12.581.989a98.29,98.29,0,0,1,11.046,1.71,110.969,110.969,0,0,0,13.926,2.37c2.412.071,6.96-2.49,7.418-4.679" transform="translate(131 338)" fill="none" stroke="#5a5b5e" stroke-width="0.06" stroke-dasharray="0.478 0.239"/>
          <path id="Path_1463" data-name="Path 1463" d="M134.351,610.115" transform="translate(131 338)" fill="#1a1818"/>
          <path id="Path_1464" data-name="Path 1464" d="M115.6,493.15a7.5,7.5,0,0,0,.642-1.3s.631-1.759-.918-2.323c-2.782-.211-10-.237-13.643-.2-4.375.043-14.65,0-17.31.3-1.054.115-1.809.158-2.375.191-.147.07-2.016,1-1.526,2.232a7.46,7.46,0,0,0,.878,1.433" transform="translate(131 338)" fill="none" stroke="#464749" stroke-width="0.121"/>
          <path id="Path_1465" data-name="Path 1465" d="M76.964,493.813c4.274,8.226,14.851,9.647,14.851,9.647s9.035,1.761,16.865-.851c5.4-1.8,9.473-4.88,11.1-9.063a1.934,1.934,0,0,0-.04-1.944c-1.209-.683-2.743-1.654-3.325-1.92a4.883,4.883,0,0,0-1.083-.153c1.549.564.918,2.323.918,2.323-1.461,3.972-6.517,6.3-9.137,7.224a28.717,28.717,0,0,1-17.461.1,15.134,15.134,0,0,1-9.174-7.128c-.49-1.234,1.379-2.162,1.526-2.232a3.913,3.913,0,0,0-1.662.268c-.54.3-2.164,1.458-3.131,1.937A1.527,1.527,0,0,0,76.964,493.813Z" transform="translate(131 338)" fill="none" stroke="#464749" stroke-width="0.121"/>
          <path id="Path_1466" data-name="Path 1466" d="M81.014,492.846a143.268,143.268,0,0,1,17.191-1.034c9.529,0,15.348.218,17.768.708l-.791,1.147s-3.966-.571-17.025-.571a128.194,128.194,0,0,0-16.19,1.079Z" transform="translate(131 338)" fill="none" stroke="#464749" stroke-width="0.121"/>
          <path id="Path_1467" data-name="Path 1467" d="M77.637,491.8a1.5,1.5,0,0,0-.238,1.756c4.187,8.057,14.546,9.451,14.546,9.451s8.85,1.719,16.518-.836c5.29-1.76,9.279-4.777,10.867-8.877a1.9,1.9,0,0,0-.039-1.9" transform="translate(131 338)" fill="none" stroke="#5a5b5e" stroke-width="0.06" stroke-dasharray="0.478 0.239"/>
          <path id="Path_1468" data-name="Path 1468" d="M76.795,492.253a1.546,1.546,0,0,0-.25,1.828c4.359,8.388,15.146,9.839,15.146,9.839s9.216,1.794,17.2-.869c5.511-1.834,9.661-4.974,11.313-9.242a1.975,1.975,0,0,0-.039-1.983" transform="translate(131 338)" fill="none" stroke="#5a5b5e" stroke-width="0.06" stroke-dasharray="0.478 0.239"/>
          <path id="Path_1469" data-name="Path 1469" d="M115.213,493.376s-3.98-.57-17.081-.57a128.465,128.465,0,0,0-16.246,1.084" transform="translate(131 338)" fill="none" stroke="#5a5b5e" stroke-width="0.06" stroke-dasharray="0.478 0.239"/>
          <path id="Path_1470" data-name="Path 1470" d="M80.938,493.147a143.376,143.376,0,0,1,17.25-1.04c9.559,0,15.4.219,17.826.711" transform="translate(131 338)" fill="none" stroke="#5a5b5e" stroke-width="0.06" stroke-dasharray="0.478 0.239"/>
          <path id="Path_1477" data-name="Path 1477" d="M115.983,492.459" transform="translate(131 338)" fill="#1a1818"/>
          <path id="Path_1478" data-name="Path 1478" d="M60.55,546.748s-1.872,19.582-1.958,21.3-.253,3.989-.253,3.989c-3.488,13.868-5.613,25.38-5.613,25.38-1.689,12.05-1.927,15.954-1.927,15.954s.222,7.7-.8,8.878-14.8,1.271-15.538.823-1.617-6.449-2.024-8.838c-.421-2.45-.728-8.479-.9-10.927s-.018-14.661-.018-14.661c-.381-9.109,2.4-15.792,2.852-23.18S37.659,544.2,37.659,544.2c.242-.557,3.507-10.816,3.91-12.964s2.982-15.074,3.869-17.563a56.237,56.237,0,0,1,3.5-6.868,13.513,13.513,0,0,1,2.826-4.287" transform="translate(131 338)" fill="none" stroke="#464749" stroke-width="0.134"/>
          <path id="Path_1479" data-name="Path 1479" d="M33.819,621.083a101.851,101.851,0,0,0,10.788.068c5.188-.3,6.031-.349,6.031-.349" transform="translate(131 338)" fill="none" stroke="#5a5b5e" stroke-width="0.06" stroke-dasharray="0.478 0.239"/>
          <path id="Path_1480" data-name="Path 1480" d="M33.642,620.392s5.814.323,9.016.186,3.209-.14,8.046-.422" transform="translate(131 338)" fill="none" stroke="#5a5b5e" stroke-width="0.06" stroke-dasharray="0.478 0.239"/>
          <path id="Path_1481" data-name="Path 1481" d="M136.179,546.165s2.285,19.875,2.325,21.6.149,3.992.149,3.992c.947,4.323,1.672,8.414,2.265,12.286,1.18,7.7,1.534,13.2,1.534,13.2,1.347,12.085,1.483,16,1.483,16s-.409,7.693.559,8.893,14.387,1.627,15.115,1.2,1.734-6.408,2.19-8.787c.469-2.44.917-8.459,1.143-10.9s.38-14.658.38-14.658c.594-9.1-1.252-15.825-1.51-23.222S158.62,544.2,158.62,544.2c-.237-.557-3.416-10.816-3.808-12.964s-2.906-15.074-3.77-17.563a57.092,57.092,0,0,0-3.414-6.868c-.811-1.609-1.591-3.551-2.687-4.675" transform="translate(131 338)" fill="none" stroke="#464749" stroke-width="0.133"/>
          <path id="Path_1482" data-name="Path 1482" d="M160.283,621.348a96.236,96.236,0,0,1-10.509-.189l-5.864-.494" transform="translate(131 338)" fill="none" stroke="#5a5b5e" stroke-width="0.059" stroke-dasharray="0.472 0.236"/>
          <path id="Path_1483" data-name="Path 1483" d="M160.474,620.662s-5.67.185-8.789-.03-3.119-.217-7.826-.616" transform="translate(131 338)" fill="none" stroke="#5a5b5e" stroke-width="0.059" stroke-dasharray="0.472 0.236"/>
        </g>
      </g>
    </svg>
	</div>
</template>

<script>
import { gsap } from 'gsap'
import { DrawSVGPlugin } from 'gsap/DrawSVGPlugin'

gsap.registerPlugin(DrawSVGPlugin)
export default {
	name: 'CrewneckAnimation',
	props: { type: String },
	methods: {},
	mounted() {
		gsap.from('svg path', { duration: 1, stagger: 0.01, drawSVG: 0 })
	},
}
</script>

<style lang="sass" scoped>
.ani-container
	max-height: 100%
	width: 100%
	height: 100%
	padding: 20px 10px
	display: flex
	justify-content: center
	align-items: center
	svg
		transform-origin: 50% 50%
		width: 65%
		path
			stroke-width: 0.4px
			stroke-dasharray: 5px

</style>