<template>
	<div
		:class="['trigger', { higher: type === 'textile' || type === 'sizes' }]"
		@click="changeVisibility"
		v-if="!infoBlockVisibility[this.type]"
	>
		?
	</div>
</template>

<script>
import { mapFields } from 'vuex-map-fields'

export default {
	name: 'InfoBlockTrigger',
	props: {
		type: { type: String, required: true },
	},
	computed: {
		...mapFields('ConfiguratorDisplay', {
			infoBlockVisibility: 'infoBlockVisibility',
		}),
	},
	methods: {
		changeVisibility() {
			this.infoBlockVisibility[this.type] = !this.infoBlockVisibility[this.type]
		},
	},
}
</script>

<style lang="sass" scoped>
.trigger
    position: absolute
    z-index: 210
    top: -24px
    right: 10px
    border-radius: 50% !important
    color: white
    background: black
    width: 20px
    height: 20px
    cursor: pointer
    display: flex
    align-items: center
    justify-content: center
    &.higher
        top: -25px
</style>