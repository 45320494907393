<template>
	<div class="d-flex flex-column fill-height">
		<SectionLabel :label="dictionary['configurator_finish_heading_position']" />
		<div class="button-wrapper d-flex justify-start flex-column" v-if="allowedPositionOptions[selectedSide].length > 0">
			<TextButton
				v-for="(option, idx) in allowedPositionOptions[selectedSide]"
				:key="idx"
				:label="option.label"
				:active="selectedPosition === option.value"
				@click.native="selectedPosition = option.value"
			/>
		</div>
	</div>
</template>

<script>
import { mapFields } from 'vuex-map-fields'

import { EventBus } from '@/utils/eventbus'
import PositionSettings from '@/assets/data/finishSettings.js'
import TextButton from '../../components/TextButton'
import SectionLabel from '../../components/SectionLabel'

export default {
	name: 'Technique',
	components: { TextButton, SectionLabel },
	data() {
		return {
			positionOptions: PositionSettings.positionOptions,
			maxSizes: PositionSettings.maxSizes,
			maxHeight: null,
			maxWidth: null,
		}
	},
	mounted() {
		this.getMaxSizes()
	},
	watch: {
		productFinishes() {
			this.getMaxSizes()
		},
	},
	computed: {
		...mapFields('Dictionary', {
			dictionary: 'readableDictionary',
		}),
		...mapFields('Configurator', {
			selectedCategory: 'category',
			selectedFinishId: 'selectedFinishId',
			productFinishes: 'finishes',
			selectedProduct: 'product',
		}),
		...mapFields('ConfiguratorDisplay', {
			displayScenario: 'displayScenario',
		}),
		selectedSide: {
			get() {
				return this.productFinishes[this.selectedFinishId] && this.productFinishes[this.selectedFinishId].side
			},
		},
		selectedPosition: {
			get() {
				return this.productFinishes[this.selectedFinishId] && this.productFinishes[this.selectedFinishId].position
			},
			set(value) {
				this.$store.dispatch('Configurator/updateFinish', { key: 'position', value })
				// unset size
				const sizeMeasure = this.productFinishes[this.selectedFinishId].sizeMeasure
				const sizeObj = this.productFinishes[this.selectedFinishId].size
				const maxSize = sizeMeasure === 'width' ? this.maxWidth : this.maxHeight
				if (sizeObj[sizeMeasure] > maxSize) {
					const newSizes = { width: 0, height: 0 }
					newSizes[sizeMeasure] = maxSize
					this.$store.dispatch('Configurator/updateFinish', {
						key: 'size',
						value: newSizes,
					})
				}
				// this.$store.dispatch('Configurator/updateFinish', {
				// 	key: 'size',
				// 	value: this.selectedCategory === 'cap' ? { width: 0, height: 3 } : { width: 5, height: 0 },
				// })
				if (value.match(/custom/gi)) {
					// this.$store.dispatch('Configurator/updateFinish', { key: 'coords', value: { x: 1, y: 1 } })
					this.$store.dispatch('Configurator/updateFinish', { key: 'coords', value: { x: 0, y: 0 } })
				}
				// this.$store.dispatch('Configurator/updateFinish', {
				// 	key: 'sizeMeasure',
				// 	value: this.selectedCategory === 'cap' ? 'height' : 'width',
				// })
				// this.$store.dispatch('ConfiguratorDisplay/displayScenario', 'zoom')
			},
		},
		// @INFO: if something is missing here, check commit: b4449f0 rgota <hello@radugota.com> 15. Jun 2021 at 17:21
		allowedPositionOptions() {
			const options = { ...this.positionOptions[this.selectedProduct.productType] }
			for (let key in this.productFinishes) {
				const finish = this.productFinishes[key]
				// add only the free options from the other, not selected finishes
				if (key.toString() !== this.selectedFinishId.toString()) {
					options[finish.side] = options[finish.side].filter((po) => po.value !== finish.position)
				}
			}
			return options
		},
	},
	methods: {
		getMaxSizes() {
			if (!this.productFinishes[this.selectedFinishId].position.match(new RegExp('custom', 'gi'))) {
				const cb = (maxSizes) => {
					this.maxHeight = maxSizes.maxHeight
					this.maxWidth = maxSizes.maxWidth
				}
				EventBus.$emit('svgComponentData/getMaxSizes', { finish: this.productFinishes[this.selectedFinishId], cb })
			} else {
				const selectedSide = this.productFinishes[this.selectedFinishId].side
				const selectedTechnique = this.productFinishes[this.selectedFinishId].technique

				this.maxHeight = this.maxSizes[this.selectedProduct.productType][selectedSide][selectedTechnique].maxWidth
				this.maxWidth = this.maxSizes[this.selectedProduct.productType][selectedSide][selectedTechnique].maxHeight
			}
		},
	},
}
</script>