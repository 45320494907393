<template>
	<v-dialog v-model="deleteFileDialog" persistent max-width="300px" style="zindex: 99999">
		<template v-slot:activator="{ on, attrs }">
			<div class="delete-file-btn" v-bind="attrs" v-on="on">
				<v-icon small color="white" class="icon"> mdi-delete </v-icon>
			</div>
		</template>
		<v-card>
			<v-card-title>
				<span class="headline">Soll die Datei wirklich gelöscht werden?</span>
			</v-card-title>
			<v-card-actions>
				<v-btn color="red darken-1" text @click="deleteFile"> Löschen </v-btn>
				<v-spacer></v-spacer>
				<v-btn color="grey darken-1" text @click="deleteFileDialog = false"> Abbrechen </v-btn>
			</v-card-actions>
		</v-card>
	</v-dialog>
</template>

<script>
export default {
	name: 'DeleteFileDialog',
	props: {
		deleteFn: { type: Function, required: true },
	},
	data() {
		return {
			deleteFileDialog: false,
		}
	},
	methods: {
		deleteFile(ev) {
			this.deleteFileDialog = false
			this.deleteFn(ev)
		},
	},
}
</script>

<style lang="sass" scoped>
.delete-file-btn
	position: absolute
	z-index: 20
	top: auto
	left: auto
	bottom: -8px
	right: -8px
	width: 20px
	height: 20px
	color: rgba(0,0,0,1)
	background: black
	border-radius: 50%
	display: flex
	align-items: center
	justify-content: center
	opacity: 0.6
	transition: all 200ms
	.icon
		font-size: 14px !important
	&:hover
		opacity: 1

.headline
	line-height: 1.5em
	font-size: 17px !important
	word-break: break-word
</style>