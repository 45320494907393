<template>
	<v-dialog content-class="textile-color-dialog" v-model="colorDialog" scrollable>
		<template v-slot:activator="{ on, attrs }">
			<v-btn class="color-change-btn" small outlined elevation="0" tile v-bind="attrs" v-on="on">Ändern</v-btn>
		</template>

		<v-card tile class="content">
			<v-card-title class="text-h5 black text-white" color="white">
				{{ $getDictString('configurator_variants_heading_color_select') }}
			</v-card-title>

			<v-card-text>
				<v-item-group class="color-selection-container d-flex flex-wrap" v-model="selectedVariantColor">
					<v-item
						class="color-selection-wrapper pa-5"
						v-for="(color, idx) in selectedProduct.colors"
						:key="idx"
						:value="color.value"
					>
						<span>
							<v-btn
								class="color-selection-item ma-2"
								fab
								small
								plain
								depressed
								:value="color.value"
								@click.native="selectTextileColor(color)"
								:class="{ selected: selectedVariantColor == color.value, 'ma-5': true }"
								color="black"
								:style="{ backgroundColor: '#' + color.hexValue, border: '1px solid lightgrey' }"
								v-if="
									selectedProduct.isManualProduct ||
									selectedProduct.shopName === 'l-shop' ||
									selectedProduct.shopName === 'custom'
								"
							>
								<span class="pt-2">{{ color.value }}</span>
							</v-btn>
							<v-btn
								class="color-selection-item ma-2"
								fab
								small
								plain
								depressed
								:value="color.value"
								@click.native="selectTextileColor(color)"
								:class="{ selected: selectedVariantColor == color.value, 'ma-5': true }"
								v-if="selectedProduct.shopName === 'stanley-stella'"
								:style="{
									backgroundImage: `url(${COLOR_DOT_BASE_URL}/${color.hexValue}.png)`,
									backgroundSize: '100%',
								}"
							>
								<span>{{ color.value }}</span>
							</v-btn>
						</span>
					</v-item>
				</v-item-group>
			</v-card-text>

			<v-divider></v-divider>

			<v-card-actions>
				<v-spacer></v-spacer>
				<v-btn color="primary" text @click="colorDialog = false"> Abbrechen </v-btn>
			</v-card-actions>
		</v-card>
	</v-dialog>
</template>

<script>
import { mapFields } from 'vuex-map-fields'

export default {
	name: 'TextileColorModal',
	props: {
		selectedProduct: { type: Object, required: true },
		selectedVariantId: { type: Number, required: true },
	},
	data() {
		return {
			colorDialog: false,
			COLOR_DOT_BASE_URL: process.env.VUE_APP_AWS_IMAGES_BASE_URL + '/stanley-stella-colors',
		}
	},
	computed: {
		...mapFields('Configurator', {
			variants: 'variants',
		}),
		selectedVariantColor: {
			get() {
				return this.variants[this.selectedVariantId] && this.variants[this.selectedVariantId].color
			},
			set(colorObj) {
				this.$store.dispatch('Configurator/updateVariant', { key: 'color', value: colorObj.value })
				this.$store.dispatch('Configurator/updateVariant', { key: 'hexColor', value: colorObj.hexValue })
			},
		},
	},
	methods: {
		selectTextileColor(colorObj) {
			this.selectedVariantColor = colorObj
			this.colorDialog = false
		},
	},
}
</script>

<style lang="sass" scoped>
.color-change-btn
	background: white !important
	font-weight: 700
	margin: 0 0 40px 0
	position: relative
	opacity: 0.4
	top: -10px
	&:hover
		opacity: 1
		background: white !important
</style>

<style lang="sass">
.textile-color-dialog
	display: inline-flex
	// justify-content: center
	// align-items: center
	.color-selection-item
		span
			position: relative
			bottom: -15px
			width: 100px
			word-wrap: wrap
			white-space: normal
</style>
