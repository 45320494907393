export const standard = [
	{ color: 'rgb(200, 201, 199)', productNumber: '1687', stock: 0, name: 'Kangaroo', pantone: 'Cool Gray 3 C' },
	{ color: 'rgb(246, 141, 46)', productNumber: '1765', stock: 0, name: 'Sea Buckthorn', pantone: '715 C' },
	{ color: 'rgb(122, 154, 1)', productNumber: '1169', stock: 0, name: 'Limeade', pantone: '377 C' },
	{ color: 'rgb(255, 255, 255)', productNumber: '1801', stock: 0, name: 'Weiß', pantone: 'White' },
	{ color: 'rgb(0, 0, 0)', productNumber: '1800', stock: 0, name: 'Schwarz', pantone: 'Black' },
	{ color: 'rgb(0, 130, 186)', productNumber: '1977', stock: 0, name: 'Lochmara', pantone: '640 C' },
	{ color: 'rgb(239, 51, 64)', productNumber: '1637', stock: 0, name: '32 C', pantone: '32 C' },
	{ color: 'rgb(255, 215, 0)', productNumber: '1924', stock: 0, name: '12 C', pantone: '12 C' },
	{ color: 'rgb(137, 141, 141)', productNumber: '1918', stock: 0, name: 'Gunsmoke', pantone: '423 C' },
	{ color: 'rgb(249, 66, 58)', productNumber: '1588', stock: 1, name: 'Sunset Orange', pantone: 'Warm Red C' },
	{ color: 'rgb(166, 25, 46)', productNumber: '1839', stock: 4, name: 'Tamarillo', pantone: '187 C' },
	{ color: 'rgb(224, 60, 49)', productNumber: '1878', stock: 4, name: 'Cinnabar', pantone: '179 C' },
	{ color: 'rgb(155, 39, 67)', productNumber: '1981', stock: 4, name: 'Mexican Red', pantone: '194 C' },
	{ color: 'rgb(250, 70, 22)', productNumber: '1987', stock: 2, name: 'Pomegranate', pantone: '172 C' },
	{ color: 'rgb(255, 199, 44)', productNumber: '1624', stock: 4, name: 'Sunglow', pantone: '123 C' },
	{ color: 'rgb(255, 198, 0)', productNumber: '1825', stock: 4, name: 'Supernova', pantone: '7548 C' },
	{ color: 'rgb(255, 233, 0)', productNumber: '1995', stock: 1, name: 'Turbo', pantone: '803 C' },
	{ color: 'rgb(132, 189, 0)', productNumber: '1649', stock: 4, name: 'Pistachio', pantone: '376 C' },
	{ color: 'rgb(101, 141, 27)', productNumber: '1769', stock: 5, name: 'Vida Loca', pantone: '370 C' },
	{ color: 'rgb(0, 159, 77)', productNumber: '1651', stock: 1, name: 'Green Haze', pantone: '7482 C' },
	{ color: 'rgb(67, 176, 42)', productNumber: '1701', stock: 4, name: 'Apple', pantone: '361 C' },
	{ color: 'rgb(0, 154, 23)', productNumber: '1749', stock: 1, name: '2272 C', pantone: '2272 C' },
	{ color: 'rgb(0, 119, 73)', productNumber: '1750', stock: 3, name: 'Fun Green', pantone: '3415 C' },
	{ color: 'rgb(0, 123, 75)', productNumber: '1751', stock: 1, name: 'Tropical Rain Forest', pantone: '7726 C' },
	{ color: 'rgb(83, 84, 53)', productNumber: '1795', stock: 4, name: 'Hemlock', pantone: '7764 C' },
	{ color: 'rgb(143, 214, 189)', productNumber: '1845', stock: 2, name: 'Vista Blue', pantone: '337 C' },
	{ color: 'rgb(73, 197, 177)', productNumber: '1847', stock: 2, name: 'Puerto Rico', pantone: '3258 C' },
	{ color: 'rgb(0, 111, 68)', productNumber: '1851', stock: 4, name: 'Fun Green', pantone: '7727 C' },
	{ color: 'rgb(44, 82, 52)', productNumber: '1905', stock: 4, name: 'Killarney', pantone: '350 C' },
	{ color: 'rgb(208, 223, 0)', productNumber: '1940', stock: 4, name: 'Electric Lime', pantone: '389 C' },
	{ color: 'rgb(0, 154, 68)', productNumber: '1988', stock: 4, name: 'Green Haze', pantone: '347 C' },
	{ color: 'rgb(72, 159, 223)', productNumber: '1528', stock: 1, name: '2171 C', pantone: '2171 C' },
	{ color: 'rgb(105, 179, 231)', productNumber: '1628', stock: 4, name: 'Seagull', pantone: '292 C' },
	{ color: 'rgb(127, 173, 227)', productNumber: '1675', stock: 1, name: '2142 C', pantone: '2142 C' },
	{ color: 'rgb(61, 135, 203)', productNumber: '1733', stock: 1, name: '2143 C', pantone: '2143 C' },
	{ color: 'rgb(0, 98, 155)', productNumber: '1797', stock: 1, name: 'Bahama Blue', pantone: '3015 C' },
	{ color: 'rgb(0, 178, 169)', productNumber: '1799', stock: 2, name: 'Persian Green', pantone: '326 C' },
	{ color: 'rgb(8, 87, 195)', productNumber: '1842', stock: 1, name: '2132 C', pantone: '2132 C' },
	{ color: 'rgb(0, 47, 108)', productNumber: '1843', stock: 4, name: 'Midnight Blue', pantone: '294 C' },
	{ color: 'rgb(0, 147, 178)', productNumber: '1895', stock: 1, name: 'Bondi Blue', pantone: '632 C' },
	{ color: 'rgb(0, 75, 135)', productNumber: '1934', stock: 8, name: 'Congress Blue', pantone: '301 C' },
	{ color: 'rgb(0, 30, 98)', productNumber: '1966', stock: 8, name: 'Midnight Blue', pantone: '2758 C' },
	{ color: 'rgb(0, 58, 112)', productNumber: '1967', stock: 4, name: 'Regal Blue', pantone: '654 C' },
	{ color: 'rgba(41,87,146,1)', productNumber: '1975', stock: 4, name: '8184 C', pantone: '8184 C' },
	{ color: 'rgb(240, 78, 152)', productNumber: '1584', stock: 4, name: 'Brilliant Rose', pantone: '212 C' },
	{ color: 'rgb(255, 95, 162)', productNumber: '1595', stock: 1, name: '812 C', pantone: '812 C' },
	{ color: 'rgb(255, 62, 181)', productNumber: '1597', stock: 1, name: 'Razzle Dazzle Rose', pantone: '806 C' },
	{ color: 'rgb(255, 134, 116)', productNumber: '1777', stock: 3, name: 'Salmon', pantone: '170 C' },
	{ color: 'rgb(249, 181, 196)', productNumber: '1816', stock: 3, name: 'Illusion', pantone: '707 C' },
	{ color: 'rgb(255, 163, 139)', productNumber: '1820', stock: 1, name: 'Mona Lisa', pantone: '1625 C' },
	{ color: 'rgb(109, 32, 119)', productNumber: '1833', stock: 4, name: 'Honey Flower', pantone: '259 C' },
	{ color: 'rgb(255, 133, 189)', productNumber: '1909', stock: 4, name: '926 C', pantone: '926 C' },
	{ color: 'rgb(255, 141, 109)', productNumber: '1952', stock: 1, name: 'Salmon', pantone: '1635 C' },
	{ color: 'rgb(206, 15, 105)', productNumber: '1984', stock: 4, name: 'Razzmatazz', pantone: '214 C' },
	{ color: 'rgb(246, 82, 117)', productNumber: '1994', stock: 1, name: 'French Rose', pantone: '184 C' },
	{ color: 'rgb(255, 143, 108)', productNumber: '1946', stock: 4, name: '811 C', pantone: '811 C' },
	{ color: 'rgb(249, 56, 34)', productNumber: '1947', stock: 4, name: 'Pomegranate', pantone: 'Bright Red C' },
	{ color: 'rgb(151, 215, 0)', productNumber: '1950', stock: 4, name: 'Pistachio', pantone: '375 C' },
	{ color: 'rgb(162, 170, 173)', productNumber: '1611', stock: 2, name: 'Gray Chateau', pantone: '429 C' },
	{ color: 'rgb(145, 157, 157)', productNumber: '1613', stock: 4, name: 'Granny Smith', pantone: '443 C' },
	{ color: 'rgb(123, 128, 133)', productNumber: '1615', stock: 4, name: '10389 C', pantone: '10389 C' },
	{ color: 'rgb(217, 217, 214)', productNumber: '1686', stock: 3, name: 'Quill Gray', pantone: 'Cool Gray 1 C' },
	{ color: 'rgb(178, 180, 178)', productNumber: '1811', stock: 4, name: 'Pumice', pantone: '421 C' },
	{ color: 'rgb(66, 85, 99)', productNumber: '1841', stock: 5, name: 'Fiord', pantone: '7545 C' },
	{ color: 'rgb(145, 147, 136)', productNumber: '1862', stock: 4, name: 'Lemon Grass', pantone: '415 C' },
	{ color: 'rgb(235, 188, 78)', productNumber: '1670', stock: 4, name: '2006 C', pantone: '2006 C' },
	{ color: 'rgb(185, 151, 91)', productNumber: '1673', stock: 4, name: 'Muddy Waters', pantone: '465 C' },
	{ color: 'rgb(252, 210, 153)', productNumber: '1723', stock: 4, name: 'Cherokee', pantone: '7507 C' },
	{ color: 'rgb(198, 146, 20)', productNumber: '1791', stock: 4, name: 'Pizza', pantone: '1245 C' },
	{ color: 'rgb(192, 181, 97)', productNumber: '1809', stock: 1, name: 'Laser', pantone: '617 C' },
	{ color: 'rgb(219, 200, 182)', productNumber: '1884', stock: 3, name: 'Akaroa', pantone: '482 C' },
	{ color: 'rgba(156, 131, 134)', productNumber: '1854', stock: 1, name: '8440 C', pantone: '8440 C' },
	{ color: 'rgb(69, 53, 54)', productNumber: '1859', stock: 1, name: 'Woody Brown', pantone: '439 C' },
	{ color: 'rgb(98, 59, 42)', productNumber: '1945', stock: 5, name: 'Quincy', pantone: '477 C' },
	{ color: 'rgb(252, 76, 2)', productNumber: '1678', stock: 4, name: 'Vermilion', pantone: '1655 C' },
	{ color: 'rgb(242, 169, 0)', productNumber: '1951', stock: 4, name: 'Web Orange', pantone: '130 C' },
	{ color: 'rgb(255, 143, 28)', productNumber: '1965', stock: 5, name: 'West Side', pantone: '1495 C' },
]

export const frosted = [
	{ color: 'rgb(147, 64, 84)', productNumber: '7255', stock: 4, name: 'Vin Rouge', pantone: '7640 C' },
	{ color: 'rgb(124, 37, 41)', productNumber: '7747', stock: 4, name: 'Crown of Thorns', pantone: '1815 C' },
	{ color: 'rgb(166, 25, 46)', productNumber: '7982', stock: 4, name: 'Tamarillo', pantone: '187 C' },
	{ color: 'rgb(225, 184, 127)', productNumber: '7670', stock: 4, name: 'Harvest Gold', pantone: '7508 C' },
	{ color: 'rgb(251, 225, 34)', productNumber: '7924', stock: 1, name: 'Candlelight', pantone: '107 C' },
	{ color: 'rgb(253, 218, 36)', productNumber: '7980', stock: 1, name: 'Candlelight', pantone: '115 C' },
	{ color: 'rgb(145, 214, 172)', productNumber: '7647', stock: 4, name: 'Vista Blue', pantone: '345 C' },
	{ color: 'rgb(164, 214, 94)', productNumber: '7649', stock: 1, name: 'Apfelgrün', pantone: '367 C' },
	{ color: 'rgb(4, 106, 56)', productNumber: '7650', stock: 5, name: 'Fun Green', pantone: '349 C' },
	{ color: 'rgb(113,176,180)', productNumber: '7746', stock: 2, name: '2232 C', pantone: '2232 C' },
	{ color: 'rgb(78, 73, 52)', productNumber: '7795', stock: 3, name: 'Mondo', pantone: '7771 C' },
	{ color: 'rgb(154, 190, 170)', productNumber: '7845', stock: 2, name: 'Summer Green', pantone: '558 C' },
	{ color: 'rgb(39, 153, 137)', productNumber: '7868', stock: 1, name: 'Jungle Green', pantone: '7473 C' },
	{ color: 'rgb(163, 170, 131)', productNumber: '7969', stock: 3, name: 'Sage', pantone: '5783 C' },
	{ color: 'rgb(96, 110, 178)', productNumber: '7142', stock: 1, name: 'Scampi', pantone: '7456 C' },
	{ color: 'rgb(41, 143, 194)', productNumber: '7695', stock: 4, name: 'Curious Blue', pantone: '7689 C' },
	{ color: 'rgb(219, 226, 233)', productNumber: '7761', stock: 4, name: 'Mystic', pantone: '649 C' },
	{ color: 'rgb(0, 40, 85)', productNumber: '7843', stock: 4, name: 'Prussian Blue', pantone: '295 C' },
	{ color: 'rgb(29, 87, 165)', productNumber: '7934', stock: 2, name: '2133 C', pantone: '2133 C' },
	{ color: 'rgb(197, 207, 218)', productNumber: '7953', stock: 3, name: 'Heather', pantone: '538 C' },
	{ color: 'rgb(41, 143, 194)', productNumber: '7977', stock: 1, name: 'Curious Blue', pantone: '7689 C' },
	{ color: 'rgb(213, 194, 216)', productNumber: '7627', stock: 4, name: 'Chatelle', pantone: '524 C' },
	{ color: 'rgb(226, 69, 133)', productNumber: '7787', stock: 4, name: 'Cerise', pantone: '7424 C' },
	{ color: 'rgb(140, 71, 153)', productNumber: '7832', stock: 1, name: 'Affair', pantone: '258 C' },
	{ color: 'rgb(230, 134, 153)', productNumber: '7917', stock: 1, name: 'Deep Blush', pantone: '701 C' },
	{ color: 'rgb(88, 44, 131)', productNumber: '7922', stock: 1, name: 'Eminence', pantone: '268 C' },
	{ color: 'rgb(226, 232, 104)', productNumber: '7823', stock: 3, name: 'Confetti', pantone: '379 C' },
	{ color: 'rgb(248, 144, 165)', productNumber: '7909', stock: 6, name: 'Tickle Me Pink', pantone: '708 C' },
	{ color: 'rgb(167, 162, 195)', productNumber: '7612', stock: 4, name: 'Logan', pantone: '7445 C' },
	{ color: 'rgb(16, 24, 32)', productNumber: '7641', stock: 2, name: 'Bunker', pantone: 'Black 6 C' },
	{ color: 'rgb(196, 191, 182)', productNumber: '7811', stock: 4, name: 'Cotton Seed', pantone: '400 C' },
	{ color: 'rgb(138, 141, 143)', productNumber: '7840', stock: 1, name: 'Oslo Gray', pantone: '877 C' },
	{ color: 'rgb(55, 58, 54)', productNumber: '7841', stock: 5, name: 'Lunar Green', pantone: '447 C' },
	{ color: 'rgb(51, 47, 33)', productNumber: '7931', stock: 2, name: 'Birch', pantone: 'Black 2 C' },
	{ color: 'rgb(168, 153, 104)', productNumber: '7936', stock: 2, name: 'Sandal', pantone: '7503 C' },
	{ color: 'rgb(205, 160, 119)', productNumber: '7125', stock: 3, name: 'Whiskey', pantone: '728 C' },
	{ color: 'rgb(236, 186, 168)', productNumber: '7752', stock: 4, name: 'Zinnwaldite', pantone: '488 C' },
	{ color: 'rgb(253, 170, 99)', productNumber: '7755', stock: 4, name: 'Atomic Tangerine', pantone: '714 C' },
	{ color: 'rgb(255, 106, 57)', productNumber: '7778', stock: 1, name: 'Orange', pantone: '1645 C' },
	{ color: 'rgb(214, 210, 196)', productNumber: '7882', stock: 5, name: 'Swirl', pantone: '7527 C' },
	{ color: 'rgb(236, 199, 205)', productNumber: '7941', stock: 2, name: 'Vanilla Ice', pantone: '196 C' },
	{ color: 'rgb(246, 141, 46)', productNumber: '7965', stock: 1, name: 'Sea Buckthorn', pantone: '715 C' },
]
