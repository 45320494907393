<template>
	<div v-if="isVisible" class="d-flex flex-column fill-height">
		<SectionLabel :label="dictionary['configurator_finish_heading_side']" />
		<div class="button-wrapper d-flex flex-column">
			<TextButton
				v-for="(option, idx) in allowedSides"
				:key="idx"
				class="flex-1"
				:label="option.label"
				:active="selectedSide === option.value"
				@click.native="selectedSide = option.value"
			/>
		</div>
	</div>
</template>

<script>
import { mapFields } from 'vuex-map-fields'

import { EventBus } from '@/utils/eventbus'
import PositionSettings from '@/assets/data/finishSettings.js'
import TextButton from '../../components/TextButton'
import SectionLabel from '../../components/SectionLabel'

export default {
	name: 'Technique',
	components: { TextButton, SectionLabel },
	data() {
		return {
			sideOptions: PositionSettings.sideOptions,
			techniqueTypeOptions: PositionSettings.techniqueTypeOptions,
			positionOptions: PositionSettings.positionOptions,
			maxSizes: PositionSettings.maxSizes,
		}
	},
	mounted() {
		this.getMaxSizes()
	},
	watch: {
		productFinishes() {
			this.getMaxSizes()
		},
	},
	computed: {
		...mapFields('Dictionary', {
			dictionary: 'readableDictionary',
		}),
		...mapFields('Configurator', {
			selectedCategory: 'category',
			selectedFinishId: 'selectedFinishId',
			productFinishes: 'finishes',
			selectedProduct: 'product',
		}),
		...mapFields('ConfiguratorDisplay', {
			displayScenario: 'displayScenario',
		}),
		selectedTechnique: {
			get() {
				return this.productFinishes[this.selectedFinishId] && this.productFinishes[this.selectedFinishId].technique
			},
		},
		selectedTechniqueType: {
			get() {
				return this.productFinishes[this.selectedFinishId] && this.productFinishes[this.selectedFinishId].techniqueType
			},
		},
		selectedSide: {
			get() {
				return this.productFinishes[this.selectedFinishId] && this.productFinishes[this.selectedFinishId].side
			},
			set(value) {
				// if shoulder is selected also select position
				if (value === 'shoulder-sleeve') {
					const freePosition = this.allowedPositionOptions[value][0].value
					this.$store
						.dispatch('Configurator/updateFinish', { key: 'coords', value: { x: 0, y: 0 } })
						.then(() => this.$store.dispatch('Configurator/updateFinish', { key: 'position', value: freePosition }))
						.then(() => this.$store.dispatch('Configurator/updateFinish', { key: 'side', value }))
				} else {
					let newPosition = ''
					if (this.allowedPositionOptions[value].length > 0) {
						const customPos = this.allowedPositionOptions[value].find((p) => p.value.match(/custom/gi))
						if (customPos) {
							newPosition = customPos.value
						} else {
							newPosition = this.allowedPositionOptions[value][0].value
						}
					}

					this.$store
						.dispatch('Configurator/updateFinish', { key: 'position', value: newPosition })
						.then(() => this.$store.dispatch('Configurator/updateFinish', { key: 'side', value }))
				}
			},
		},
		isVisible() {
			return this.techniqueTypeOptions[this.selectedTechnique] ? this.selectedTechniqueType : this.selectedTechnique
		},
		allowedPositionOptions() {
			const options = { ...this.positionOptions[this.selectedProduct.productType] }
			for (let key in this.productFinishes) {
				const finish = this.productFinishes[key]
				if (finish.idx === undefined || finish.idx.toString() !== this.selectedFinishId.toString()) {
					options[finish.side] = options[finish.side].filter((po) => po.value !== finish.position)
				}
			}
			return options
		},
		allowedSides() {
			const techniqueKey = this.selectedTechnique + (this.selectedTechniqueType ? '--' + this.selectedTechniqueType : '')
			const availableSides = this.sideOptions[this.selectedCategory][techniqueKey]
			const allowedSides = []
			availableSides.forEach((side) => {
				if (this.allowedPositionOptions[side.value] && this.allowedPositionOptions[side.value].length > 0) allowedSides.push(side)
				else if (this.selectedSide === side.value) allowedSides.push(side) // push currently selected side
			})
			return allowedSides
		},
	},
	methods: {
		getMaxSizes() {
			const cb = (maxSizes) => {
				this.maxHeight = maxSizes.maxHeight
				this.maxWidth = maxSizes.maxWidth
			}
			EventBus.$emit('svgComponentData/getMaxSizes', { finish: this.productFinishes[this.selectedFinishId], cb })
		},
	},
}
</script>
