<template>
	<div :class="{ text: true, 'warning-state': warning }" v-html="text"></div>
</template>

<style scoped lang="sass">
.text
    font-size: 11px
    color: rgba(0,0,0,0.75)
    font-family: 'Helvetica'
    font-weight: 500
    margin-bottom: 15px
    margin-left: 0px
    &.warning-state
        color: #BC8A2F
        background: #FFEFB9
        padding: 10px
        border: 1px solid #FCCB39

        background: #F8D6D7
        color: #B54E4E
        border: 1px solid #B54E4E
        border-radius: 4px
</style>

<script>
export default {
	name: 'InfoText',
	props: {
		text: { type: String },
		warning: { type: Boolean, default: false },
	},
}
</script>
