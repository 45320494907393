<template>
	<div class="wrapper">
		<InfoBlock type="sizes" />
		<v-card class="main-selection-container elevation-0" ref="amount">
			<InfoBlockTrigger type="sizes" />
			<v-container>
				<v-row>
					<v-col class="pt-2 px-10">
						<b>{{ $getDictString('configurator_amount_heading_main') }}</b>
						<InfoText class="mb-1" :text="$getDictString('configurator_amount_text_main')" />
						<InfoText
							class="mb-1"
							style="font-family: ‘grotta-semibold‘"
							:text="
								$getDictString('configurator_amount_minimum_order_value', {
									MINIMUM_AMOUNT: getMinAmountForConfiguration(),
								})
							"
						/>
					</v-col>
				</v-row>
				<!-- <v-row>
					<v-col cols="12" :style="{ position: 'relative', 'margin-bottom': '0px' }" class="px-10">
						<SectionLabel :label="$getDictString('configurator_amount_heading_sizes')" />
						<div class="button-wrapper" :class="{ few: sortedProductSizes.length < 8 }">
							<TextButton
								:active="selectedSizes.indexOf(size) > -1"
								@click.native="updateSelectedSizes(size)"
								:label="size"
								v-for="(size, idx) in sortedProductSizes"
								:key="idx"
							/>
						</div>
					</v-col>
				</v-row> -->
				<v-row>
					<v-col cols="12" :style="{ position: 'relative', 'margin-bottom': '10px' }" class="px-10">
						<div class="amounts-wrapper">
							<div v-for="(variant, variantIdx) in variants" :key="variantIdx">
								<SectionLabel :label="$getDictString('configurator_amount_heading_distribution')" class="mt-10" />
								<!-- <SectionLabel :label="$getDictString('configurator_amount_heading_sizes')" /> -->
								<v-row class="no-gutters">
									<v-col cols="8" class="pr-0 pb-0">
										<div class="button-wrapper" :class="{ few: sortedProductSizes(variant).length < 8 }">
											<TextButton
												:active="variant.selectedSizes.indexOf(size) > -1"
												@click.native="updateSelectedSizes(size, variant)"
												:label="size"
												v-for="(size, idx) in sortedProductSizes(variant)"
												:key="idx"
											/>
										</div>
									</v-col>
									<v-col cols="4" class="pr-0 pb-2 d-flex align-end justify-end">
										<StandardAmount
											:variantIdx="variantIdx"
											:defaultAmount="getMinAmountForVariant(variantIdx)"
											:variant="variant"
										/>
									</v-col>
								</v-row>
								<!-- <SectionLabel :label="$getDictString('configurator_amount_heading_distribution')" /> -->
								<div class="amounts-row">
									<v-row class="no-gutters">
										<!-- <StandardAmount
											:variantIdx="variantIdx"
											:defaultAmount="getMinAmountForVariant(variantIdx)"
											:variant="variant"
										/> -->
										<v-col cols="6" class="pr-0 pb-0">
											<div class="variant-indicator">
												<div class="mockup">
													<div
														class="total-amount"
														:style="!validateVariantAmount(variantIdx) && { color: 'lightgrey' }"
													>
														{{ totalAmount(variantIdx) }}
														<div v-if="!validateVariantAmount(variantIdx)" class="warning-label">
															Mindestbestellmenge noch nicht erreicht
														</div>
														<div v-if="validateVariantAmount(variantIdx)" class="label">INSGESAMT</div>
													</div>

													<div class="color" :class="{ cap: selectedProduct.productType.match('cap') }">
														<SVGFront
															mockupSide="front"
															currentStep="amounts"
															:mockupType="selectedProduct.productType"
															:hideBlockedAreas="true"
															:mainColor="variant.extractedHexColor"
															:secColor="variant.extractedHexColor"
														/>
													</div>

													<!-- <img
													:src="getColorBlob(variant.color, selectedProduct.shopName)"
													v-if="selectedProduct.shopName === 'stanley-stella'"
												/>
												<div
													class="color-circle"
													:style="{ backgroundColor: variant.extractedHexColor }"
													v-if="selectedProduct.isManualProduct || selectedProduct.shopName === 'l-shop' || selectedProduct.shopName === 'custom'"
												></div> -->
												</div>
											</div>
										</v-col>
										<v-col cols="6" class="py-0 pl-0 fill-height right-side">
											<div
												class="size-distribution d-flex fill-height justify-between"
												v-if="variant.selectedSizes.length > 0"
											>
												<div
													class="sizes d-flex items-center justify-end"
													v-for="(variantSize, idx) in variants[variantIdx].sizes"
													:key="idx"
												>
													<div class="label">
														{{ variantSize.size }}
													</div>

													<div class="amount">
														<button
															:class="{ inactive: variantSize.amount == 0 }"
															@click="
																manualUpdateVariantSizeAmount(
																	variantSize.amount - 1,
																	variantSize.size,
																	variantIdx
																)
															"
														>
															-
														</button>
														<input
															:value="variantSize.amount"
															:tabindex="variantIdx + idx + 1"
															style="width: 100%; text-align: center"
															onClick="this.setSelectionRange(0, this.value.length)"
															@change="(ev) => updateVariantSizeAmount(ev, variantSize.size, variantIdx)"
														/>
														<button
															@click="
																manualUpdateVariantSizeAmount(
																	variantSize.amount + 1,
																	variantSize.size,
																	variantIdx
																)
															"
														>
															+
														</button>
													</div>
												</div>
											</div>

											<div v-else class="d-flex fill-height justify-center items-center text-center pa-5">
												{{ $getDictString('configurator_amount_warning_select') }}
											</div>
										</v-col>
									</v-row>
								</div>
							</div>
						</div>
						<!-- <v-row>
							<v-col>
								<p class="my-0">
									{{
										$getDictString('configurator_amount_minimum_order_value', {
											MINIMUM_AMOUNT: getMinAmountForConfiguration(),
										})
									}}
								</p>
							</v-col>
						</v-row> -->
					</v-col>
				</v-row>
			</v-container>
		</v-card>
		<div e class="row" v-if="validAmounts">
			<div class="container finish-button flex justify-center">
				<BlackButton
					:label="$getDictString('configurator_amount_button_ready')"
					:iconSrc="require('@/assets/icons/check.png')"
					@click.native="completeConfiguration"
				/>
			</div>
		</div>
	</div>
</template>

<script>
import SectionLabel from '../components/SectionLabel'
import TextButton from '../components/TextButton'
import BlackButton from '../components/BlackButton'
import InfoText from '../components/InfoText'
import StandardAmount from '../components/StandardAmount'
import InfoBlock from '@/views/configurator/components/InfoBlock'
import InfoBlockTrigger from '@/views/configurator/components/InfoBlockTrigger'

import { mapFields } from 'vuex-map-fields'
import SVGFront from '../mockups/SVGFront'

import { sortSizes } from '@/utils/helpers'

export default {
	name: 'Amount',
	components: { SectionLabel, TextButton, BlackButton, InfoText, SVGFront, StandardAmount, InfoBlock, InfoBlockTrigger },
	mounted() {
		this.validateAllAmounts()
	},
	data() {
		return {
			COLOR_DOT_BASE_URL: process.env.VUE_APP_AWS_IMAGES_BASE_URL + '/stanley-stella-colors',
			MIN_VARIANT_AMOUNT: 30,
			MIN_SIZE_AMOUNT: 1,
			validAmounts: false,
			MIN_AMOUNTS: {
				print: {
					single: 30,
					multiple: 50,
					recommended: 30,
				},
				stick: {
					single: 20,
					multiple: 20,
					recommended: 20,
				},
			},
			standardAmount: 100,
		}
	},
	computed: {
		...mapFields('Configurator', {
			productSizes: 'product.sizes',
			selectedProduct: 'product',
			variants: 'variants',
			finishes: 'finishes',
		}),
	},
	watch: {
		variants() {
			this.validateAllAmounts()
		},
	},
	methods: {
		sortedProductSizes(variant) {
			const color = this.selectedProduct.colors.find((c) => c.value === variant.color)
			const sizes = color.activeSizes.map((ps) => ({
				size: ps.value,
			}))
			return sortSizes(sizes).map((s) => s.size)
		},
		selectAll() {
			console.log('select all')
		},
		updateVariantSizeAmount(ev, size, variantIdx) {
			this.$store.dispatch('Configurator/updateSizeAmount', {
				newAmount: parseInt(ev.srcElement.value),
				size,
				variantIdx: parseInt(variantIdx),
			})
		},
		manualUpdateVariantSizeAmount(amount, size, variantIdx) {
			this.$store.dispatch('Configurator/updateSizeAmount', {
				newAmount: parseInt(amount),
				size,
				variantIdx: parseInt(variantIdx),
			})
		},
		updateSelectedSizes(size, variant) {
			let sizes = [...variant.selectedSizes] || []
			if (sizes.indexOf(size) > -1) {
				const idx = sizes.indexOf(size)
				sizes.splice(idx, 1)
			} else {
				sizes.push(size)
			}
			this.$store.dispatch('Configurator/updateSelectedSizes', { sizes, variant }).finally(() => this.validateAllAmounts())
		},
		totalAmount(variantIdx) {
			return this.variants[variantIdx].sizes ? this.variants[variantIdx].sizes.reduce((acc, val) => acc + val.amount, 0) : 0
		},
		completeConfiguration() {
			this.$store.dispatch('Configurator/completeConfiguration')
			this.$router.push({ name: 'configurator-overview' })
		},
		getColorBlob(colorName, shopName) {
			if (shopName == 'stanley-stella') {
				const blob = this.selectedProduct.colors.find((c) => c.value === colorName).hexValue
				return `${this.COLOR_DOT_BASE_URL}/${blob}.png`
			}
			return ''
		},
		validateVariantAmount(variantIdx) {
			const minAmount = this.getMinAmountForVariant(variantIdx)
			const currentAmount = this.totalAmount(variantIdx)
			return currentAmount >= minAmount
		},
		getMinAmountForVariant(variantIdx = 0) {
			let technique, type

			for (let key in this.finishes) {
				const finish = this.finishes[key]
				if (finish.technique === 'print') {
					technique = 'print'
					break
				} else {
					technique = finish.technique
				}
			}

			const variant = this.variants[variantIdx]
			for (let key in variant.finishes) {
				const finish = variant.finishes[key]
				if (finish.type === 'multiple') {
					type = 'multiple'
					break
				} else {
					type = 'single'
				}
			}

			return this.MIN_AMOUNTS[technique][type] || this.MIN_VARIANT_AMOUNT
		},
		getMinAmountForConfiguration() {
			let confMinAmount = 0
			for (let key in this.variants) {
				confMinAmount = Math.max(this.getMinAmountForVariant(key), confMinAmount)
			}
			return confMinAmount
		},
		validateSizeAmount(variantSize) {
			return variantSize.amount === 0 || variantSize.amount >= this.MIN_SIZE_AMOUNT
		},
		validateAllAmounts() {
			let valid = true
			for (let key in this.variants) {
				// const variant = this.variants[key]
				if (valid) {
					valid = this.validateVariantAmount(key)
					// if (valid) {
					// 	for (let size of variant.sizes) {
					// 		if (valid) valid = this.validateSizeAmount(size)
					// 	}
					// }
				}
			}
			this.validAmounts = valid
		},
	},
}
</script>

<style scoped lang="sass">
.wrapper
	margin-top: 100px
.main-selection-container
	// margin-top: 100px
	padding-top: 20px
	border: solid 1px rgb(235,235,235)
.transparent-background
	background: transparent
.button-wrapper
	display: flex
	flex-direction: row
	flex-wrap: wrap
	// justify-content: space-between
	justify-content: flex-start
	&.few
		justify-content: flex-start
.amounts-wrapper
	width: 100%
	.amounts-row
		width: 100%
		border: 1px solid rgb(235, 235, 235)
		min-height: 250px
		border-radius: 4px
		// overflow: hidden
		display: flex
		flex-direction: row
		margin-top: 10px
		margin-bottom: 60px
		position: relative

		&:last-child
			margin-bottom: 0

		.standard-amounts
			position: absolute
			top: -42px
			right: 4px
			z-index: 10
			color: rgba(0,0,0,0.6)
			background-color: rgba(0,0,0,0.05)
			text-align: center
			border-radius: 4px
			.v-btn
				color: red
				text-transform: initial !important
		.variant-indicator
			width: 100%
			height: 100%
			display: flex
			justify-content: center
			align-items: center
			flex-direction: column
			height: 100%
			position: relative
			.mockup
				height: 100%
				width: 100%
				display: flex
				justify-content: center
				align-items: center
				flex-direction: column
				position: relative
				overflow: hidden
				img
					position: absolute
					height: 60px
					width: 60px
					right: 10px
					bottom: 20px
					z-index: 10
				.color
					// position: absolute
					// width: 150%
					// height: 150%
					// right: -75%
					// bottom: -75%
					// text-align: center
					// z-index: 1
					// &.cap
					// 	width: 160%
					// 	height: 160%
					// 	right: -60%
					// 	bottom: -40%
					// width: 150%
					// height: 150%

					position: absolute
					// right: -90px
					text-align: center
					z-index: 1
					// top: 10px
					// bottom: 10px

					top: 0px
					bottom: 0px
					right: -50%
					left: 50%
					display: flex
					justify-content: center
					align-items: center

					&.cap
						right: -100%
						left: 0%

					#front-mockup
						flex: 1 1
						height: 100%
						max-height: 220px
						display: flex
						justify-content: center
						align-items: center

				.color-circle
					position: absolute
					width: 160px
					height: 160px
					right: -80px
					bottom: -80px
					border-radius: 50%
					z-index: 1

			.total-amount
				top: 0
				display: flex
				position: absolute
				justify-content: center
				align-items: center
				flex-direction: column
				text-align: center
				font-size: 74px
				font-family: "grotta-semibold"
				width: 50%
				left: 0
				line-height: 1
				padding: 0px 0 0 0px
				.label
					font-family: "grotta-semibold"
					font-size: 10px
					letter-spacing: 0.2em
					line-height: 1
					text-transform: uppercase
				.warning-label
					position: absolute
					bottom: -37px
					left: 0px
					width: 100%
					font-weight: 300
					font-size: 11px
					line-height: 1.3em
					letter-spacing: 0.02em
					color: rgba(0,0,0,0.5)
					text-align: center
					border-radius: 4px
					padding: 7px 10px
				// &:after
					// font-family: "grotta-semibold
				// 	width: 100%
				// 	left: 0
				// 	text-align: center
				// 	position: absolute
				// 	text-transform: uppercase
				// 	bottom: 00px
				// 	font-size: 10px
				// 	content: "insgesamt"
				// 	letter-spacing: 0.2em
				// 	line-height: 1
		.right-side
			border-left: solid 1px rgb(235,235,235)
		.size-distribution
			width: 100%
			display: inline-flex
			flex-direction: column

			.sizes
				display: flex
				flex-direction: row
				flex-grow: 1
				// 	width: 50%
				input
					width: 50px
			>div
				width: 100%

		.outer-sizes
			width: 85%
			height: 100%
		.sizes
			display: flex
			flex-direction: column
			width: 100%
			padding: 15px
			border-bottom: 1px solid rgb(235, 235, 235)
			position: relative
			overflow: hidden
			&:hover
				.label
					opacity: 0.4
			.label
				display: flex
				align-items: center
				justify-content: center
				// font-size: 36px
				font-size: 7em
				position: absolute
				font-family: "grotta-semibold"
				opacity: 0.1
				left: -9px
				pointer-events: none
				transition: all 300ms ease-out

			.amount
				display: flex
				justify-content: center
				align-items: center
				font-size: 2em
				font-family: "grotta-semibold"
				button
					background-color: black
					width: 20px
					height: 20px
					font-size: 0.8em
					color: white
					line-height: 1
					display: inline-block
					justify-content: center
					align-items: center
					&.inactive
						pointer-events: none
						opacity: 0.3

				input
					width: 50px !important
					font-size: 1.2em
					list-height: 1
.finish-button
	margin-top: 20px
	z-index: 3
</style>
