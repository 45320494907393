<template>
	<div id="finish-container" class="background-transparent">
		<InfoBlock type="motive" />
		<v-card class="main-selection-container elevation-0 background-transparent" ref="motive" :style="{ 'align-items': 'flex-start' }">
			<InfoBlockTrigger type="motive" />

			<v-col cols="12" class="selected-finishes pa-0">
				<div v-for="(finish, idx) in productFinishes" :key="idx">
					<div
						class="finishes-row content px-0 d-flex justify-between items-center"
						@click="setCurrentFinish(idx)"
						v-if="parseInt(selectedFinishId) !== parseInt(idx)"
						:class="{ inactive: selectedFinishId > -1 && parseInt(selectedFinishId) != parseInt(idx) }"
					>
						<img :src="$getFileUrlById(finish.fileName)" />
						<div class="bigIndex">
							<span>{{ parseInt(idx) + 1 }}</span>
						</div>
						<div class="overlay d-flex justify-center items-center">
							<span>{{ $getDictString('configurator_finish_button_edit_finish') }}</span>
						</div>
						<v-col cols="11">
							<div class="d-flex align-center justify-start">
								<div class="px-2"></div>
								<div class="px-2">
									<div>
										<small>{{ $getDictString('configurator_finish_heading_technique') }}</small>
									</div>
									<div>
										<h3>
											{{
												finish.technique &&
												techniqueOptions[selectedCategory] &&
												techniqueOptions[selectedCategory].find((opt) => opt.value === finish.technique).label
											}}
										</h3>
									</div>
								</div>
								<div v-if="finish.techniqueType" class="px-3">
									<div>
										<small>{{ $getDictString('configurator_finish_heading_label_type') }}</small>
									</div>
									<div>
										<h3>
											{{
												finish.technique &&
												finish.side &&
												sideOptions[selectedCategory] &&
												sideOptions[selectedCategory][finish.technique + '--' + finish.techniqueType] &&
												sideOptions[selectedCategory][finish.technique + '--' + finish.techniqueType].find(
													(opt) => opt.value === finish.side
												).label
											}}
										</h3>
									</div>
								</div>
								<div class="px-3">
									<div>
										<small>{{ $getDictString('configurator_finish_heading_side') }}</small>
									</div>
									<div>
										<h3>
											{{
												finish.technique &&
												finish.side &&
												sideOptions[selectedCategory] &&
												sideOptions[selectedCategory][finish.technique] &&
												sideOptions[selectedCategory][finish.technique].find((opt) => opt.value === finish.side)
													.label
											}}
										</h3>
									</div>
								</div>
								<div class="px-3">
									<div>
										<small>{{ $getDictString('configurator_finish_heading_position') }}</small>
									</div>
									<div>
										<h3>
											{{
												finish.side &&
												finish.position &&
												positionOptions[selectedProduct.productType] &&
												positionOptions[selectedProduct.productType][finish.side] &&
												positionOptions[selectedProduct.productType][finish.side].find(
													(opt) => opt.value === finish.position
												).label
											}}
										</h3>
									</div>
								</div>
								<div class="px-3">
									<div>
										<small>{{ finish.sizeMeasure === 'width' ? 'Breite' : 'Höhe' }}</small>
									</div>
									<div>
										<h3>{{ finish.size[finish.sizeMeasure] }}cm</h3>
									</div>
								</div>
							</div>
						</v-col>
					</div>

					<div
						class="new-finish-settings px-10 py-10 open"
						:ref="`open-finish-${parseInt(idx)}`"
						v-if="parseInt(selectedFinishId) === parseInt(idx)"
					>
						<v-container>
							<v-row>
								<div class="bigIndex">
									{{ parseInt(idx) + 1 }}
								</div>
								<v-col class="pt-0" v-if="idx == 0">
									<b>{{ $getDictString('configurator_finish_heading_1') }}</b>
									<InfoText class="mb-5" :text="$getDictString('configurator_finish_textile-color')" />
								</v-col>

								<v-col class="new-finish-row py-0" cols="12">
									<SectionLabel :label="$getDictString('configurator_finish_heading_file')" />
									<v-row>
										<v-col class="py-0">
											<v-item-group
												v-model="selectedFileName"
												class="pa-0 py-0 files-slide-group d-flex flex-wrap"
												:center-active="true"
											>
												<v-item
													v-for="file in fileOptions.filter((f) => f.type === 'upload').reverse()"
													:key="file._id"
													v-slot="{ active, toggle }"
													:value="file._id"
												>
													<FileButton
														class="box-button"
														:label="`${file.label}`"
														:image="file.url"
														:active="active"
														@click.native="
															() => {
																if (!active) toggle()
															}
														"
														:deleteFn="(ev) => deleteFile(ev, file)"
													/>
												</v-item>

												<v-item>
													<FinishFileUpload />
												</v-item>

												<v-item v-if="fileOptions.length < 2">
													<FileButton
														class="box-button"
														:label="fileOptions.filter((f) => f.type === 'dummy')[0].label"
														:image="fileOptions.filter((f) => f.type === 'dummy')[0].url"
														:active="selectedFileName === fileOptions.filter((f) => f.type === 'dummy')[0]._id"
														@click.native="
															() => {
																selectedFileName = fileOptions.filter((f) => f.type === 'dummy')[0]._id
															}
														"
													/>
												</v-item>
											</v-item-group>
											<InfoText class="mb-2" :text="$getDictString('configurator_finish_text_file')" />
										</v-col>
									</v-row>
									<v-row class="pt-5">
										<v-col cols="4">
											<Technique />
										</v-col>
										<v-col cols="4">
											<Side />
										</v-col>
										<v-col cols="4">
											<Position />
										</v-col>
									</v-row>
									<v-row>
										<v-col class="new-finish-row py-0" cols="12">
											<!-- <Position /> -->
										</v-col>
									</v-row>
									<Size />
									<Comment />
									<!-- SETTINGS END -->
								</v-col>
							</v-row>
							<v-row>
								<v-col cols="6" class="d-flex pt-10 justify-start">
									<button
										class="finish-row-button danger"
										v-if="Object.keys(productFinishes).length > 1"
										@click="deleteFinish"
									>
										{{ $getDictString('configurator_finish_button_delete_finish') }}
									</button>
								</v-col>
								<v-col cols="6" class="d-flex pt-10 justify-end">
									<button
										v-if="Object.keys(productFinishes).length > 0 && !sizeWarning"
										class="finish-row-button main"
										@click="closeSelectedFinish()"
									>
										{{ $getDictString('configurator_finish_button_save_finish') }}
									</button>
								</v-col>
							</v-row>
						</v-container>
					</div>
				</div>
			</v-col>

			<v-row
				v-if="
					productFinishes && Object.keys(productFinishes).length > 0 && selectedFinishId === -1 && !allCustomProductPositionsUsed
				"
			>
				<v-col class="pa-2 text-center" cols="11">
					<v-btn class="addButton" @click="addFinish">
						<v-icon class="pr-2"> mdi-plus </v-icon> {{ $getDictString('configurator_finish_button_new_finish') }}
					</v-btn>
				</v-col>
			</v-row>
		</v-card>
	</div>
</template>

<script>
import PositionSettings from '@/assets/data/finishSettings.js'
import SectionLabel from '../components/SectionLabel'
import FileButton from '../components/FileButton'
import InfoText from '../components/InfoText'
import FinishFileUpload from '../partials/FinishFileUpload'
// import Technique from '../partials/finish/Technique'
// import TechniqueType from '../partials/finish/TechniqueType'
// import Side from '../partials/finish/Side'
// import Position from '../partials/finish/Position'
import Size from '../partials/finish/Size'
import Technique from '../partials/finish/Technique'
import Side from '../partials/finish/Side'
import Position from '../partials/finish/Position'
import Comment from '../partials/finish/Comment'
import InfoBlock from '@/views/configurator/components/InfoBlock'
import InfoBlockTrigger from '@/views/configurator/components/InfoBlockTrigger'

import { mapFields } from 'vuex-map-fields'

import { finishValidate } from '@/assets/data/validationSchemes'

export default {
	name: 'Finish',
	components: {
		SectionLabel,
		InfoText,
		FileButton,
		FinishFileUpload,
		Size,
		Technique,
		Side,
		Comment,
		Position,
		InfoBlock,
		InfoBlockTrigger,
	},
	computed: {
		...mapFields('ConfiguratorFiles', {
			fileOptions: 'files',
		}),
		...mapFields('ConfiguratorDisplay', {
			displayScenario: 'displayScenario',
			sizeWarning: 'sizeWarning',
		}),
		...mapFields('Configurator', {
			selectedCategory: 'category',
			selectedFinishId: 'selectedFinishId',
			productFinishes: 'finishes',
			selectedProduct: 'product',
		}),
		selectedFileName: {
			get() {
				return this.productFinishes[this.selectedFinishId] && this.productFinishes[this.selectedFinishId].fileName
			},
			set(fileName) {
				this.$store.dispatch('Configurator/updateFinish', { key: 'fileName', value: fileName })
			},
		},
		allCustomProductPositionsUsed() {
			console.log(this.productFinishes)
			return this.selectedProduct.productType === 'custom' && this.productFinishes && Object.keys(this.productFinishes).length === 2
		},
	},
	created() {
		if (Object.keys(this.productFinishes).length === 0) {
			this.$store.dispatch('Configurator/addFinish')
		}
	},
	data() {
		return {
			// MAX_IMAGE_HEIGHT: 38,
			// MAX_IMAGE_WIDTH: 45,
			techniqueOptions: PositionSettings.techniqueOptions,
			techniqueTypeOptions: PositionSettings.techniqueTypeOptions,
			sideOptions: PositionSettings.sideOptions,
			positionOptions: PositionSettings.positionOptions,
		}
	},
	methods: {
		imgHeight(finish) {
			const aspectRatio = finish.imageWidth / finish.imageHeight
			return Math.floor(finish.size / aspectRatio)
		},
		deleteFile(ev, file) {
			ev.preventDefault()
			ev.stopPropagation()
			console.log(file)
			this.$store.dispatch('ConfiguratorFiles/deleteFile', { file })
		},
		closeSelectedFinish() {
			let finish = { ...this.productFinishes[this.selectedFinishId] }

			// finish.technique = 'labels'

			const validate = finishValidate()
			const valid = validate(finish)
			if (!valid) {
				// console.log(validate.errors)
				const printErrors = validate.errors.filter((e) => e.instancePath !== '')
				// const printErrors = validate.errors
				let errorString = ''

				console.log('--- FEHLER ---')
				printErrors.forEach((e) => {
					console.log(e.instancePath + ' ' + e.message)
					errorString += e.message + '\n'
				})
				console.log('--- ------ ---')
				this.$store.dispatch('ConfiguratorDisplay/setError', { message: errorString })
			} else {
				this.selectedFinishId = -1
			}
		},
		scrollToSelectedFinish() {
			const that = this
			that.$nextTick(() => {
				const wrapperElement = that.$refs['motive'].$el
				const openFinishElement = that.$refs[`open-finish-${parseInt(that.selectedFinishId)}`][0]
				if (wrapperElement && openFinishElement) {
					const scrollContainer = document.getElementById('configurator-wrapper-scroll-container')
					const offset = 20
					scrollContainer.scrollTo({
						top: openFinishElement.offsetTop + wrapperElement.offsetTop - offset,
						left: 0,
						behavior: 'smooth',
					})
				}
			})
		},
		setCurrentFinish(finishId) {
			// if (!this.sizeWarning) {
			// allows to open a wrong finish-config, but cannot switch from wrong to another one
			if (this.selectedFinishId === -1) {
				const that = this
				this.$store.dispatch('Configurator/selectFinish', finishId).then(() => {
					that.scrollToSelectedFinish()
				})
			}
		},
		openAddFinishDialog() {
			this.$store.dispatch('Configurator/addFinish')
		},
		addFinish(ev) {
			ev.preventDefault()
			const that = this
			this.$store.dispatch('Configurator/addFinish').then(() => {
				that.scrollToSelectedFinish()
			})
		},
		deleteFinish() {
			this.sizeWarning = false
			this.$store.dispatch('Configurator/deleteFinish', this.selectedFinishId)
		},
		// validateFinish() {
		// 	const finish = this.productFinishes[this.selectedFinishId]

		// 	// check if finish has a custom position, if not "true", if check if coordinates are set
		// 	const validateCustomPosition = () => {
		// 		if (finish.position.match(/custom/gi)) {
		// 			return finish.coords.x && finish.coords.y
		// 		} else {
		// 			return true
		// 		}
		// 	}

		// 	if (
		// 		Boolean(finish.fileName) &&
		// 		Boolean(finish.position) &&
		// 		Boolean(finish.side) &&
		// 		finish.size &&
		// 		Boolean(finish.sizeMeasure) &&
		// 		finish.size[finish.sizeMeasure] > 0 &&
		// 		Boolean(finish.technique)
		// 	) {
		// 		if (finish.technique === 'labels') {
		// 			if (finish.techniqueType) {
		// 				return validateCustomPosition()
		// 			} else {
		// 				return false
		// 			}
		// 		} else {
		// 			return validateCustomPosition()
		// 		}
		// 	} else {
		// 		return false
		// 	}
		// },
	},
}
</script>
<style scoped lang="sass">
.main-selection-container
	margin-top: 0
	padding-top: 0 !important
.background-transparent
	background: transparent

.addButton
	background: white !important
	color: black !important
	border: solid 2px black
	font-family: "grotta-semibold"
	box-shadow: none
	&:hover
		background: black !important
		color: white !important
</style>

<style lang="sass">
#finish-container
	margin-top: 100px
	.bigIndex
		position: absolute
		top: -18px
		height: 100%
		display: flex
		justify-content: center
		align-items: flex-start
		right: 0
		font-size: 120px
		font-family: "grotta-semibold"
		opacity: 0.1
	.delete-btn
		position: absolute
		z-index: 10
		top: 0
		right: 10px
	.finishes-row
		height: 140px
		background: white
		border: solid 1px rgba(235,235,235,)
		position: relative
		cursor: pointer
		margin: 10px 0
		border-radius: 4px
		position: relative
		&.inactive
			cursor: not-allowed
			.overlay
				opacity: 0.7
				span
					display: none
		small
			opacity: 0.5
			z-index: 1
		h3
			font-size: 1.5em
			font-family: "grotta-semibold"
		img
			height: 120px
			width: 120px
			object-fit: contain
			padding: 10px
			margin-left: 20px
		.overlay
			position: absolute
			background: rgba(255,255,255,0.95)
			text-transform: uppercase
			top: 0
			left: 0
			width: 100%
			height: 100%
			pointer-events: none
			opacity: 0
			transition: all 200ms
			z-index: 11
			font-family: "grotta-semibold"
			font-size: 10px
			display: flex
			justify-content: center
			align-items: center
			letter-spacing: 0.02em
			span
				background: black
				color: white
				padding: 8px 12px
				border-radius: 8px
		&:hover
			.overlay
				transition: 300ms ease-out
				opacity: 1
				span
					transform: translateY(0%)
					transition: 300ms ease-out
	.finish-row-button
		border: solid 2px black
		padding: 5px 20px
		font-family: "grotta-semibold"
		margin: 0
		font-size: 1.2em
		letter-spacing: -0.02em
		opacity: 0.3
		&:hover
			opacity: 1
		&.main
			background: black
			color: white
			opacity: 1
			&:hover
				opacity: 0.8
	.new-finish-row
		.values
			.large
				font-size: 2.2em
				font-family: "grotta-semibold"
				line-height: 0.6
				padding: 10px 0 3px 0
	.new-finish
		margin-top: 0px

		.new-finish-btn-wrapper, .next-btn-wrapper
			width: 100%
			text-align: center
			position: relative
			z-index: 10
			&.next-btn-wrapper
				position: relative
				top: 50px
				transition: all 200ms

			&.add-finish-dialog-open
				&.next-btn-wrapper
					top: -50px
		.new-finish-settings
			top: -25px !important

	.new-finish-settings
		height: 0
		padding-top: 0px
		padding-bottom: 0px
		position: relative
		z-index: 9
		background: white
		z-index: 11
		border: 1px solid rgb(235, 235, 235)
		border-radius: 4px
		// border: solid 1px
		transition: all 200ms
		&.open
			height: auto
			padding-top: 40px
			padding-bottom: 40px

		.files-slide-group
			width: 99%
			&:after
				content: " "
				width: 8.5vw

		.close-btn
			position: absolute
			top: 20px
			right: 30px
			letter-spacing: 1px
			cursor: pointer
			span.measure
				color: rgb(209, 209, 209)

			&.active-state
				&:before
					display: none
		.inactive
			opacity: 0.5
			pointer-events: none
		.size-wrapper
			background: transparent
			padding: 0px
			border: 0
			position: relative
			&.active-state
				&:before
					display: none

			// v-slider styling
			.v-slider.v-slider--horizontal
				margin-left: 0
			.v-slider__thumb
				cursor: pointer
				border-radius: 0
				height: 27px
				width: 8px
			.v-slider__track-container
				height: 4px
</style>
